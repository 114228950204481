import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { ProfileTracker } from '../_models/StudentProfileTracker';
import { LogUsers } from '../_models/user';
import { AccountService } from '../_services/account.service';
import { ExamApplicationsService } from '../_services/exam-applications.service';
import { StudentProfileUtilityService } from '../_services/student-profile-utility.service';

@Component({
  selector: 'app-students-my-tracker',
  templateUrl: './students-my-tracker.component.html',
  styleUrls: ['./students-my-tracker.component.css']
})
export class StudentsMyTrackerComponent implements OnInit {


  profileTrackDetails: ProfileTracker;
  eAdmissionAllow: boolean;
  appliedMediumTrue :boolean;
  downloadsString: string;
  user: LogUsers;
  StudentAppliedMedium: string;
  InstructionSheetName:string;
  examApplied: boolean;


  constructor(private profileUtilityservice: StudentProfileUtilityService, public accountservive: AccountService, private toastr: ToastrService,private examService: ExamApplicationsService) {
    this.accountservive.currentUser$.pipe(take(1)).subscribe(user => this.user = user);
  }

  ngOnInit(): void {
    this.checkExamApplied();
    
    this.downloadsString = "assets/" + this.user.userId + ".pdf";    
    this.getStudentProfileTrackDetails(1);
  }

  getStudentProfileTrackDetails(trackingID: number) {   
   
    this.profileUtilityservice.getProfileActivityTracking(trackingID).subscribe(trakDetails => {     

      if (trakDetails) {
        this.eAdmissionAllow = true;        
      }
      else {
        this.eAdmissionAllow = false;
      }
    }
    );
  }

  checkExamApplied() {
    this.examService.checkExamAppliedservice().subscribe(response => {
      this.examApplied = response;
      if(response)
      {
        this.getAppliedMediumSubjects();
      }

    });
  }



  getAppliedMediumSubjects() {
    this.profileUtilityservice.getAppliedMedium().subscribe(appliedMedium => {

      this.appliedMediumTrue = appliedMedium;
      if (appliedMedium) {
        this.StudentAppliedMedium = "SINHALA";
        this.InstructionSheetName = "assets/sinhala.pdf";
      }
      else {
        this.StudentAppliedMedium = "ENGLISH";
        this.InstructionSheetName = "assets/english.pdf";
      }

    });
  }








}
