<div class="container" style="margin-top:50px">
    <div class="row" *ngIf="item">
        <div class="col-4">
            <div class="card">
                <img src="{{item.photoUrl}}" alt="{{item.itemName}}" class="card-img-top img-thumbnail">
                <div class="card-body">
                    <div>
                        <strong>Name :</strong>
                        <p>{{item.itemName}}</p>
                    </div>
                    <div>
                        <strong>Prices (RS) :</strong>
                        <p>{{item.itemPrice}}</p>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="btn-group d-flex">
                        <button class="btn btn-primary" ngbTooltip="If You want to Purchase this, Please Add Curt"
                            [openDelay]="300" [closeDelay]="500" (click)="AddItemToCart()">Add to cart</button>

                        <button class="btn btn-success" ngbTooltip="Provide a review about this Item" [openDelay]="300"
                            [closeDelay]="500" (click)="onclick()">Review</button>
                    </div>

                </div>
            </div>

        </div>
        <div class="col-8">
            <tabset class="item-tabset">
                <tab heading="Details" style="width: 100%;">
                    <br>
                    <h4>
                        <strong>
                            Description
                        </strong>
                    </h4>
                    <p>{{item.itemDescription}}</p>
                    <br>
                    <br>
                   
                        <p-table [value]="salesitemsDetails">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center; width: 10%;">Unit</th>
                                    <th style="text-align: center; width: 75%;">Key Topics</th>
                                    <th style="text-align: center; width: 15%;">Weightage %</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-salesitemsDetails>
                                <tr>
                                    <td style="width: 10%; text-align: center;">{{salesitemsDetails.itemLineNumber}}
                                    </td>
                                    <td style="width: 75%;">{{salesitemsDetails.itemDescription}}</td>
                                    <td style="width: 15%; text-align: center;">{{salesitemsDetails.itemWaitage}}</td>
                                </tr>

                            </ng-template>

                            <ng-template pTemplate="footer" style="margin-top: 10px;">
                                <tr>
                                    <th style="text-align: center; width: 10%;"></th>
                                    <th style="text-align: center; width: 75%;">Total</th>
                                    <th style="text-align: center; width: 15%;">100</th>
                                </tr>
                            </ng-template>
                        </p-table>
                    
                </tab>
                <tab heading="Review">
                    <br>
                    <h4>
                        <strong>Reviews</strong>
                    </h4>
                    <div *ngFor="let review of userReview">

                        <br>

                        <div class="card border-primary" style="width: 100%;">
                            <div class="card-header">{{review.userName}}</div>
                            <div class="card-body">
                                <p class="card-text"> {{review.userReview}} </p>
                            </div>
                        </div>




                        <br>

                    </div>

                </tab>
                <tab heading="Additional Views">
                    <br>

                    <ngx-gallery [options]="galleryOptions" [images]="galleryImages"
                        style="display: inline-block; margin-bottom: 20px;"></ngx-gallery>
                </tab>

            </tabset>

        </div>
    </div>
    <br>
</div>