<nav class="navbar navbar-expand-lg navbar-dark bg-primary ">
  <div class="container">

    <a class="navbar-brand" *ngIf="!(accountservive.currentUser$ | async) " routerLink='/'>
      AAT - Sri Lanka Student's Portal
    </a>

    <ul class="navbar-nav me-auto">
      <ng-container *ngIf="accountservive.currentUser$ | async">
        <li class="nav-item active">
          <a class="nav-link" routerLink='/StudentsDetails' routerLinkActive='active'>My Details</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink='/ExamApplication' routerLinkActive='active'>Exam Application</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink='/ExempReqestBanner' routerLinkActive='active'>Exemption Request</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink='/StudentsMyTracker' routerLinkActive='active'>My Tracker</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink='/StudentsResultHistory' routerLinkActive='active'>History</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink='/help' routerLinkActive='active'>Help</a>
        </li>
      </ng-container>

    </ul>


    <div class="dropdown" *ngIf="(accountservive.currentUser$ | async) as user" dropdown>
      <a class="dropdown-toggle text-light" dropdownToggle>Welcome {{user.userId}}</a>
      <div class="dropdown-menu mt3" *dropdownMenu>
        <a class="dropdown-item" (click)="clearout()">LogOut</a>
      </div>
    </div>



    <form *ngIf="(accountservive.currentUser$ | async) === undefined" #loginForm="ngForm"
      class="form-inline mt-2 mt-md-0" (ngSubmit)="login()" autocomplete="off">
      <input name="regID" required [(ngModel)]="model.UserID" class="form-control mr-sm-2" type="text"
        placeholder="Registration ID">

      <input name="password" required [(ngModel)]="model.PasswordText" class="form-control mr-sm-2" type="password"
        placeholder="Password">
      <button [disabled]="!loginForm.valid" class="btn btn-primary" type="submit">Login</button>

    </form>

    <ul class="navbar-nav me-auto">
      <ng-container *ngIf="(accountservive.currentUser$ | async) === undefined" style="align-items: center;">
        <li class="nav-item">
          <a class="nav-link" routerLink='/StudRegister' routerLinkActive='active'>Sign UP</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink='/LoginIssues' routerLinkActive='active'>Login Issues</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink='/HowtoRegister' routerLinkActive='active'>How to Register</a>
        </li>       
      </ng-container>
    </ul>

    

  </div>







</nav>