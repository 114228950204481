<div class="container" style="margin-top: 10px;">

    <div class="jumbotron">
        <div class="row ">
            <div class="col-12">
                <P class="card-header fa-2x text-center font-weight-bold  alert alert-dismissible alert-primary">
                    Exam Application Payment - Manual Bank Updates
                </P>
            </div>
        </div>

        <div class="row">
            <div class="col-8" style="text-align: right;">
                <label style="color:coral; font-weight: bolder;">Expected Payment Amount :</label>

            </div>
            <div class="col-4" style="text-align: right;">
                <label style="color:rgb(24, 22, 22); font-weight: bolder;">{{netPaymentValue| number :
                    '1.2-2'}}</label>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-8">
            </div>
            <div class="form-group col-4">
                <button inputmode="submit" (click)="addNewPayment()" pButton icon="pi pi-mobile" label="Add Payment Details"
                    style="width: 100%;"></button>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);">

                <div class="row">
                    <div class="col-12">
                        <p-table #dt [value]="offlinePayments" dataKey="subjectCode" responsiveLayout="scroll"
                            [rowHover]="true">

                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center; width: 10%;">SER#</th>
                                    <th style="text-align: center; width: 20%;">Voucher Code</th>
                                    <th style="text-align: center; width: 15%;">Pay Referance</th>
                                    <th style="text-align: center; width: 15%;">Upload File</th>
                                    <th style="text-align: center; width: 10%;">Paid Date</th>
                                    <th style="text-align: center; width: 20%;">Amount</th>
                                    <th style="text-align: center; width: 10%;">Delete</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-offlinePayments>
                                <tr>
                                    <td style="text-align: center;">{{offlinePayments.paymentID}}</td>
                                    <td style="text-align: center;">{{offlinePayments.paymentReferance}}</td>                                    
                                    <td style="text-align: left;">{{offlinePayments.studentRegID}}</td>
                                    <td style="text-align: center;">{{offlinePayments.uploadedFileName}}</td>
                                    <td style="text-align: center;">{{offlinePayments.paidDate | date:'dd/MM/yyyy'}}
                                    </td>
                                    <td style="text-align: right;">{{offlinePayments.paidAmount | number:'1.0-0'}}</td>
                                    <td style="text-align: center;">
                                        <span class="table-remove" style="align-items: center;">
                                            <button mat-fab color="warn"
                                                (click)="removeItemfromCart(offlinePayments.paymentID,offlinePayments.paidAmount)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </span>
                                    </td>

                                </tr>
                            </ng-template>

                        </p-table>
                    </div>
                </div>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-6" style="text-align: right; width: 100%;">
                <button pButton icon="pi pi-mobile" inputmode="submit" label="Confirm Payment"  (click)="confirmPayments()" style="width: 100%;"></button>

            </div>
            <div class="col-6" style="text-align: right; width: 100%;">
                <button pButton icon="pi pi-book" inputmode="submit" label="Return Payments"  (click)="BacktoPaymentSelection()" style="width: 100%;"></button>
            </div>
        </div>
        <hr>

        <div class="row">
            <div class="col-8" style="text-align: right;">
                <label style="color:coral; font-weight: bolder;">Total Paid Amount :</label>

            </div>
            <div class="col-4" style="text-align: right;">
                <label style="color:rgb(24, 22, 22); font-weight: bolder;">{{totalpaidAmount| number :
                    '1.2-2'}}</label>
            </div>
        </div>

        <div class="row">
            <div class="col-8" style="text-align: right;">
                <label style="color:coral; font-weight: bolder;">Balance to Pay Amount :</label>

            </div>
            <div class="col-4" style="text-align: right;">
                <label style="color:rgb(24, 22, 22); font-weight: bolder;">{{netPaymentValue -totalpaidAmount| number :
                    '1.2-2'}}</label>
            </div>
        </div>             
        <hr>
    </div>




    <form #PaymentAddForm="ngForm">

        <p-dialog header='Add Bank Payment' [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}" 
            [baseZIndex]="10000" [draggable]="false" [resizable]="false">

            <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);">

                <div class="row">
                    <div class="col-6">
                        <Label style="color:coral; font-weight: bolder;">
                            Payment SER#
                        </Label>
                    </div>
                    <div class="col-6">
                        <Label>
                            <strong>
                                {{payID}}
                            </strong>

                        </Label>
                    </div>

                </div>
                <hr>

                <div class="row">
                    <div class="col-6 form-group">
                        <Label style="color:coral; font-weight: bolder;">
                            Payment Referance :
                        </Label>
                    </div>

                    <div class="col-6 form-group">
                        <input name="referanceNumber" required [(ngModel)]="referanceNumber"
                            class="form-control mr-sm-2" type="text"
                            placeholder="22 plus student registration number and any two Slips use _1 and _2" style="width: 100%;">
                    </div>

                </div>

                <div class="row">
                    <div class="col-6 form-group">
                        <Label style="color:coral; font-weight: bolder;">
                            Paid Date :
                        </Label>
                    </div>

                    <div class="col-6 form-group">

                        <p-calendar name="paidDate" styleClass="dateTimeField" [touchUI]="true" required inputId="basic"
                            [(ngModel)]="paidDate" placeholder="Paid date to the Bank" dateFormat="dd.mm.yy">
                        </p-calendar>
                    </div>

                </div>


                <div class="row">
                    <div class="col-6 form-group">
                        <Label style="color:coral; font-weight: bolder;">
                            Payment Amount :
                        </Label>
                    </div>

                    <div class="col-6 form-group">
                        <p-inputNumber name="paidAmount" required [(ngModel)]='paidAmount'
                            placeholder="Paid Amount to the Slip"></p-inputNumber>
                    </div>

                </div>

                <div class="row">

                    <div class="col-3 form-group">
                       <input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)" style="display: none;">
                       <button type="button" class="btn btn-success" (click)="file.click()">Upload File</button>
                    </div>
                    <div class="col-4 form-group">
                        <span class="upload" *ngIf="progress > 0">
                            {{progress}}%
                        </span>
                        <span class="upload" *ngIf="message">
                            {{message}}%
                        </span>
                     </div>

                </div>





            </div>

            <ng-template pTemplate="footer">

                <div class="row">
                    <div class="col-6">

                    </div>
                    <div class="col-3">
                        <p-button icon="pi pi-check" inputmode="submit" (click)="saveNewPayment()" label="Yes"
                            class="p-button-text">
                        </p-button>
                    </div>

                    <div class="col-3">
                        <p-button icon="pi pi-times" inputmode="submit" (click)="closeDialog()" label="No"
                            class="p-button-text"></p-button>
                    </div>


                </div>



            </ng-template>




        </p-dialog>
    </form>



    <p-dialog header='Confirmation' [(visible)]="displayConfirm" [modal]="true" [style]="{width: '25vw'}" [breakpoints]="{'960px': '75vw', '320px': '50vw'}"
        [baseZIndex]="10000" [draggable]="false" [resizable]="false">

        <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);">

            <div class="row">
                <div class="col-12">
                    <Label style="color:coral; font-weight: bolder;">
                        Are you sure want to Update Payments ?
                    </Label>
                </div>             

            </div>
            
        </div>

        <ng-template pTemplate="footer">

            <div class="row">
                <div class="col-6">

                </div>
                <div class="col-3">
                    <p-button icon="pi pi-check" inputmode="submit" (click)="UpdatePayments()" label="Yes"
                        class="p-button-text">
                    </p-button>
                </div>

                <div class="col-3">
                    <p-button icon="pi pi-times" inputmode="submit" (click)="displayConfirm = false" label="No"
                        class="p-button-text"></p-button>
                </div>
            </div>
        </ng-template>

    </p-dialog>



</div>

<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>