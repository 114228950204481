import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { ListsComponent } from './lists/lists.component';
import { MessagesComponent } from './messages/messages.component'
import { SharedModule } from './_module/shared.module';
import { ErrorInterceptor } from './_interceptor/error.interceptor';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { ServerErrorComponent } from './error/server-error/server-error.component';
import { SalesItemsListComponent } from './salesItems/sales-items-list/sales-items-list.component';
import { SalesitemCardComponent } from './salesItems/salesitem-card/salesitem-card.component';
import { JwtInterceptor } from './_interceptor/jwt.interceptor';
import { SalesItemsDetailsComponent } from './salesItems/sales-items-details/sales-items-details.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingInterceptor } from './_interceptor/loading.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MemberReviewComponent } from './salesItems/member-review/member-review.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { StickyNavModule } from 'ng2-sticky-nav';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TextInputComponent } from './_forms/text-input/text-input.component';
import { MatSelectModule } from '@angular/material/select';
import { RegiConfComponent } from './regi-conf/regi-conf.component';
import { MatCardModule } from '@angular/material/card';
import { DownloadsComponent } from './downloads/downloads.component';
import { TableModule } from 'primeng/table';
import { ShopcartFinalComponent } from './shopcart-final/shopcart-final.component';
import { UserActiveComponent } from './user-active/user-active.component';
import { FieldsetModule, } from 'primeng/fieldset';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { UserprofileEditComponent } from './userprofile-edit/userprofile-edit.component';
import { CommonModule, DatePipe } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { HashLocationStrategy, Location, LocationStrategy } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { CarouselComponent, CarouselModule, MDBBootstrapModule, WavesModule } from 'angular-bootstrap-md';
import { HelpComponent } from './help/help.component';
import { NewNavComponent } from './new-nav/new-nav.component';
import { OutinvDispComponent } from './outinv-disp/outinv-disp.component'
import { CheckboxModule } from 'primeng/checkbox';
import { UserDetailsComponent } from './user-details/user-details.component';
import { CalendarModule } from 'primeng/calendar';
import { Angular2CsvModule } from 'angular2-csv';
import { StudentDetailsComponent } from './student-details/student-details.component';
import { ExaminationAppStudentComponent } from './examination-app-student/examination-app-student.component';
import { MessageModule } from 'primeng/message';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ExamAppPaymentComponent } from './exam-app-payment/exam-app-payment.component';
import { InputTextModule } from 'primeng/inputtext';
import { ExamApplicationConfirmationComponent } from './exam-application-confirmation/exam-application-confirmation.component';
import { ManualPaymentUpdateComponent } from './manual-payment-update/manual-payment-update.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { FileUploadModule } from 'primeng/fileupload';
import { ManualPaymentConfirmationComponent } from './manual-payment-confirmation/manual-payment-confirmation.component';
import {PanelModule} from 'primeng/panel';
import { StudentsExewmptionRequestComponent } from './students-exewmption-request/students-exewmption-request.component';
import { StudentsMyTrackerComponent } from './students-my-tracker/students-my-tracker.component';
import { StudentHistoryComponent } from './student-history/student-history.component';
import { HowtoRegisterComponent } from './howto-register/howto-register.component';

import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {TabMenuModule} from 'primeng/tabmenu';
import { ExamApplicationHelpComponent } from './exam-application-help/exam-application-help.component';
import { ExameAdmissionHelpComponent } from './exame-admission-help/exame-admission-help.component';
import { StudentzregistrationNewComponent } from './studentzregistration-new/studentzregistration-new.component';
import { LoginIssuesComponent } from './login-issues/login-issues.component';
import { ResendUserActivationEmailComponent } from './resend-user-activation-email/resend-user-activation-email.component';
import {DropdownModule} from 'primeng/dropdown';
import { NewExemptionDisplayBannerComponent } from './new-exemption-display-banner/new-exemption-display-banner.component';






@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    RegisterComponent,
    ListsComponent,
    MessagesComponent,
    NotFoundComponent,
    ServerErrorComponent,
    SalesItemsListComponent,
    SalesitemCardComponent,
    SalesItemsDetailsComponent,
    MemberReviewComponent,
    TextInputComponent,
    RegiConfComponent,
    DownloadsComponent,
    ShopcartFinalComponent,
    UserActiveComponent,
    ForgetpasswordComponent,
    UserprofileEditComponent,
    HelpComponent,
    NewNavComponent,
    OutinvDispComponent,
    UserDetailsComponent,
    StudentDetailsComponent,
    ExaminationAppStudentComponent,
    ExamAppPaymentComponent,
    ExamApplicationConfirmationComponent,
    ManualPaymentUpdateComponent,
    ManualPaymentConfirmationComponent,
    StudentsExewmptionRequestComponent,
    StudentsMyTrackerComponent,
    StudentHistoryComponent,
    HowtoRegisterComponent,
    ExamApplicationHelpComponent,
    ExameAdmissionHelpComponent,
    StudentzregistrationNewComponent,
    LoginIssuesComponent,
    ResendUserActivationEmailComponent,
    NewExemptionDisplayBannerComponent,
    
    


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxSpinnerModule,
    NgbModule,
    MatSidenavModule,
    StickyNavModule,
    // MatCarouselModule.forRoot(),
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    TableModule,
    FieldsetModule,
    DialogModule,
    ButtonModule,
    RippleModule,
    CarouselModule.forRoot(),
    WavesModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    CheckboxModule,
    CalendarModule,
    Angular2CsvModule,
    MessageModule,
    BrowserModule,
    BrowserAnimationsModule,
    ConfirmDialogModule,
    ButtonModule,
    //ConfirmationService,
    InputTextModule,
    InputNumberModule,
    FileUploadModule,
    PanelModule,
    MenuModule,
    MenubarModule,
    TabMenuModule,
    DropdownModule,




  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: DatePipe },




  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
