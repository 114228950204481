<div class="container  " style="margin-top: 10px;">
    <div class="row alert alert-dismissible alert-danger">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <div class="col-12" style="text-align: center;">
            <h1>
                <strong>
                    Student's Notice
                </strong>                
            </h1>
            <h2>
                Exemption Request Not Available
            </h2>
        </div>
    </div>

    <div class="col-12" style="text-align: center;">
        <p>
            
        </p>
  
    </div>

    <div class="row">
       

    </div>



</div>

<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>