<div class="container" style="margin-top:50px">
    <div>
        <div class="jumbotron">
            <P class="card-header fa-2x text-center font-weight-bold  alert alert-dismissible alert-primary">
                Payment - Invoice
            </P>

            <br>

            <div>
                <p-table [value]="itemsinCart">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="text-align: center;">Item Name</th>
                            <th style="text-align: center;">Quantity</th>
                            <th style="text-align: center;">Rate</th>
                            <th style="text-align: center;">Sales Value</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-itemsinCar>
                        <tr>
                            <td>{{itemsinCar.itemName}}</td>
                            <td style="text-align: center;">{{itemsinCar.purchasedQty | number : '1.2-2'}}</td>
                            <td style="text-align: right;">{{itemsinCar.itemRate | number : '1.2-2'}}</td>
                            <td style="text-align: right;">{{itemsinCar.salesValue | number : '1.2-2'}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <div class="row">
                <br>
                <br>

            </div>


            <div class="row">
                <div class="col-7" style="text-align: right;">
                    <strong>
                        Total Invoice Value :
                    </strong>

                </div>

                <div class="col-5" style="text-align: right;">
                    <p style="margin-right: 15px;">
                        <strong>
                            {{totalSales | number : '1.2-2'}}
                        </strong>
                    </p>
                </div>

            </div>

            <div class="row">
                <div class="col-7" style="text-align: right;">
                    <strong>
                        Total Discount Value :
                    </strong>

                </div>

                <div class="col-5" style="text-align: right;">
                    <p style="margin-right: 15px;">
                        <strong>
                            {{totalDiscount | number : '1.2-2'}}
                        </strong>
                    </p>
                </div>

            </div>

            <div class="row">
                <div class="col-7" style="text-align: right;">
                    <strong>
                        Total Net Sales Value :
                    </strong>

                </div>

                <div class="col-5" style="text-align: right;">
                    <p style="margin-right: 15px;">
                        <strong>
                            {{netSales | number : '1.2-2'}}
                        </strong>
                    </p>
                </div>

            </div>

            <br>

            <div class="row">
                <div class="col-7" style="text-align: right;">
                    <strong>
                        Total Item Count :
                    </strong>

                </div>

                <div class="col-5" style="text-align: right;">
                    <p style="margin-right: 15px;">
                        <strong>
                            {{totalCount | number : '1.2-2'}}
                        </strong>
                    </p>
                </div>

            </div>

            <br>

            <div style="text-align: center;">

                Total Sales Invoice Values is <strong>{{salesinWord}} </strong>, To continue to pay please press
                <strong>Pay Now</strong> button or else click Cart to <strong>back to shopping cart</strong>.


            </div>
            <br>
            <div class="row">

                <div class="col-7" style="text-align: right;">
                  

                </div>

                <div class="col-5" style="text-align: right;">
                    
                        <button class="btn btn-primary btn-lg btn-block" routerLink='/lists' routerLinkActive='active'>back to shopping cart</button>
                        <button *ngIf="totalSales>0" class="btn btn-success btn-lg btn-block" (click)="confirmPayment()">Pay Now</button>            
                    
                </div>

            </div>

        </div>
    </div>
</div>