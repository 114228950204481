<div class="container" style="margin-top:50px">



    <p-card
        header="භාවිතා කරන්නාගේ ඇතුළත්වීමේ ගැටළු කළමනාකරණය / பயனாளர்  உள்நுழைவு சிக்கல்கள் தொடர்பாளர் / User Login issues Manager"
        subheader="" [style]="{width: '100%'} " styleClass="p-card-shadow">
        <ng-template pTemplate="header">
            <P class="card-header fa-1x text-center font-weight-bold  alert alert-dismissible alert-primary">
                ඇතුල් වීමේ ගැටළු / உள்நுழைவின் போதான சிக்கல்கள் / Login Issues
            </P>

        </ng-template>
        <p-divider></p-divider>


        <div class="card-body">

            <div class="row">
                <div class="col-12">
                    <Label style="color:red; font-weight: bolder;">
                        <strong>
                            <u>
                                භාවිතා කරන්නාට නැවත e-mail එකක් එවන්න / பயனாளர் மின்னஞ்சல் செயற்படுத்தலை மீண்டும்
                                அனுப்புதல் / Resend User Activation Email
                            </u>

                        </strong>
                    </Label>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <Label style="color:rgb(19, 7, 2); font-weight: bolder;">
                        Student portal හි ලියාපදිංචි වූ ඔබට ගිණුම සක්‍රීය කිරීමට e-mail එකක් නොලැබුණි නම් නැවත e-mail
                        එකක් ගෙන්වා ගැනීමට පහත බොත්තම ඔබන්න. / மாணவர் விபரத்தளத்தில் பதிவு செய்தும் உங்களுக்கு உங்கள்
                        கணக்கினை செயற்படுத்தும் மின்னஞ்சல் கிடைக்கவில்லை எனில் கீழுள்ள சுட்டியை அழுத்தி மீண்டும் அதனை
                        பெற்றுக்கொள்ள முடியும் /
                        If you have registered in the Student Portal and have not received The Account Activation Email,
                        please use the below button to send the Activation email again.
                    </Label>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <button class="btn btn-primary" style="width: 100%;" type="submit"
                        routerLink='/ResendActivationLink'>Resend Activation eMail</button>
                </div>
            </div>


            <br>

            <div class="row">
                <div class="col-12">
                    <Label style="color:red; font-weight: bolder;">
                        <strong>
                            <u>
                                මුරපදය නැවත ඇතුළත් කිරීම (Reset) / உங்கள் கடவுச்சொல்லை மீளமைத்தல் / Reset Your Password
                            </u>

                        </strong>
                    </Label>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <Label style="color:rgb(19, 7, 2); font-weight: bolder;">
                        ඔබ ඇතුළත් කළ මුරපදය අමතක වී ඇත්නම් නැවත මුරපදයක් ඇතුළත් කිරීම සඳහා පහත බොත්තම ඔබන්න / உங்கள்
                        கடவுச்சொல்லை மறந்துவிட்டால் கீழுள்ள சுட்டியை அழுத்தி அதனை மீளமைத்துக்கொள்ள முடியும் / If you
                        have forgotten your password please use the below button to reset your password
                    </Label>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <button class="btn btn-primary" style="width: 100%;" type="submit"
                        routerLink='/forgetpassword'>Reset Password</button>
                </div>
            </div>

            <br>


            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>




        </div>



        <p-divider></p-divider>

        <ng-template pTemplate="footer">



        </ng-template>
    </p-card>
</div>