<div *ngIf="this.studRegCompDetails != null" class="container ">
    <div class="row">
        <div class="col-12">
            <P class="card-header fa-2x text-center font-weight-bold  alert alert-dismissible alert-primary"
                style="margin-top: 20px;">
                My Details
            </P>
        </div>


    </div>

    <form #StudentDetailForm="ngForm" autocomplete="off">
        <div class="row">
            <div class="col-12">
                <app-text-input [(ngModel)]="studRegCompDetails.regNumber" [label]='"Student Registration Number"' name="studRegID"
                    [childmessage]="true" [displayMessage]='"Student Registration Number"'></app-text-input>
            </div>

        </div>

        <div class="row">
            <div class="col-12">
                <app-text-input [(ngModel)]="studRegCompDetails.fullName +' ' + studRegCompDetails.surname"
                    [label]='"Student Name"' name="fullname" [childmessage]="true" [displayMessage]='"Student Name"'>
                </app-text-input>
            </div>

        </div>

        <div class="row">

            <div class="col-2">
                <app-text-input [(ngModel)]="studRegCompDetails.studentStatus" [label]='"Status"' name="status"
                    [childmessage]="true" [displayMessage]='"Status"'>
                </app-text-input>

            </div>

            <div class="col-10">
                <app-text-input [(ngModel)]="studRegCompDetails.initials +' ' + studRegCompDetails.surname"
                    [label]='"Name with Initials"' name="nameinitials" [childmessage]="true"
                    [displayMessage]='"Name with Initials"'>
                </app-text-input>

            </div>
        </div>

        <div class="row">
            <div class="col-4">
                <app-text-input [(ngModel)]="studRegCompDetails.nationalIDNumber" [label]='"NIC"' name="nic"
                    [childmessage]="true" [displayMessage]='"Student NIC"'></app-text-input>
            </div>

            <div class="col-4">
                <app-text-input [ngModel]="studRegCompDetails.dateofBirth | date: 'yyyy/MM/dd'"
                    [label]='"Date of Birth"' name="dateofbirth" [childmessage]="true"
                    [displayMessage]='"Student Date of Birth"'></app-text-input>
            </div>

            <div class="col-4">
                <app-text-input [ngModel]="studRegCompDetails.regDate | date: 'yyyy/MM/dd'"
                    [label]='"Registration Date"' name="regDate" [childmessage]="true"
                    [displayMessage]='"Student Registration date"'></app-text-input>
            </div>

        </div>

        <div class="row">
            <div class="col-12">
                <app-text-input
                    [(ngModel)]="studRegCompDetails.addressOne +' ' + studRegCompDetails.addressTwo + ' ' + studRegCompDetails.addressThree"
                    [label]='"Student Address"' name="studentaddress" [childmessage]="true"
                    [displayMessage]='"Student Address"'>
                </app-text-input>
            </div>

        </div>


        <div class="row">
            <div class="col-3">
                <app-text-input [(ngModel)]="studRegCompDetails.province" [label]='"Province"' name="province"
                    [childmessage]="true" [displayMessage]='"Province"'></app-text-input>

            </div>

            <div class="col-3">
                <app-text-input [(ngModel)]="studRegCompDetails.district" [label]='"District"' name="district"
                    [childmessage]="true" [displayMessage]='"District"'></app-text-input>
            </div>

            <div class="col-3">
                <app-text-input [(ngModel)]="studRegCompDetails.prefferedLang" [label]='"Preferred Language"'
                    name="PreferredLanguage" [childmessage]="true" [displayMessage]='"Preferred Language"'>
                </app-text-input>
            </div>

            <div class="col-3">
                <app-text-input [(ngModel)]="studRegCompDetails.gender" [label]='"Gender"' name="gender"
                    [childmessage]="true" [displayMessage]='"Gender"'></app-text-input>
            </div>

        </div>


        <div class="row">
            <div class="col-6">
                <app-text-input [(ngModel)]="studRegCompDetails.contactNumber" [label]='"Contact Number"'
                    name="contactNumber" [childmessage]="true" [displayMessage]='"Contact Number"'></app-text-input>
            </div>

            <div class="col-6">
                <app-text-input [(ngModel)]="studRegCompDetails.emailAddress" [label]='"Email Address"'
                    name="emailaddress" [childmessage]="true" [displayMessage]='"Email Address"'></app-text-input>
            </div>
        </div>


        <div class="row">
            <div class="col-3">
                <app-text-input [(ngModel)]="studRegCompDetails.renevalYear" [label]='"Reneved Year"' name="renevalYear"
                    [childmessage]="true" [displayMessage]='"Reneved Year"'></app-text-input>
            </div>

            <div class="col-3">
                <app-text-input [(ngModel)]="studRegCompDetails.lastAppliedCity" [label]='"Last Exam Applied City"'
                    name="lastAppliedCity" [childmessage]="true" [displayMessage]='"Last Exam Applied City"'>
                </app-text-input>
            </div>

            <div class="col-3">
                <app-text-input [(ngModel)]="studRegCompDetails.classCode" [label]='"Class Code"' name="classCode"
                    [childmessage]="true" [displayMessage]='"Class Code"'></app-text-input>
            </div>

            <div class="col-3">
                <app-text-input [(ngModel)]="studRegCompDetails.applicationNumber" [label]='"Application Number"'
                    name="ApplicationNumber" [childmessage]="true" [displayMessage]='"Application Number"'>
                </app-text-input>
            </div>
        </div>


    </form>

    <hr>   
    
    

<br>
<br>




</div>