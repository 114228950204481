<div class="container" style="margin-top:50px">

    <p-tabMenu [model]="items" [activeItem]="items[0]"></p-tabMenu>


    <p-card header="Applying for AAT Examination Online" subheader="" [style]="{width: '100%'} "
        styleClass="p-card-shadow">
        <ng-template pTemplate="header">
            <P class="card-header fa-2x text-center font-weight-bold  alert alert-dismissible alert-primary">
                eAdmission Help               
            </P>

        </ng-template>
        <p-divider></p-divider>


        <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);">

            <div class="row">
                <div class="col-12">
                    <Label style="color:red; font-weight: bolder;">
                        <strong>
                            <u>
                                ඔබගේ “E-admission” එක බාගත (download) කරගැනීම සදහා :
                            </u>
    
                        </strong>
                    </Label>
                </div>
            </div>
    
            <div class="row">
                <div class="col-12">
                    <Label style="color:rgb(19, 7, 2); font-weight: bolder;">
                        ඔබගේ ශිෂ්‍ය ගිණුමෙහි ඇති “My Tracker” වෙත පිවිස ඔබගේ 2022 ජනවාරි විභාගය සදහා අදාල විභාග ප්‍රවේශ
                        පත්‍රය හා අත්සන් පත්‍රය බාගත (download) කරගත හැක. (ඒ සමගම විභාග කාල සටහන අඩංගු උපදෙස් පත්‍රිකාවද
                        බාගත (download) කර ගත යුතුයි.)
                    </Label>
                </div>
            </div>
    
    
            <br>
    
            <div class="row">
                <div class="col-12">
                    <Label style="color:red; font-weight: bolder;">
                        <strong>
                            <u>
                                Download your E-admission Card :
                            </u>
    
                        </strong>
                    </Label>
                </div>
            </div>
    
            <div class="row">
                <div class="col-12">
                    <Label style="color:rgb(19, 7, 2); font-weight: bolder;">
                        You can download your E-admission Card and Signature Form relating to January 2022 Examination by
                        accessing "My Tracker" in your student account. (At the same time, the Instructions Sheet and the
                        Examination Time Table should also be downloaded)
                    </Label>
                </div>
            </div>
            <br>
    
            <div class="row">
                <div class="col-12">
                    <Label style="color:red; font-weight: bolder;">
                        <strong>
                            <u>
                                உங்களது இலத்திரனியல் அனுமதி அட்டையை பதிவிறக்கல் :
                            </u>
                        </strong>
                    </Label>
                </div>
            </div>
    
            <div class="row">
                <div class="col-12">
                    <Label style="color:rgb(19, 7, 2); font-weight: bolder;">
                        ஜனவரி 2022 பரீட்சைக்கான உங்களது இலத்திரனியல் அனுமதி அட்டை மற்றும் கையொப்ப படிவத்தை உங்கள் மாணவர்
                        கணக்கில் “My Tracker” இனை அணுகுவதன் செய்வதன் மூலம் பதிவிறக்க முடியும் .
                        (அதே நேரத்தில் பரீட்சை அறிவுறுத்தல்கள் தாள் மற்றும் பரீட்சை நேர அட்டவணையும் பதிவிறக்கம் செய்யப்பட
                        வேண்டும்)
                    </Label>
                </div>
            </div> 
    
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    
    
    
    
        </div>
    


        <p-divider></p-divider>

        <ng-template pTemplate="footer">         

            

        </ng-template>
    </p-card>
</div>


