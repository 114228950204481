import { DatePipe } from '@angular/common';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { StudentsOfflinePayments } from '../_models/StudentsOfflinePayments';
import { LogUsers } from '../_models/user';
import { AccountService } from '../_services/account.service';
import { ExamApplicationsService } from '../_services/exam-applications.service';


@Component({
  selector: 'app-manual-payment-update',
  templateUrl: './manual-payment-update.component.html',
  styleUrls: ['./manual-payment-update.component.css']
})
export class ManualPaymentUpdateComponent implements OnInit {

  user: LogUsers;
  creditValue: number;
  totalPaymentValue: number;
  netPaymentValue: number;
  disabledValue: boolean;
  payLineNumber: number;
  offlinePayments: StudentsOfflinePayments[] = [];
  displayModal: boolean = false;
  displayConfirm: boolean = false;
  payID: number = 0;
  date: Date;
  totalpaidAmount: number = 0;
  uploadStatus: boolean = false;

  referanceNumber: string;
  paidDate: Date;
  paidAmount: number = 0;
  uploadedFiles: any[] = [];
  public message: string;
  public progress: number;
  @Output() public onUploadFinished = new EventEmitter();
  fileName: string;





  constructor(private http: HttpClient, private accountService: AccountService, private examService: ExamApplicationsService,
    private toastr: ToastrService, private dialog: MatDialog, private router: Router, private fb: FormBuilder, public datepipe: DatePipe) {
    this.accountService.currentUser$.pipe(take(1)).subscribe(user => this.user = user);
    this.getUserDetails();

  }

  ngOnInit(): void {
    this.getUserDetails();
  }

  public uploadFile = (files) => {


    if (this.referanceNumber == undefined) {
      this.toastr.error("Please Enter Your Payment Voucer Number before Upload File !")
      return;
    }


    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, this.referanceNumber.toString() + ".jpg");
    this.fileName = fileToUpload.name;
    this.examService.UpdateFileToServer(formData)
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
        }
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload Success.';
          this.uploadStatus = true;
          this.onUploadFinished.emit(event.body);
        }
      });


  }

  getUserDetails() {

    this.examService.GetExamPaidAmounts().subscribe(response => {
      this.creditValue = response.creditCharge;
      this.totalPaymentValue = response.examFees;
      this.netPaymentValue = response.netPayAmount;
    }
    );

  }



  removeItemfromCart(itemLine: number, paidAmount: number): void {
    for (let order of this.offlinePayments) {
      if (order.paymentID == itemLine) {
        this.offlinePayments.splice(this.offlinePayments.indexOf(order), 1);
        break;
      }
    }

    this.totalpaidAmount = this.totalpaidAmount - paidAmount;
    this.toastr.info("Removed Line Number " + itemLine.toString());
  };



  BacktoPaymentSelection() {
    this.router.navigateByUrl('ExamPayments');
  }

  addNewPayment() {
    this.displayModal = true;
    this.payID = this.payID + 1;
    //this.inititializeForm();
  }

  confirmPayments() {
    this.displayConfirm = true;
  }


  UpdatePayments() {
    var NetBalance = this.netPaymentValue - this.totalpaidAmount;

    if (NetBalance <= 0) {
      this.examService.ManualBankPayments(this.offlinePayments).subscribe(result => {
        if(result)
        {
          this.displayConfirm = false;
          this.toastr.info("Payment Update Success")
          this.router.navigateByUrl('ManualPaymentConfirm');

        }
        else
        {
          this.displayConfirm = false;
          this.toastr.error("Payment Update Not Success, Please Contact Exam Division")
        }

      })
    }
    else {
      this.toastr.error("Payment Not Completed, Expected Payment amount not Completed !");
    }

  }





  saveNewPayment() {

    var refValue = this.offlinePayments.findIndex(refnumber => this.referanceNumber == refnumber.paymentReferance)

    if (refValue < 0) {
      if (this.paidAmount > 0) {
        if (this.referanceNumber != undefined && this.uploadStatus == true) {
          if (this.paidDate != undefined) {
            this.offlinePayments.push({
              paymentID: this.payID,
              paymentType: 3,
              studentRegID: this.user.userId,
              paymentReferance: this.referanceNumber,
              paidDate: this.paidDate,
              paidAmount: this.paidAmount,
              uploadedFileName: this.referanceNumber.toString() + ".jpg",
            });
            this.totalpaidAmount = this.totalpaidAmount + this.paidAmount;
            this.referanceNumber = undefined;
            this.paidAmount = 0;
            this.paidDate = undefined;
            this.displayModal = false;
            this.progress = 0;
            this.message = '';
            this.uploadStatus = false;
          }
          else {
            this.toastr.error("Payment Date Not Valid");
          }
        }
        else {
          if (this.uploadStatus != true) {
            this.toastr.error("Please Upload Payment Receipt");
          }
          if (this.referanceNumber === undefined) {
            this.toastr.error("Payment Referance Not Valid");
          }

        }
      }
      else {
        this.toastr.error("Payment Amount Not Valid");
      }
    }
    else {
      this.toastr.error("Payment Referance already Enterd");
    }







  }

  closeDialog() {
    this.payID = this.payID - 1;
    this.displayModal = false;
  }

}
