<div *ngIf="this.editUserProfile != null" class="container">
    <form [formGroup]="registerForm" (ngSubmit)=" registerForm.valid " autocomplete="off">
        <br>
        <br>

        <br>
        <div class="alert alert-dismissible alert-danger">
            <h1>
                <strong>Notice</strong>
            </h1>
            <p>
                Please Check and Update your Profile. You are not allowed to change your email address. To reset
                password press reset password.
            </p>

            <br>

            <h1>
                <strong>දැන්වීම</strong>
            </h1>
            <p>
                කරුණාකර ඔබේ පැතිකඩ පරීක්ෂා කර යාවත්කාලීන කරන්න. ඔබගේ විද්‍යුත් තැපැල් ලිපිනය වෙනස් කිරීමට ඔබට අවසර නැත.
                මුරපදය නැවත සැකසීමට “Reset password” බොත්තම ඔබන්න.
            </p>

            <br>
            <h1>
                <strong>அறிவிப்பு</strong>
            </h1>
            <p>
                உங்கள் சுயவிவரத்தை சரிபார்த்து புதுப்பிக்கவும். உங்கள் மின்னஞ்சல் முகவரியை மாற்ற உங்களுக்கு அனுமதி
                இல்லை. கடவுச்சொல்லை மீட்டமைக்க “கடவுச்சொல்லை மீட்டமை” பொத்தானை அழுத்தவும்.
            </p>

        </div>

        <app-text-input [formControl]='registerForm.controls["userName"]' [label]='"User Name"' [childmessage]="true"
            [displayMessage]='"Please provide the valid User Name"'></app-text-input>

        <div class="form-group">
            <label class="control-label" style="margin-right: 10px; color: coral; font-weight: bolder;"> I am a
                :</label>
            <label class="radio-inline">
                <input type="radio" value="male" class="ml-3" [formControl]='registerForm.controls["gender"]'> Male
            </label>

            <label class="radio-inline">
                <input type="radio" value="female" class="ml-3" [formControl]='registerForm.controls["gender"]'> Female
            </label>

        </div>

        <app-text-input [formControl]='registerForm.controls["userFirstName"]' [label]='"First Name"'
            [displayMessage]='"Enter User First Name"'></app-text-input>

        <app-text-input [formControl]='registerForm.controls["userLastName"]' [label]='"Last Name"'
            [displayMessage]='"Enter User Last Name"'></app-text-input>


        <app-text-input [formControl]='registerForm.controls["emailAddress"]' [label]='"Email Address"'
            [childmessage]="true" [displayMessage]='"Please provide the valid email address"'></app-text-input>


        <app-text-input [formControl]='registerForm.controls["mobileNumber"]' [label]='"Mobile Number"'
            [childmessage]="false" [displayMessage]='"Please valid Mobile Number"'></app-text-input>



        <div class="form-group">
            <label style="font-weight: bolder; color: coral;">User Category</label>
            <select class="form-control" matNativeControl [disabled]="disableSelect.value"
                [formControl]='registerForm.controls["userCategoryCode"]'>
                <option value=1>Students</option>
                <option value=2>Member</option>
                <option value=3>AEC</option>
                <option value=3>Other</option>
            </select>
        </div>


        <app-text-input [formControl]='registerForm.controls["userCategoryReferance"]'
            [label]='"User Category Reference"' [displayMessage]='"Student No. / Member No. /AEC No. / NIC No."'>
        </app-text-input>


        <br>
        <br>
        <hr>





        <hr>
        <br>
        <div class="form-group text-center">
            <button (click)="showBasicDialog()" class="btn btn-primary mr-2" type="submit">Reset Password</button>
            <button [disabled]='!registerForm.valid' (click)="updateUserProfile()"  class="btn btn-success mr-2"
                type="submit">Update Profile</button>
            <button class="btn btn-danger mr-2" (click)="cancel()" type="button">Cancel Update</button>
        </div>
        <hr>

        <br>
        <br>
        <br>
        <br>
        <br>



    </form>

    <p-dialog header="Reset Your Password" [(visible)]="displayBasic" [style]="{width: '30vw'}" [baseZIndex]="10000">

        <form #loginForm="ngForm" class="form-inline mt-2 mt-md-0"  autocomplete="off">
            <input name="passwordOne" required [(ngModel)]="model.PasswordText" class="form-control mr-sm-2"
                type="password" placeholder="Password">

            <input name="passwordTwo" required [(ngModel)]="model.PasswordTextCheck" class="form-control mr-sm-2"
                type="password" placeholder="Password Re-Enter">
            <button [disabled]="!loginForm.valid" class="btn btn-primary" (click)="resetPassword()" type="submit">Reset Password</button>
        </form>




    </p-dialog>


</div>