<div class="container" style="margin-top:50px">
    <div class="jumbotron">
        <P class="card-header fa-2x text-center font-weight-bold  alert alert-dismissible alert-primary">
            Outstanding Invoices
        </P>

        <br>

        <div>
            <p-table [value]="invOutList">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="text-align: center; ">Invoice Number</th>
                        <th style="text-align: center; width:30% ">Invoice Date</th>
                        <th style="text-align: center;">Item Quantity</th>
                        <th style="text-align: center;">Total Invoice Value</th>
                        <th style="text-align: center; width:30% ">Controls</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-invOutList>
                    <tr>
                        <td style="width: fit-content;">{{invOutList.invNumber}}</td>
                        <td style="width: fit-content;">{{invOutList.invDate | date:'medium'}}</td>
                        <td>{{invOutList.itemCount}}</td>
                        <td style="text-align: right;">{{invOutList.invAmount | number : '1.2-2'}}</td>
                        <td>
                            <span style="align-content: center;">
                                <button pButton pRipple type="button" label="View"
                                    (click)="loadOutInvoicesItems(invOutList.invNumber)"></button>
                                <button pButton pRipple type="button" label="Delete" class="p-button-danger"
                                    (click)="deleteInvoices(invOutList.invNumber)"></button>
                                <button pButton pRipple type="button" label="Pay" class="p-button-success"
                                    (click)="confirmPayment(invOutList.invNumber)"></button>
                            </span>

                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div class="row">
            <br>
            <br>

        </div>






    </div>
</div>



<p-dialog header="Invoice Details" [(visible)]="displayBasic" [style]="{width: '40vw'}" [baseZIndex]="10000">

    <form #loginForm="ngForm" class="form-inline mt-2 mt-md-0" autocomplete="off">
        <div class="jumbotron">


            <div>
                <p-table [value]="outinvItms">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="text-align: center; ">Item Name</th>
                            <th style="text-align: center;">Item Quantity</th>
                            <th style="text-align: center;">Item Rate</th>
                            <th style="text-align: center;">Total Item Value</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-outinvItms>
                        <tr>
                            <td>{{outinvItms.itemName}}</td>
                            <td>{{outinvItms.purchasedQty}}</td>
                            <td style="text-align: right;">{{outinvItms.itemRate | number : '1.2-2'}}</td>
                            <td style="text-align: right;">{{outinvItms.salesValue | number : '1.2-2'}}</td>

                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <div class="row">
                <br>
                <br>

            </div>






        </div>
    </form>




</p-dialog>