import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EditUserProfile } from '../_models/editUserProfile';
import { AccountService } from '../_services/account.service';

@Component({
  selector: 'app-userprofile-edit',
  templateUrl: './userprofile-edit.component.html',
  styleUrls: ['./userprofile-edit.component.css']
})
export class UserprofileEditComponent implements OnInit {
  @Output() cancelRegister = new EventEmitter();
  disableSelect = new FormControl(false);
  editUserProfile: EditUserProfile;
  updateuserpass: any = {};
  displayBasic: boolean;
  displayModal: boolean;
  passwordUpdateStatus: boolean = false;
  passwordResetStatus: boolean = false;

  registerForm: FormGroup;
  model: any = {};
  validPassword: string = "";

  validationErrors: string[] = [];

  constructor(public accountService: AccountService, private toastr: ToastrService, private fb: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.inititializeForm();
  }

  updateUserProfile() {
    console.log(this.registerForm.value);
    this.accountService.updateUserProfile(this.registerForm.value).subscribe(response => {
      this.passwordUpdateStatus = response;
      if (this.passwordUpdateStatus == true) {
        this.toastr.success("Updated User Profile Successfully");
        this.router.navigateByUrl('/');
      }

    }, error => {
      this.validationErrors = error;
      this.toastr.error(error.error);

    })

  }



  cancel() {

  }

  inititializeForm() {

    this.accountService.getUserEditProfile().subscribe(userProfile => {
      this.editUserProfile = userProfile;

      this.registerForm = this.fb.group({
        gender: [this.editUserProfile.gender],
        userFirstName: [this.editUserProfile.userFirstName, Validators.required],
        userName: [this.editUserProfile.userName, Validators.required],
        userLastName: [this.editUserProfile.userLastName, Validators.required],
        userCategoryCode: [this.editUserProfile.userCategoryCode],
        emailAddress: [this.editUserProfile.emailAddress, Validators.required],
        mobileNumber: [this.editUserProfile.mobileNumber, Validators.required],
        userCategoryReferance: [this.editUserProfile.userCategoryReferance, Validators.required]
      }
      )
    }
    )



  }

  matchValues(matchTo: string): ValidatorFn {
    return (control: any) => {
      return control?.value === control?.parent?.controls[matchTo].value ? null : { isMatching: true }
    }
  }



  showBasicDialog() {
    this.displayBasic = true;
  }

  resetPassword() {

    if (this.model.PasswordText == this.model.PasswordTextCheck) {
      this.validPassword = this.model.PasswordText;

      this.updateuserpass.userName = this.editUserProfile.userName;
      this.updateuserpass.password = this.validPassword;

      this.accountService.updateUserPassword(this.updateuserpass).subscribe(response => {
        this.passwordResetStatus = response;
        if (this.passwordResetStatus == true) {
          this.toastr.success('Password Updated !');
          this.displayBasic = false;
        }
        else {
          this.toastr.error('Password Updation Failed!');
        }

      }
      )
    }
    else {
      this.toastr.error('Password mismatched');
    }

  }

}
