import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AccountService } from '../_services/account.service';
import { map, retry } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {



  constructor(private accountService: AccountService, private toaster: ToastrService, private router: Router) { }

  canActivate(): Observable<boolean> {
    return this.accountService.currentUser$.pipe(
      map(user => {
        if (user) {

          if (this.tokenExpired(user.token)) {
            this.toaster.error('Token Invalid')
            this.accountService.logout();
            this.router.navigateByUrl('/')
            return false;
          } else {
            //this.toaster.error(user.token)
            return true;
          }


        }
        else {
          this.toaster.error('You Shall Not Pass !')
          return false;
        }
      })
    )
  }

  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

}
