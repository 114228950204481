import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {



  items: MenuItem[];
  activeItem: MenuItem;
  clickDisplay:string;
  helpMainTrue:boolean;
  helpExamApplicationTrue:boolean;
  helpeAdmissionTrue:boolean;
   

  constructor() { }

  ngOnInit(): void {   

    this.HelpMain();
    this.items = [
      {label: 'More Help', icon: 'pi pi-fw pi-desktop', title : 'Students Help Home', command :()=>{       
        this.HelpMain();
      } },
      {label: 'How to Apply Exam', icon: 'pi pi-fw pi-file' , title : 'How to Apply for the Examination', command :()=>{
        this.HelpHowToApplyExam();
      }},
      {label: 'eAdmission', icon: 'pi pi-fw pi-folder-open' , title : 'How to download eAdmission Form',command :()=>{
        this.HelpeAdmission();
      }}
      
    ];
  }

 
  HelpMain(){
    this.helpMainTrue = true;
    this.helpExamApplicationTrue = false;
    this.helpeAdmissionTrue =  false;
  }


  HelpHowToApplyExam(){
    this.helpMainTrue = false;
    this.helpExamApplicationTrue = true;
    this.helpeAdmissionTrue =  false;
  }

  HelpeAdmission(){
    this.helpMainTrue = false;
    this.helpExamApplicationTrue = false;
    this.helpeAdmissionTrue =  true;
  }




  

}
