<div class="card border-primary">
  
  <div class="modal-header">
    <h2>Item Review</h2> 
    <button type="button" class="close" data-dismiss="modal" (click)="onclose()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="div d-flex">
    <form class="example-form">    
    
      <mat-form-field class="example-full-width">
        <mat-label>Comment Here . . . .</mat-label>
        <textarea matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="11" name="userview" #name="ngModel" [(ngModel)]="model.userReview"  >{{dialogTitle}}</textarea>
      </mat-form-field>
    </form>
  </div>
  <div class="btn-group d-flex modal-footer">
    <button class="btn btn-primary"(click)="updateReview()">Submit</button>
    <button class ="btn btn-success" (click)="onclose()" >Cancel</button>  
    
</div>

</div>