<body>
    <ngx-spinner>
        <h4>Loading . . . . .</h4>
    </ngx-spinner>
    

    <header>
        <app-nav>
            
        </app-nav>
    </header>

    <div>
        <router-outlet>
        </router-outlet>

        <div class="card bg-ligcard text-white bg-primary mb-3" style="background-color: slategrey;">
            <!-- Footer -->
            <footer class="page-footer font-small blue pt-4" style="background-color: slategray;"> 

                <!-- Footer Links -->
                <div class="container-fluid text-center text-md-left" style="background-color: slategrey;">

                    <!-- Grid row -->
                    <div class="row" style="background-color: slategrey;">

                        <!-- Grid column -->
                        <div class="col-md-6 mt-md-0 mt-3">

                            <!-- Content -->
                            <h2 class="text-uppercase">AAT Student's Portal</h2>
                            <br>

                           

                        </div>
                        <!-- Grid column -->

                        <hr class="clearfix w-100 d-md-none pb-3">

                        <!-- Grid column -->
                        <div class="col-md-3 mb-md-0 mb-3">

                            <!-- Links -->
                            <h2 class="text-uppercase">Address</h2>

                            <ul class="list-unstyled">
                                <li>
                                    <p>No. 540,</p>
                                </li>
                                <li>
                                    <p>Ven. Muruththettuwe Ananda Nahimi Mw,</p>
                                </li>
                                <li>
                                    <p>Narahenpita,</p>
                                </li>
                               
                                <li>
                                    <p>Colombo 05</p>
                                </li>
                            </ul>

                        </div>
                        <!-- Grid column -->

                        <!-- Grid column -->
                        <div class="col-md-3 mb-md-0 mb-3">

                            <!-- Links -->
                            <h2 class="text-uppercase">Contact</h2>

                            <ul class="list-unstyled">
                                <li>
                                    <p>Tel: 0112-559669</p>
                                </li>
                                <li>
                                    <p>Email: info@aatsl.lk</p>
                                </li>
                                <li>
                                    <button pButton pRipple type="button" onclick="window.location.href=' https://www.facebook.com/AATSriLankaOfficial/';" icon="fa fa-facebook" class="p-button-rounded p-button-info" style="font-size: 20px; background-color: hsla(206, 3%, 54%, 0.925); color: white;"></button>
                                    &nbsp;
                                    <button pButton pRipple type="button" onclick="window.location.href=' https://www.instagram.com/aat_srilanka_official/';" icon="fa fa-instagram" class="p-button-rounded p-button-info" style="font-size: 20px; background-color: hsla(206, 3%, 54%, 0.925); color: white;"></button>
                                    &nbsp;
                                    <button pButton pRipple type="button" onclick="window.location.href=' https://www.youtube.com/channel/UCKHgO2j1mqI5vFxJioRSPMQ';" icon="fa fa-youtube-play" class="p-button-rounded p-button-info" style="font-size: 20px; background-color: hsla(206, 3%, 54%, 0.925); color: white;"></button>
                                    &nbsp;                                    
                                    <button pButton pRipple type="button" onclick="window.location.href=' https://www.linkedin.com/company/aatsrilanka';" icon="fa fa-linkedin" class="p-button-rounded p-button-info" style="font-size: 20px; background-color: hsla(206, 3%, 54%, 0.925); color: white;"></button>
                                     &nbsp;
                                    
                                </li>
                               
                                <li>
                                    <br>
                                    <p> WhatsApp/Viber: 0760-559669 | 0777559669 </p>
                                </li>
                            </ul>

                        </div>
                        <!-- Grid column -->

                    </div>
                    <!-- Grid row -->

                </div>
                <!-- Footer Links -->

                <!-- Copyright -->
                <div class="footer-copyright text-center py-3 card text-white bg-primary mb-3">© 2021 Copyright:
                    <a href="https://www.aatsl.lk/"> © AAT Sri Lanka. All rights reserved.</a>
                </div>
                <!-- Copyright -->

            </footer>
            <!-- Footer -->

        </div>


    </div>


</body>