import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StudentsMasterDetails } from '../_models/StudentsMasterDetails';
import { AccountService } from '../_services/account.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @Output() cancelRegister = new EventEmitter();
  disableSelect = new FormControl(false);


  registerForm: FormGroup;
  validationErrors: string[] = [];
  StudentDetails: StudentsMasterDetails ;
  agreestatus :boolean = false;
  notagreebanner:string="";
  studentRegistrationID :string;

  constructor(private accountService: AccountService, private toastr: ToastrService, private fb: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.inititializeForm();
  }

  inititializeForm() {
    this.registerForm = this.fb.group({     
      studentRegID : ['', Validators.required],
      emailAddress: ['', Validators.compose([Validators.required,Validators.email])],
      contactNumber: ['', Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")])],
      userPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(44)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(44),this.matchValues('userPassword')]]
      
    })
  }

  matchValues(matchTo: string): ValidatorFn {
    return (control: any) => {
      return control?.value === control?.parent?.controls[matchTo].value ? null : { isMatching: true }
    }
  }

  GetStudentDetails(){
    this.StudentDetails = undefined;
    this.accountService.getStudentsDetails(this.registerForm.controls["studentRegID"].value).subscribe(response =>{
      this.StudentDetails = response;
    })
  }

  notagree(): ValidatorFn {
    return (control: any) => {
      return control?.value === "Agree" ? null : { isMatching: true }
    }
  }

  notagreeIssue(){
    this.agreestatus = false;
    this.notagreebanner = "If you are not agreed to terms & conditions, you are not allowed to register to online purchasing application.";
  }

  notagreeIssueOk(){
    this.agreestatus = true;
    this.notagreebanner = "";
  }
  

  register() {
    console.log(this.registerForm.value);
    this.accountService.register(this.registerForm.value).subscribe(response => {
      this.router.navigateByUrl('/regi-conf');
    }, error => {
      this.validationErrors = error;  
     // this.toastr.error(error.error);     
      
    })



  }

  cancel() {
    this.cancelRegister.emit(false);
  }

}




