import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { ExemptionQualifications } from '../_models/ExemptionQualifications';
import { ExemptionSubjects } from '../_models/ExemptionSubjects';
import { SystemControlFile } from '../_models/SystemControlFile';
import { LogUsers } from '../_models/user';
import { AccountService } from '../_services/account.service';
import { ExamApplicationsService } from '../_services/exam-applications.service';
import { ExemptionManagerService } from '../_services/exemption-manager.service';

@Component({
  selector: 'app-students-exewmption-request',
  templateUrl: './students-exewmption-request.component.html',
  styleUrls: ['./students-exewmption-request.component.css']
})
export class StudentsExewmptionRequestComponent implements OnInit {

  renevalStatus: boolean = false;
  BlackListedStatus: boolean = false;
  sysConFile: SystemControlFile;
  studentRegID: string;
  displayModal: boolean = false;
  selectedValues: boolean = false;

  position: string;
  nameofme: string;

  StageOneQualificationSelect: string = undefined;
  StageTwoQualificationSelect: string = undefined;
  StageThreeQualificationSelect: string = undefined;
  StageSKIQualificationSelect: string = undefined;

  ValidationError: string;

  SelectedSubjectName: string;


  user: LogUsers;

  examAppForm: FormGroup;

  stageOneSubjects: ExemptionSubjects[];
  stageTwoSubjects: ExemptionSubjects[];
  stageThreeSubjects: ExemptionSubjects[];
  stageSKISubjects: ExemptionSubjects[];

  SubjectsSelectedOne: ExemptionSubjects[] = [];
  SubjectsSelectedTwo: ExemptionSubjects[] = [];
  SubjectsSelectedThree: ExemptionSubjects[] = [];
  SubjectsSelectedSKI: ExemptionSubjects[] = [];
  AppliedCompleteSubjects: ExemptionSubjects[] = [];
  ExemptionQualifications: ExemptionQualifications[] = [];

  DisplayConfirm: boolean = false;
  SaveConfirmation:boolean = false;


  readStatus: boolean = false;

  ValidateVerification: boolean;


  constructor(private accountService: AccountService, private exempservice: ExemptionManagerService, private fb: FormBuilder, private examService: ExamApplicationsService, private toastr: ToastrService, private dialog: MatDialog, private router: Router) {
    this.accountService.currentUser$.pipe(take(1)).subscribe(user => this.user = user);
  }

  ngOnInit(): void {
    this.getRenevalStatus();
  }




  formClose() {
    this.displayModal = false;
  }


  UpdateConfirmation() {
    this.DisplayConfirm = false;
  }



  getRenevalStatus() {
    this.exempservice.getExemptionRenevalStatus().subscribe(response => {
      this.renevalStatus = response;
      if (this.renevalStatus) {
        this.getBlackListed();
        this.inititializeForm();
        this.getExamAppliysubjects();
        this.getExemptionQualifications();

      }
    }
    );
  }

  UpdateReadStatus() {
    if (this.selectedValues) {
      this.readStatus = true;
    }
    else {
      this.toastr.error("Please Confirm and Click Agree");
      this.readStatus = false;
    }
  }

  CheckAppliedQualifications() {

  }


  inititializeForm() {

    this.examAppForm = this.fb.group({
      studentRegID: ["369258741"],
      cityCode: ['', Validators.required],
      stageOneMedium: ['', Validators.required],
      stageTwoMedium: ['', Validators.required],
      stageThreeMedium: ['', Validators.required]
    }

    )

  }


  getExamAppliysubjects() {
    this.exempservice.getExemptionPendingSubjects().subscribe(response => {
      this.stageOneSubjects = response.filter(x => x.stageCode == "LE1");
      this.stageTwoSubjects = response.filter(x => x.stageCode == "LE2");
      this.stageThreeSubjects = response.filter(x => x.stageCode == "LE3");
      this.stageSKISubjects = response.filter(x => x.stageCode == "SKI");
      this.toastr.success("Student Information Received !");
    })
  }

  getBlackListed() {
    this.accountService.getBlackListedStatus().subscribe(response => {
      this.BlackListedStatus = response
    });
  }


  getExemptionQualifications() {
    this.exempservice.getExemptionQualificationList().subscribe(response => {
      this.ExemptionQualifications = response
    });
  }

  checkAppliedStatus() {

    var SubjectCodeStageOne = undefined;
    var QualificationCodeStageOne = 0;

    for (let j = 0; j < this.SubjectsSelectedOne.length; j++) {

      QualificationCodeStageOne = this.SubjectsSelectedOne[j].qualificationCode;

      if (QualificationCodeStageOne == 0) {
        SubjectCodeStageOne = (SubjectCodeStageOne == undefined ? "" : SubjectCodeStageOne) + (SubjectCodeStageOne == undefined ? "" : ",") + this.SubjectsSelectedOne[j].subjectCode;
      }

    }


    var SubjectCodeStageTwo = undefined;
    var QualificationCodeStageTwo = 0;

    for (let j = 0; j < this.SubjectsSelectedTwo.length; j++) {

      QualificationCodeStageTwo = this.SubjectsSelectedTwo[j].qualificationCode;

      if (QualificationCodeStageTwo == 0) {
        SubjectCodeStageTwo = (SubjectCodeStageTwo == undefined ? "" : SubjectCodeStageTwo) + (SubjectCodeStageTwo == undefined ? "" : ",") + this.SubjectsSelectedTwo[j].subjectCode;
      }

    }


    var SubjectCodeStageThree = undefined;
    var QualificationCodeStageThree = 0;

    for (let j = 0; j < this.SubjectsSelectedThree.length; j++) {

      QualificationCodeStageThree = this.SubjectsSelectedThree[j].qualificationCode;

      if (QualificationCodeStageThree == 0) {
        SubjectCodeStageThree = (SubjectCodeStageThree == undefined ? "" : SubjectCodeStageThree) + (SubjectCodeStageThree == undefined ? "" : ",") + this.SubjectsSelectedThree[j].subjectCode;
      }

    }


    var SubjectCodeStageSKI = undefined;
    var QualificationCodeStageSKI = 0;

    for (let j = 0; j < this.SubjectsSelectedSKI.length; j++) {

      QualificationCodeStageSKI = this.SubjectsSelectedSKI[j].qualificationCode;

      if (QualificationCodeStageSKI == 0) {
        SubjectCodeStageSKI = (SubjectCodeStageSKI == undefined ? "" : SubjectCodeStageSKI) + (SubjectCodeStageSKI == undefined ? "" : ",") + this.SubjectsSelectedSKI[j].subjectCode;
      }

    }


    this.StageOneQualificationSelect = SubjectCodeStageOne;
    this.StageTwoQualificationSelect = SubjectCodeStageTwo;
    this.StageThreeQualificationSelect = SubjectCodeStageThree;
    this.StageSKIQualificationSelect = SubjectCodeStageSKI;


    if(SubjectCodeStageOne != undefined ||  SubjectCodeStageTwo != undefined || SubjectCodeStageThree != undefined || SubjectCodeStageSKI != undefined)
    {
         this.ValidateVerification = true;
         this.ValidationError = "Please Select Valid Qualifications for the selected Subjects.  -  " + SubjectCodeStageOne + " " + SubjectCodeStageTwo + " " + SubjectCodeStageThree + " "+  SubjectCodeStageSKI;
    }
    else
    {

      if(this.SubjectsSelectedSKI.length <= 0 && this.SubjectsSelectedOne.length <= 0 && this.SubjectsSelectedTwo.length <= 0 && this.SubjectsSelectedThree.length <= 0)
      {
        this.ValidationError = "Please select Exemption Required Subjects ";

        this.ValidateVerification = true;
        this.SaveConfirmation = false;

      }
      else
      {
        this.ValidateVerification = false;
        this.SaveConfirmation = true;
      }

      
    }



  }

  validationConfirmationCheck(){

    this.ValidateVerification = false;

  }

  clickMethod(selectSubject: ExemptionSubjects, event: any) {

    if (event == true) {
      if (selectSubject.applyStatus == "1") {
        this.SelectedSubjectName = selectSubject.subjectCode + " - " + selectSubject.subjectName;
        this.DisplayConfirm = true;
      }
      else {
        this.DisplayConfirm = false;
      }


    }
    else {
      this.DisplayConfirm = false;
    }

  }





  CalculateExamFees(): number {


    var SubjectCodeStageOne = undefined;
    var QualificationCodeStageOne = 0;

    for (let j = 0; j < this.SubjectsSelectedOne.length; j++) {

      QualificationCodeStageOne = this.SubjectsSelectedOne[j].qualificationCode;

      if (QualificationCodeStageOne == 0) {
        SubjectCodeStageOne = (SubjectCodeStageOne == undefined ? "" : SubjectCodeStageOne) + (SubjectCodeStageOne == undefined ? "" : ",") + this.SubjectsSelectedOne[j].subjectCode;
      }

    }


    var SubjectCodeStageTwo = undefined;
    var QualificationCodeStageTwo = 0;

    for (let j = 0; j < this.SubjectsSelectedTwo.length; j++) {

      QualificationCodeStageTwo = this.SubjectsSelectedTwo[j].qualificationCode;

      if (QualificationCodeStageTwo == 0) {
        SubjectCodeStageTwo = (SubjectCodeStageTwo == undefined ? "" : SubjectCodeStageTwo) + (SubjectCodeStageTwo == undefined ? "" : ",") + this.SubjectsSelectedTwo[j].subjectCode;
      }

    }


    var SubjectCodeStageThree = undefined;
    var QualificationCodeStageThree = 0;

    for (let j = 0; j < this.SubjectsSelectedThree.length; j++) {

      QualificationCodeStageThree = this.SubjectsSelectedThree[j].qualificationCode;

      if (QualificationCodeStageThree == 0) {
        SubjectCodeStageThree = (SubjectCodeStageThree == undefined ? "" : SubjectCodeStageThree) + (SubjectCodeStageThree == undefined ? "" : ",") + this.SubjectsSelectedThree[j].subjectCode;
      }

    }


    var SubjectCodeStageSKI = undefined;
    var QualificationCodeStageSKI = 0;

    for (let j = 0; j < this.SubjectsSelectedSKI.length; j++) {

      QualificationCodeStageSKI = this.SubjectsSelectedSKI[j].qualificationCode;

      if (QualificationCodeStageSKI == 0) {
        SubjectCodeStageSKI = (SubjectCodeStageSKI == undefined ? "" : SubjectCodeStageSKI) + (SubjectCodeStageSKI == undefined ? "" : ",") + this.SubjectsSelectedSKI[j].subjectCode;
      }

    }


    this.StageOneQualificationSelect = SubjectCodeStageOne;
    this.StageTwoQualificationSelect = SubjectCodeStageTwo;
    this.StageThreeQualificationSelect = SubjectCodeStageThree;
    this.StageSKIQualificationSelect = SubjectCodeStageSKI;

    var StageOneFee = 0;
    var StageTwoFee = 0;
    var StageThreeFee = 0;
    var StageSKIFee = 0;

    for (let j = 0; j < this.SubjectsSelectedOne.length; j++) {
      StageOneFee += this.SubjectsSelectedOne[j].exemptionFees
    }

    for (let j = 0; j < this.SubjectsSelectedTwo.length; j++) {
      StageTwoFee += this.SubjectsSelectedTwo[j].exemptionFees
    }

    for (let j = 0; j < this.SubjectsSelectedThree.length; j++) {
      StageThreeFee += this.SubjectsSelectedThree[j].exemptionFees
    }

    for (let j = 0; j < this.SubjectsSelectedSKI.length; j++) {
      StageSKIFee += this.SubjectsSelectedSKI[j].exemptionFees
    }



    return StageOneFee + StageTwoFee + StageThreeFee + StageSKIFee;

  }


  proceedtoSaveFiles(){
    this.SaveConfirmation =  false;

  }

}
