<div class="container">
    <div class="mainCover" style="margin-top: 100px;">

        <mat-card class="boxModel">
            <h1 class="headerfont" style="text-align: center;">
                <strong>
                    To reset password Enter Your Email Address

                </strong>
            </h1>

            <br>

            <p>If you have forgotten your password, enter your registered email address and can generate your new
                password. New Password will be dispatched to your registered email address. </p>

            <br>
            <p>ඔබට ඔබගේ මුරපදය අමතක වී ඇත්නම්, ඔබගේ ලියාපදිංචි ඊමේල් ලිපිනය ඇතුළත් කර ඔබගේ නව මුරපදය ජනනය කළ හැකිය. නව
                මුරපදය ඔබගේ ලියාපදිංචි ඊමේල් ලිපිනයට යවනු ලැබේ. </p>

            <br>
            <p>உங்கள் கடவுச்சொல்லை நீங்கள் மறந்துவிட்டால், உங்கள் பதிவுசெய்த மின்னஞ்சல் முகவரியை உள்ளிட்டு உங்கள் புதிய
                கடவுச்சொல்லை உருவாக்கலாம். புதிய கடவுச்சொல் உங்கள் பதிவு செய்யப்பட்ட மின்னஞ்சல் முகவரிக்கு
                அனுப்பப்படும். </p>

            <br>

            <form #resetForm="ngForm" (ngSubmit)="resetPassword()" autocomplete="off">
                <div class="row">

                    <div class="col-10" style="text-align: right; width: 100%;">
                        <input name="emailAddress" required [(ngModel)]="emailAddress" class="form-control mr-sm-2"
                            type="text" placeholder="Email Address" style="width: 100%;">
                    </div>


                    <div class="col-2">
                        <button [disabled]="!resetForm.valid" class="btn btn-primary" type="submit">Reset
                            Password</button>
                    </div>
                </div>

                <div *ngIf="resetStatus == true">
                    <strong>
                        <p>Password dispatched to Your Registered Email Address, Please Check your Email. </p>
                    </strong>
                </div>
            </form>

            <br>
            <br>
            <br>


        </mat-card>
      

    </div>


    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>

</div>