import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SalesItemsInter } from 'src/app/_models/SalesItemsInt';
import { ItemReview } from 'src/app/_models/itemreview';
import { SalesitemsService } from 'src/app/_services/salesitems.service';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MemberReviewComponent } from '../member-review/member-review.component';
import { AccountService } from 'src/app/_services/account.service';
import { LogUsers } from 'src/app/_models/user';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UserItemCart } from 'src/app/_models/useritemcart';
import { SalesItemsDetails } from 'src/app/_models/salesitemsdetails';



@Component({
  selector: 'app-sales-items-details',
  templateUrl: './sales-items-details.component.html',
  styleUrls: ['./sales-items-details.component.css']
})
export class SalesItemsDetailsComponent implements OnInit {

  item: SalesItemsInter;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  userReview: ItemReview[];
  salesitemsDetails: SalesItemsDetails[] = [];
  user: LogUsers;

  CartItems: any = {};


  constructor(private itemservice: SalesitemsService, public accountservive: AccountService, private route: ActivatedRoute, private dialog: MatDialog, private toastr: ToastrService) {

    this.accountservive.currentUser$.pipe(take(1)).subscribe(user => this.user = user);


  }

  ngOnInit(): void {
    this.loadSalesItem();

    this.galleryOptions = [
      {
        width: '500px',
        height: '500px',
        imagePercent: 100,
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        preview: false
      },
    ]

  }



  onclick() {

    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;
    dialogconfig.width = "30%";
    dialogconfig.height = "40%";
    dialogconfig.data = { dataKey: this.item.firstReview, itemcode: this.item.itemCodeID, usernamekey: this.user.userId };
    this.dialog.afterAllClosed.subscribe(data => this.loadSalesItem());
    this.dialog.open(MemberReviewComponent, dialogconfig);

  }

  getImages(): NgxGalleryImage[] {
    const imagegeUrls = [];
    for (const photo of this.item.photos) {
      imagegeUrls.push({
        small: photo?.url,
        medium: photo?.url,
        big: photo?.url
      })
    }
    return imagegeUrls;
  }

  getReview(): ItemReview[] {
    const userview = [];
    for (const review of this.item.itemReviews) {
      userview.push({
        id: review?.id,
        userName: review?.userName,
        userReview: review?.userReview,
        isMain: review?.isMain

      })
    }

    if (userview.length > 0) {
      return userview;
    }
    else {
      return [];
    }

  }

  returnParameter(): number {
    if (this.route.snapshot.paramMap.get('itemCode') == undefined || null) {
      return 1;

    }
    else {
      return Number(this.route.snapshot.paramMap.get('itemCode'));
    }
  }



  loadSalesItem() {
    this.itemservice.getSalesItem(this.returnParameter()).subscribe(item => {
      this.item = item;
      this.galleryImages = this.getImages();
      this.userReview = this.getReview();
      this.getSalesItemsDetails(this.item.itemCodeID);

    })
  }

  getSalesItemsDetails(itemcode: number) {

    this.itemservice.geSalesItemsDetails(itemcode).subscribe(itemDetails => {
      this.salesitemsDetails = itemDetails;

    })

  }

  AddItemToCart() {

    this.CartItems.itemCode = this.item.itemCodeID;
    this.CartItems.userName = this.user.userId;
    this.CartItems.purchasedQty = 1;
    this.CartItems.itemRate = this.item.itemPrice;
    this.CartItems.salesValue = this.item.itemPrice;
    this.CartItems.addedDateTime = new Date();


    this.itemservice.addUserItemtoCart(this.CartItems).subscribe(response => {
      console.log(response);
      this.toastr.success("Your Item Added to Cart");
    }, error => {
      console.log(error);
      this.toastr.error(error.error);
    }
    )
  }

}


