import { Component, OnInit } from '@angular/core';
import { StudentExamApplicationSubjects } from '../_models/StudentExamApplicationSubjects';
import { StudentExamApplication } from '../_models/StudentsExamApplication';
import { StudentsExamApplicationPayments } from '../_models/StudentsExamApplicationPayments';
import { ExamApplicationsService } from '../_services/exam-applications.service';

@Component({
  selector: 'app-exam-application-confirmation',
  templateUrl: './exam-application-confirmation.component.html',
  styleUrls: ['./exam-application-confirmation.component.css']
})
export class ExamApplicationConfirmationComponent implements OnInit {


  appliedDetails: StudentExamApplication;
  studentsApplicationSubjects: StudentExamApplicationSubjects[];
  studentsPayments: StudentsExamApplicationPayments[];
  totalSubjectCount: number;
  totalPaymentLines: number;
  totalPaidAmount: number;

  constructor(private examService: ExamApplicationsService) { }



  ngOnInit(): void {
    this.getExamAppliedDetails();
  }


  getExamAppliedDetails() {

    this.examService.checkExamAppliedDetails().subscribe(details => {

      this.appliedDetails = details;
      this.studentsApplicationSubjects = this.appliedDetails.studentsAppliedExamApplicationSubjectsDto;
      this.studentsPayments = this.appliedDetails.studentsAppliedExamApplicationPaymentsDto;
      this.totalSubjectCount = this.studentsApplicationSubjects.length;
      this.totalPaymentLines = this.studentsPayments.length;
      this.getTotalPaidAmount();
      //this.totalPaidAmount = this.studentsPayments.values

    });
  }


  getTotalPaidAmount() {

    let p : number = 0;

    for (let item of this.studentsPayments) {
      p = p + item.paidAmount;
    }

    this.totalPaidAmount = p;
  }

}
