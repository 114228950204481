import { Component, OnInit } from '@angular/core';
import { SalesitemsService } from 'src/app/_services/salesitems.service';
import {UserItemCartDetails} from 'src/app/_models/useritemcartdetails';
import { ToastrService } from 'ngx-toastr';
import { SalesInvoiceService } from '../_services/sales-invoice.service';


@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.css']
})
export class ListsComponent implements OnInit {

  itemsinCart: UserItemCartDetails[] = [];
  totalSales: number = 0;
  totalDiscount: number = 0;
  netSales:number = 0;
  itemString:string="";

  constructor(private itemService :SalesitemsService, private toastr:ToastrService, private invoicceService:SalesInvoiceService) { }

  ngOnInit(): void {
    this.loadItemsToCart();
  }


  loadItemsToCart(){
    this.itemService.getUserItemCartDetails().subscribe(itemCarts=>{
      this.itemsinCart = itemCarts;
      this.createItemString();
      
    })
  }

  calculateTotalSales(){
    this.itemsinCart.forEach(a => this.totalSales += a.salesValue);
    this.netSales = this.totalSales - this.totalDiscount;
    console.log(this.totalSales);
  }

  createItemString(){
    this.itemsinCart.forEach(a => {
      if(this.itemString == "")
      {
        this.itemString = a.itemCode.toString()
      }
      else
      {
        this.itemString = this.itemString + "A"+ a.itemCode.toString();
      }
    });

    if(this.itemString != "")
    {
      this.invoicceService.CreateDiscount(this.itemString).subscribe(discountValue=>{
        this.totalDiscount = discountValue;
        this.calculateTotalSales();
      })
    }
    else
    {
      this.totalDiscount = 0;
    }

    

    

  }

  removeItemfromCart(itemLine:number){
    this.totalSales = 0;
    this.itemService.removeUserItemfromCart(itemLine).subscribe(item=>{
      console.log(itemLine.toString());
      this.loadItemsToCart();
      this.toastr.success("Selected Item Removed from Shopping Cart");   
    },error=>{
      console.log(error);
      this.toastr.error(error.error);             
    }
    );

    
    
    
  }
  }

