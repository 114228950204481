import { Component, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, pipe, throwError } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';





@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private toastr:ToastrService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {

        if(error) {

          switch(error.status){
            case 400:
            
            if(error.error.errors){
              const modelStateErrors = [];
              for(const key in error.error.errors) {
                if(error.error.errors[key]){
                  modelStateErrors.push(error.error.errors[key])
                }
              }
              throw modelStateErrors.flat();              
            } 
            else
            {
              //this.toastr.error(error.statusText, error.status);
     
            }             
            break;

             case 401:             
             this.toastr.error(error.error.message, error.status);     
             break;

             case 404:
             this.toastr.error(error.error.message, error.status);
             //this.messageService.add({key: 'myKey1', severity:'success', summary: 'Summary Text', detail: 'Detail Text'});
             break;

             case 409:
              this.toastr.error(error.error.message, error.status);
              //this.messageService.add({key: 'myKey1', severity:'success', summary: 'Summary Text', detail: 'Detail Text'});
              break;
            
            case 500:
            const navigationExtras: NavigationExtras = {state: {error: error.error}}
            this.router.navigateByUrl('/server-error',navigationExtras);
            //this.toastr.error("System Issues");
            break;

            

            default:
              this.toastr.error('Something unexpected went wrong ' + error.error.error);
              console.log(error);
              break;

          }
        }
        return throwError(error);
      })
    );
  }
}
