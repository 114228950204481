import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-exame-admission-help',
  templateUrl: './exame-admission-help.component.html',
  styleUrls: ['./exame-admission-help.component.css']
})
export class ExameAdmissionHelpComponent implements OnInit {

  displayConfirm:boolean;
  items: MenuItem[];
  activeItem: MenuItem;


  constructor() { }

  ngOnInit(): void {

    this.items = [
      {label: 'Home', icon: 'pi pi-fw pi-home',routerLink:   ['/help']},
      {label: 'How to Apply Exam', icon: 'pi pi-fw pi-home',routerLink:   ['/ExamHelp']},
      {label: 'eAdmission', icon: 'pi pi-fw pi-calendar',routerLink:   ['/EAdmissionHelp']}
  ];

  }

}
