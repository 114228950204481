import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserCurrentStatus } from '../_models/usercurrentstatus';
import { AccountService } from '../_services/account.service';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {

  userItemDetails: UserCurrentStatus[];
  model: any = {};
  fromDate :any;
  toDate :any;
  enablebutton:boolean= false;

  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: ['User ID','User Name','User Full Name','Mobile Number','Email Address','Purchased Status','Installed Status'],
    showTitle: true,
    title: 'Members Details',
    useBom: false,
    removeNewLines: true,
    keys: ['userID','userName','userFullName','userMobileNumber','userEmailAddress','purvchasedStatus','installedStatus']
  };


  constructor(public accountService: AccountService, private toastr: ToastrService, private fb: FormBuilder, private router: Router,public datepipe: DatePipe) { }

  ngOnInit(): void {
  }


  getUserDetails() {
    
    this.fromDate = this.datepipe.transform(this.model.fromDate, 'yyyy-MM-dd');
    this.toDate = this.datepipe.transform(this.model.todate, 'yyyy-MM-dd');

    this.accountService.getUserDetailsStatus(this.fromDate, this.toDate).subscribe(userDetails => {
      this.userItemDetails = userDetails; 
      if(userDetails.length > 0)
      {
        this.enablebutton = true;
      }     
      else
      {
        this.enablebutton = false;
      }
        
      
    })
  }  
}
