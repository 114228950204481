<div class="container">
  <div class="alert alert-dismissible alert-danger" style="margin-top: 10px;">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    <strong>Downloads Warning</strong>
    <br>
    <a href="#" class="alert-link">If you have already downloaded this application, please don't download application
      again.
      <br>
    </a> If New Version release you will get notified via email. You need to uninstall existing application and download
    new version

    <br>
    <br>

    <strong>බාගතකිරීම් අනතුරු ඇඟවීම</strong>
    <br>
    <a href="#" class="alert-link">ඔබ දැනටමත් මෙම යෙදුම බාගත කර ඇත්නම්, කරුණාකර නැවත යෙදුම බාගත නොකරන්න.
      <br>
    </a> අනුවාදය නිකුත් කළහොත් ඔබට විද්‍යුත් තැපෑලෙන් දැනුම් දෙනු ලැබේ. ඔබට පවතින යෙදුම අස්ථාපනය කර නව අනුවාදයක් බාගත කළ
    යුතුය

    <br>
    <br>
    <strong>பதிவிறக்கங்கள் எச்சரிக்கை</strong>
    <br>
    <a href="#" class="alert-link">நீங்கள் ஏற்கனவே இந்த பயன்பாட்டை பதிவிறக்கம் செய்திருந்தால், தயவுசெய்து விண்ணப்பத்தை
      மீண்டும் பதிவிறக்க வேண்டாம்.
      <br>
    </a> புதிய பதிப்பு வெளியீடு என்றால் உங்களுக்கு மின்னஞ்சல் வழியாக அறிவிக்கப்படும். நீங்கள் ஏற்கனவே உள்ள பயன்பாட்டை
    நிறுவல் நீக்கி புதிய பதிப்பைப் பதிவிறக்க வேண்டும்
    <br>

  </div>

  <div class="jumbotron">
    <h1 class="display-3">AAT - eBook Reading Tool</h1>
    <p class="lead">First you have to purchase the required books. Once it is completed download this application and
      install to your PC or Laptop (allowed to install only one unit)</p>
    <br>
    <h1 class="display-3">AAT - විද්‍යුත් පොත් කියවීමේ මෙවලම</h1>
    <p class="lead">පළමුව ඔබ අවශ්‍ය පොත් මිලදී ගත යුතුය. එය සම්පුර්ණ වූ පසු මෙම යෙදුම බාගත කර (302 Mb) ඔබේ ලැප්ටොප්
      පරිගණකයට ස්ථාපනය කරන්න (ස්ථාපනය කල හැක්කේ එක උපාංගයකට පමණි )</p>
    <br>
    <h1 class="display-3">AAT - மின்புத்தக வாசிப்பு கருவி</h1>
    <p class="lead">முதலில் நீங்கள் தேவையான புத்தகங்களை வாங்க வேண்டும். இது முடிந்ததும் இந்த பயன்பாட்டைப்
      பதிவிறக்குங்கள் (302 மெ.பை) உங்கள் லேப்டாப்பின் கணினியில் நிறுவவும் (1 சாதனம் மட்டும்)</p>

    <hr class="my-4">
    <p>Click Here to download Application</p>
    <p class="lead">
      
      <a href="assets/AAT_E-Study_PAck.zip" target="_self">
        <p-button label="Download" icon="pi pi-check" styleClass="p-button-lg" ></p-button>
      </a>


    </p>

    <button pButton pRipple type="button" label="How to Download and Install the e-Study text system" class="p-button-success"
    (click)="loadHelpDisplay()"></button>


  </div>

  <div class="row">

    <div class="col-4">
      <div class="card text-white bg-info mb-3" style="width: 100%;">
        <div class="card-header" style="background-color: rgb(23, 55, 94);">Register</div>
        <div class="card-body" style="background-color: rgb(65, 129, 189);">
          <p>Register Application</p>
          <p class="card-text">Once you register always use the same user name and the password when you are using this
            portal. To get the updates and other necessary information related to this portal please provide the valid
            email address at the time when you are registering. <br><br></p>
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="card text-white bg-primary mb-3" style="width: 100%;">
        <div class="card-header" style="background-color: maroon;">Download</div>
        <div class="card-body" style="background-color: rgb(228, 108, 10);">
          <p>Download eBooks Reading Tool</p>
          <p class="card-text">After login select the required eBooks and make the online payment. Now download the
            eBooks Reading Tool using the same PC or Laptop (This is not compatible for Mobile devices). <br> <br> <br>
          </p>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card text-white bg-warning mb-3" style="width: 100%;">
        <div class="card-header" style="background-color: rgb(0, 0, 0);">Install</div>
        <div class="card-body" style="background-color: rgb(127, 127, 127);">
          <p>Install Application</p>
          <p class="card-text">To Install the eBooks Reading Tool you need to have Windows 8-10 with 4GB RAM in your PC
            or Laptop. Download the eBooks Reading Tool and Install need to be done using a same PC of Laptop. If you
            have registered to one computer you are not allowed to another device.</p>

        </div>

      </div>

    </div>


  </div>

</div>



<p-dialog header="How to Download and Install the e-Study text system" [(visible)]="displayBasic" [style]="{width: '60vw'}" [baseZIndex]="10000">

  <form #loginForm="ngForm" class="form-inline mt-2 mt-md-0" autocomplete="off">

    <div>
      <li>
        Step 1	:	After arranging the payment successfully select the “Download” option in the menu 
		    ගෙවීම සාර්ථකව සැකසීමෙන් පසු මෙනුවේ “Download” විකල්පය තෝරන්න

      </li>

      <br>

      <img src="assets/mnudisp.png" alt="Menu" width="800" height="60">

      <br>
      <br>

      <li>
        Step 2	:	Select the “Download” button given below and download the application
    		පහත දී ඇති " Download " බොත්තම තෝරා අයදුම්පත බාගත කරගන්න

      </li>

      <br>

      <img src="assets/DownloadsButtons.png" alt="Menu" width="300" height="120">

      <br>
      <br>

      <li>
        Step 3	:	After download you will see icon in the Download folder 
      </li>
      <br>

      <img src="assets/zipfile.png" alt="Menu" width="240" height="110">

      <br>
      <br>

      <li>
        Step 4	:	Open the folder and double click the “setup” Application to install the software
      </li>
      <br>

      <img src="assets/setupfile.png" alt="Menu" width="360" height="180">

      <br>
      <br>

      <li>
        Step 5	:	After successfully installed you will see below application icon in the desktop. 
        Double click the icon to open your e-Study text application and you will see the books you purchased. 
        The Internet requires you to verify your username and password to activate the books you purchased 
        the first time you open this application.   
      </li>
      <br>

      <img src="assets/icon.png" alt="Menu" width="120" height="120">
      




    </div>
     
  </form>

</p-dialog>