import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StudentProfileUtilityService } from '../_services/student-profile-utility.service';

@Component({
  selector: 'app-resend-user-activation-email',
  templateUrl: './resend-user-activation-email.component.html',
  styleUrls: ['./resend-user-activation-email.component.css']
})
export class ResendUserActivationEmailComponent implements OnInit {

  emailAddress: string;
  resetStatus:boolean ;

  constructor(private toastr: ToastrService, private stuUtility: StudentProfileUtilityService) { }

  ngOnInit(): void {
  }

  resetPassword() {
    this.stuUtility.ResendEmailActivation(this.emailAddress).subscribe(response => {
      this.resetStatus = response;
    })
}

}
