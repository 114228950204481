import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-exam-application-help',
  templateUrl: './exam-application-help.component.html',
  styleUrls: ['./exam-application-help.component.css']
})
export class ExamApplicationHelpComponent implements OnInit {


  displayConfirm:boolean;
  items: MenuItem[];
  activeItem: MenuItem;

  constructor() { }

  ngOnInit(): void {
    
    this.items = [
      {label: 'Home', icon: 'pi pi-fw pi-home',routerLink:   ['/help']},
      {label: 'How to Apply Exam', icon: 'pi pi-fw pi-home',routerLink:   ['/ExamHelp']},
      {label: 'eAdmission', icon: 'pi pi-fw pi-calendar',routerLink:   ['/EAdmissionHelp']}
  ];

  }





}
