import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { LogUsers } from '../_models/user';
import { AccountService } from '../_services/account.service';
import { ExamApplicationsService } from '../_services/exam-applications.service';

@Component({
  selector: 'app-exam-app-payment',
  templateUrl: './exam-app-payment.component.html',
  styleUrls: ['./exam-app-payment.component.css']
})
export class ExamAppPaymentComponent implements OnInit {

  user: LogUsers;
  creditValue: number;
  totalPaymentValue: number;
  netPaymentValue: number;
  disabledValue: boolean;
  invoiceNumber :number;

  


  constructor(private accountService: AccountService, private examService: ExamApplicationsService,private toastr: ToastrService, private dialog: MatDialog,private router: Router,@Inject(DOCUMENT) private document: Document) {
    this.accountService.currentUser$.pipe(take(1)).subscribe(user => this.user = user);
    this.getUserDetails();
  }

  ngOnInit(): void {

    this.getUserDetails();

  }

  getUserDetails() {
   
    this.examService.GetExamPaidAmounts().subscribe(response => {
      this.creditValue = response.creditCharge;
      this.totalPaymentValue = response.examFees;
      this.netPaymentValue = response.netPayAmount;
      this.invoiceNumber = 54;
    }
    );

  }

  checkManualPayments(){
    this.router.navigateByUrl('ManualPaymentUpdate');
  }

  checkOnlinePayments(){

    this.examService.checkExamAppliedDetails().subscribe(response=>{

      let invNumber :String

      invNumber = '22'+this.user.userId;

      this.document.location.href = 'https://www.aatsl.lk/index.php/en/exam-payment?invno='+invNumber;
    })

   
  }

}
