import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { userActiveStatus } from '../_models/activeStatus';
import { ActiveStudentDetails } from '../_models/ActiveStudentDetails';
import { AccountService } from '../_services/account.service';

@Component({
  selector: 'app-user-active',
  templateUrl: './user-active.component.html',
  styleUrls: ['./user-active.component.css']
})
export class UserActiveComponent implements OnInit {

  userActiveCode : string;
  useractivestatus : ActiveStudentDetails = undefined;

  constructor(private route : ActivatedRoute, private accountService:AccountService) { }

  ngOnInit(): void {

    this.activeUserAccount();

  }


  returnParameter():string{
    if(this.route.snapshot.paramMap.get('regNumber') == undefined || null)
    {
      return "1";
    }
    else
    {
      return String(this.route.snapshot.paramMap.get('regNumber'));
    }
  }

  activeUserAccount(){
    this.accountService.activeUserLogin(this.returnParameter()).subscribe(activeStatus=>{
      this.useractivestatus =  activeStatus;    
      
    })
  }

}
