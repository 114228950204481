import { Component, OnInit, NgZone,ViewChild, Inject} from '@angular/core';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SalesitemsService } from 'src/app/_services/salesitems.service';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-member-review',
  templateUrl: './member-review.component.html',
  styleUrls: ['./member-review.component.css']
})
export class MemberReviewComponent implements OnInit {

  model: any = {};

  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private _ngZone: NgZone, public dialogRef:MatDialogRef<MemberReviewComponent>, 
  private itemservice:SalesitemsService,private toastr:ToastrService ) {
    
   

   }

   dialogTitle: string;
   usernamevar : string;
   itemcodevar :number;
   
   @ViewChild('autosize') autosize: CdkTextareaAutosize;

   triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  updateReview(){
     
   
    this.itemservice.updateUserReview(this.model).subscribe(response=>{
      console.log(response);
      this.toastr.success("Your Review Posted");  
      this.onclose();
    },error=>{
      console.log(error);
      this.toastr.error(error.error);      
    }

    )
  }


  ngOnInit(): void {  
    
    this.dialogTitle = this.data.dataKey;
    this.usernamevar =  this.data.usernamekey;
    this.itemcodevar = this.data.itemcode;


    this.model.itemCode = this.itemcodevar;
    this.model.userName= this.usernamevar;
    this.model.userReview = this.dialogTitle;
  }

  onclose():void{
    
    this.dialogRef.close();
  }

}
