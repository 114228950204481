import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OutInvoice } from '../_models/outinvoice';
import { OutInvoiceItems } from '../_models/outInvoiceItems';
import { PurchaseRequest } from '../_models/purchaseRequest';
import { SalesInvoiceService } from '../_services/sales-invoice.service';

@Component({
  selector: 'app-outinv-disp',
  templateUrl: './outinv-disp.component.html',
  styleUrls: ['./outinv-disp.component.css']
})
export class OutinvDispComponent implements OnInit {


  invOutList: OutInvoice[] = [];
  purchaseRequest: PurchaseRequest;
  displayBasic: boolean;
  outinvItms : OutInvoiceItems[] = [];

  constructor(private invService: SalesInvoiceService, private toastr: ToastrService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.loadOutInvoices();
  }


  loadOutInvoices() {
    this.invService.GetOutstandingInvoice().subscribe(outInvoice => {
      this.invOutList = outInvoice;
    })
  }


  loadOutInvoicesItems(invNumber:number) {
    this.displayBasic = true;
    this.invService.GetOutInvoiceItems(invNumber).subscribe(outInvoiceDetails => {
      this.outinvItms = outInvoiceDetails;
      
    })
  }


  deleteInvoices(invNumber: number) {
    this.invService.DeleteOutInvoiceItems(invNumber).subscribe(deletestatus => {

      if (deletestatus) {
        this.loadOutInvoices();
        this.toastr.success("Selected Invoice Removed");
      }
    })
  }

  confirmPayment(invNumber: number) {

    this.document.location.href = 'https://aatsl.lk/index.php/en/shopping-cart-payment?refno=' + invNumber;

  }

 

}
