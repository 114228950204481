<div class="container" style="margin-top: 10px;">

    <div class="row">
        <div class="col-12">
            <div class="alert alert-dismissible alert-success ">
                <h1>
                    <strong>Students Exam History View </strong>
                </h1>

                <p style="text-align: justify;">
                    <strong>
                        Dear Student,
                    </strong>
                </p>

                <p style="text-align: justify;">
                    Student Examination History will be available soon, Students can view past exam results, exam attempts, etc.   
                </p>


            </div>
        </div>
    </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
