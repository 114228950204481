<div *ngIf="(accountservive.currentUser$ | async) ">
    <div *ngIf="!registerMode">
        
        <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
            <mdb-carousel-item>
                <div class="view w-100">
                    <img class="d-block w-100" src="assets/newSlideOne.png" alt="First slide">
                    <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
                </div>
                <div class="carousel-caption">
                    <div
                        style="align-items: center; justify-content: center; width: 100%; height: 100%; display: flex; flex-direction: column;">
                        <h1
                            style="color: aliceblue; text-shadow: aquamarine; filter: drop-shadow(0 0 0.75rem rgb(17, 16, 17)); font-weight: bolder;  ">
                            Apply for Examination
                        </h1>
                        
                    </div>
                </div>
            </mdb-carousel-item>
            <mdb-carousel-item>
                <div class="view w-100">
                    <img class="d-block w-100" src="assets/NewSlideTwo.png"
                        alt="Second slide">
                    <div class="mask rgba-black-strong waves-light" mdbWavesEffect></div>
                </div>
                <div class="carousel-caption">
                    <div
                        style="align-items: center; justify-content: center; width: 100%; height: 100%; display: flex; flex-direction: column;">
                        <h1
                            style="color: aliceblue; text-shadow: aquamarine; filter: drop-shadow(0 0 0.75rem rgb(17, 16, 17)); font-weight: bolder;  ">
                            Post Exemption Request
                        </h1>
                      
                    </div>
                </div>
            </mdb-carousel-item>
            <mdb-carousel-item>
                <div class="view w-100">
                    <img class="d-block w-100" src="assets/NewSlideThree.png"
                        alt="Third slide">
                    <div class="mask rgba-black-slight waves-light" mdbWavesEffect></div>
                </div>
                <div class="carousel-caption">
                    <div
                        style="align-items: center; justify-content: center; width: 100%; height: 100%; display: flex; flex-direction: column;">
                        <h1
                            style="color: aliceblue; text-shadow: aquamarine; filter: drop-shadow(0 0 0.75rem rgb(17, 16, 17)); font-weight: bolder;  ">
                            View Your Result
                        </h1>
                     
                    </div>
                </div>
            </mdb-carousel-item>


            <mdb-carousel-item>
                <div class="view w-100">
                    <img class="d-block w-100" src="assets/NewSlideFour.png"
                        alt="Fourth slide">
                    <div class="mask rgba-black-slight waves-light" mdbWavesEffect></div>
                </div>
                <div class="carousel-caption">
                    <div
                        style="align-items: center; justify-content: center; width: 100%; height: 100%; display: flex; flex-direction: column;">
                        <h1
                            style="color: aliceblue; text-shadow: aquamarine; filter: drop-shadow(0 0 0.75rem rgb(17, 16, 17)); font-weight: bolder;  ">
                            Get Your Exam Documents
                        </h1>
                     
                    </div>
                </div>
            </mdb-carousel-item>

        </mdb-carousel>

    </div>

</div>



<div *ngIf="!(accountservive.currentUser$ | async) ">

    <div *ngIf="!registerMode">
        <mdb-carousel class="carousel slide carousel-fade " [animation]="'fade'">
            <mdb-carousel-item>
                <div class="view w-100">
                    <img class="d-block w-100" src="assets/newSlideOne.png" alt="First slide">
                    <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
                </div>
                <div class="carousel-caption ">
                    <div
                        style="align-items: center; justify-content: center; width: 100%; height: 100%; display: flex; flex-direction: column; vertical-align:middle;">
                       
                        <h1
                            style="color: aliceblue; text-shadow: aquamarine; filter: drop-shadow(0 0 0.75rem rgb(17, 16, 17)); font-weight: bolder;  ">
                            Request for Exemptions
                        </h1>
                        <p class="lead fa-1x"
                            style="color: rgb(21, 58, 16); text-shadow: aquamarine; filter: drop-shadow(0 0 0.50rem rgb(17, 247, 5)); font-weight: bolder; size:2em;">
                            Hurry UP, Register Today !
                        </p>

                        <br>

                        <div class="text-center">
                            <p-button label="Sign Up" (click)="registerToggle()" icon="pi pi-users"
                                styleClass="p-button-lg"></p-button>
                        </div>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                       
                    </div>
                </div>
            </mdb-carousel-item>
            <mdb-carousel-item>
                <div class="view w-100">
                    <img class="d-block w-100" src="assets/NewSlideTwo.png"
                        alt="Second slide">
                    <div class="mask rgba-black-strong waves-light" mdbWavesEffect></div>
                </div>
                <div class="carousel-caption">
                    <div
                        style="align-items: center; justify-content: center; width: 100%; height: 100%; display: flex; flex-direction: column;">
                        <h1
                            style="color: aliceblue; text-shadow: aquamarine; filter: drop-shadow(0 0 0.75rem rgb(17, 16, 17)); font-weight: bolder;  ">
                            Apply for Examinations
                        </h1>
                        <p class="lead fa-1x"
                            style="color: rgb(21, 58, 16); text-shadow: aquamarine; filter: drop-shadow(0 0 0.50rem rgb(17, 247, 5)); font-weight: bolder; size:2em;">
                            Hurry UP, Register Today !
                        </p>
                        <br>
                        <div class="text-center">
                            <p-button label="Sign Up" (click)="registerToggle()" icon="pi pi-users"
                                styleClass="p-button-lg"></p-button>
                        </div>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                    </div>
                </div>
            </mdb-carousel-item>
            <mdb-carousel-item>
                <div class="view w-100">
                    <img class="d-block w-100" src="assets/NewSlideThree.png"
                        alt="Third slide">
                    <div class="mask rgba-black-slight waves-light" mdbWavesEffect></div>
                </div>
                <div class="carousel-caption">
                    <div
                        style="align-items: center; justify-content: center; width: 100%; height: 100%; display: flex; flex-direction: column;">
                        <h1
                            style="color: aliceblue; text-shadow: aquamarine; filter: drop-shadow(0 0 0.75rem rgb(17, 16, 17)); font-weight: bolder;  ">
                            Study Online with AAT Online Book Store
                        </h1>
                        <p class="lead fa-1x"
                            style="color: rgb(21, 58, 16); text-shadow: aquamarine; filter: drop-shadow(0 0 0.50rem rgb(17, 247, 5)); font-weight: bolder; size:2em;">
                            Hurry UP, Register Now !
                        </p>
                        <br>
                        <div class="text-center">
                            <p-button label="Sign Up" (click)="registerToggle()" icon="pi pi-users"
                                styleClass="p-button-lg"></p-button>
                        </div>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                    </div>
                </div>
            </mdb-carousel-item>


            <mdb-carousel-item>
                <div class="view w-100">
                    <img class="d-block w-100" src="assets/NewSlideFour.png"
                        alt="Fourth slide">
                    <div class="mask rgba-black-slight waves-light" mdbWavesEffect></div>
                </div>
                <div class="carousel-caption">
                    <div
                        style="align-items: center; justify-content: center; width: 100%; height: 100%; display: flex; flex-direction: column;">
                        <h1
                            style="color: aliceblue; text-shadow: aquamarine; filter: drop-shadow(0 0 0.75rem rgb(17, 16, 17)); font-weight: bolder;  ">
                            Get Your Online Help, Chat with Others
                        </h1>
                        <p class="lead fa-1x"
                            style="color: rgb(21, 58, 16); text-shadow: aquamarine; filter: drop-shadow(0 0 0.50rem rgb(17, 247, 5)); font-weight: bolder; size:2em;">
                            Hurry UP, Register Today !
                        </p>
                        <br>
                        <div class="text-center" style="font-size: larger;">
                            <p-button label="Sign Up" (click)="registerToggle()" icon="pi pi-users"
                                styleClass="p-button-lg"></p-button>
                        </div>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                    </div>
                </div>
            </mdb-carousel-item>

        </mdb-carousel>

    </div>




    <div *ngIf="registerMode" style="align-items: center;" class="container">
        <div class="justify-content-centre">
            <div class="justify-content-centre">
                <app-register (cancelRegister)="cancelRegisterMode($event)"></app-register>
            </div>
        </div>
    </div>

</div>