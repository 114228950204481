<div class="container " *ngIf="renevalStatus && !BlackListedStatus && readStatus ">


    <div class="jumbotron">
        <div class="row ">
            <div class="col-12">
                <P class="card-header fa-2x text-center font-weight-bold  alert alert-dismissible alert-primary"
                    style="margin-top: 20px;">
                    Exemption Application Request
                </P>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header" style="background-color: rgba(221, 230, 231, 0.952);">
            <div class="row">
                <div class="form-group col-6">
                    <h1>
                        <strong>
                            Level 1 - LE1
                        </strong>
                    </h1>
                </div>


                <div class="form-group col-6" style="text-align: right;">
                    <h1>
                        <label style="color:coral; font-weight: bolder;" *ngIf="SubjectsSelectedOne.length > 0">
                            Selected Subjects : {{SubjectsSelectedOne ? SubjectsSelectedOne.length : 0 }}
                        </label>
                    </h1>

                </div>



            </div>

            <div class="row" *ngIf="SubjectsSelectedOne.length > 0">
                <div class="form-group col-12">
                    <div class="text-left">
                        <label style="color:coral; font-weight: bolder;"
                            *ngIf="StageOneQualificationSelect != undefined">
                            Please Select Qualification for Applied Subjects : {{StageOneQualificationSelect}}
                        </label>
                    </div>
                </div>

            </div>

        </div>

        <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);">

            <div class="row">
                <div class="form-group col-12">
                    <p-table [value]="stageOneSubjects" dataKey="lineNumber" responsiveLayout="scroll"
                        [(selection)]="SubjectsSelectedOne" [rowHover]="true">

                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center; width: 7%;">SER#</th>
                                <th style="text-align: center; width: 10%;">Subject Code</th>
                                <th style="text-align: center; width: 25%;">Subject Name</th>
                                <th style="text-align: center; width: 15%;">Status</th>
                                <th style="text-align: center; width: 33%;">Qualification</th>
                                <th style="text-align: center; width: 10%;">Select</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-stageOneSubjects1>
                            <tr>
                                <td style="text-align: center;">{{stageOneSubjects1.lineNumber}}</td>
                                <td style="text-align: center;">{{stageOneSubjects1.subjectCode}}</td>
                                <td style="text-align: left;">{{stageOneSubjects1.subjectName}}</td>
                                <td style="text-align: center;">
                                    <span *ngIf="stageOneSubjects1.completed == 1"
                                        style="background-color: rgb(4, 2, 114); color: gainsboro;"><strong>Completed</strong></span>
                                    <span *ngIf="stageOneSubjects1.completed == 0"
                                        style="background-color: crimson; color: gainsboro;"><strong>Not
                                            Completed
                                        </strong></span>
                                </td>

                                <td style="text-align: left;">
                                    <p-dropdown (onChange)="CalculateExamFees()" [style]="{'minWidth':'100%'}"
                                        [options]="ExemptionQualifications"
                                        [(ngModel)]="stageOneSubjects1.qualificationCode"
                                        [disabled]="stageOneSubjects1.completed == 1" optionLabel="qualificationDesc"
                                        optionValue="qualificationCode"></p-dropdown>
                                </td>

                                <td style="text-align: center;">

                                    <p-tableCheckbox [disabled]="stageOneSubjects1.completed == 1 "
                                        [value]="stageOneSubjects1" (click)="clickMethod(stageOneSubjects1,e.checked)"
                                        #e>
                                    </p-tableCheckbox>

                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">


                        </ng-template>
                    </p-table>
                </div>
            </div>


        </div>
    </div>
    <br>

    <div class="card">
        <div class="card-header" style="background-color: rgba(221, 230, 231, 0.952);">
            <div class="row">
                <div class="form-group col-6">
                    <h1>
                        <strong>
                            Level Two - LE2
                        </strong>
                    </h1>
                </div>


                <div class="form-group col-6" style="text-align: right;">
                    <h1>
                        <label style="color:coral; font-weight: bolder;" *ngIf="SubjectsSelectedTwo.length > 0">
                            Selected Subjects : {{SubjectsSelectedTwo ? SubjectsSelectedTwo.length
                            :
                            0 }}
                        </label>
                    </h1>

                </div>
            </div>

        </div>

        <div class="row" *ngIf="SubjectsSelectedTwo.length > 0">
            <div class="form-group col-12">
                <div class="text-left">
                    <label style="color:coral; font-weight: bolder;" *ngIf="StageTwoQualificationSelect != undefined">
                        Please Select Qualification for Applied Subjects : {{StageTwoQualificationSelect}}
                    </label>
                </div>
            </div>

        </div>

        <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);">

            <div class="row">
                <div class="form-group col-12">
                    <p-table #dt [value]="stageTwoSubjects" dataKey="subjectCode" responsiveLayout="scroll"
                        [(selection)]="SubjectsSelectedTwo" [rowHover]="true">

                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center; width: 7%;">SER#</th>
                                <th style="text-align: center; width: 10%;">Subject Code</th>
                                <th style="text-align: center; width: 25%;">Subject Name</th>
                                <th style="text-align: center; width: 15%;">Status</th>
                                <th style="text-align: center; width: 33%;">Qualification</th>
                                <th style="text-align: center; width: 10%;">Select</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-stageTwoSubjects1>
                            <tr>
                                <td style="text-align: center;">{{stageTwoSubjects1.lineNumber}}</td>
                                <td style="text-align: center;">{{stageTwoSubjects1.subjectCode}}</td>
                                <td style="text-align: left;">{{stageTwoSubjects1.subjectName}}</td>
                                <td style="text-align: center;">
                                    <span *ngIf="stageTwoSubjects1.completed == 1"
                                        style="background-color: rgb(4, 2, 114); color: gainsboro;"><strong>Completed</strong></span>
                                    <span *ngIf="stageTwoSubjects1.completed == 0"
                                        style="background-color: crimson; color: gainsboro;"><strong>Not
                                            Completed</strong></span>
                                </td>

                                <td style="text-align: left;">
                                    <p-dropdown (onChange)="CalculateExamFees()" [style]="{'minWidth':'100%'}"
                                        [options]="ExemptionQualifications"
                                        [(ngModel)]="stageTwoSubjects1.qualificationCode"
                                        [disabled]="stageTwoSubjects1.completed == 1 " optionLabel="qualificationDesc"
                                        optionValue="qualificationCode"></p-dropdown>
                                </td>
                                <td style="text-align: center;">

                                    <p-tableCheckbox [disabled]="stageTwoSubjects1.completed == 1 "
                                        [value]="stageTwoSubjects1" (click)="clickMethod(stageTwoSubjects1,e.checked)"
                                        #e>
                                    </p-tableCheckbox>


                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">


                        </ng-template>
                    </p-table>
                </div>
            </div>

        </div>
    </div>
    <br>

    <div class="card">
        <div class="card-header" style="background-color: rgba(221, 230, 231, 0.952);">
            <div class="row">
                <div class="form-group col-6">
                    <h1>
                        <strong>
                            Level Three - LE3
                        </strong>
                    </h1>
                </div>


                <div class="form-group col-6" style="text-align: right;">
                    <h1 style="color:coral; font-weight: bolder;">
                        <label style="color:coral; font-weight: bolder;" *ngIf="SubjectsSelectedThree.length > 0">
                            Selected Subjects : {{SubjectsSelectedThree ? SubjectsSelectedThree.length
                            :
                            0 }}
                        </label>
                    </h1>

                </div>
            </div>
        </div>

        <div class="row" *ngIf="SubjectsSelectedThree.length > 0">
            <div class="form-group col-12">
                <div class="text-left">
                    <label style="color:coral; font-weight: bolder;" *ngIf="StageThreeQualificationSelect != undefined">
                        Please Select Qualification for Applied Subjects : {{StageThreeQualificationSelect}}
                    </label>
                </div>
            </div>

        </div>

        <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);">

            <div class="row">
                <div class="form-group col-12">
                    <p-table #dt [value]="stageThreeSubjects" dataKey="subjectCode" responsiveLayout="scroll"
                        [(selection)]="SubjectsSelectedThree" [rowHover]="true">

                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center; width: 7%;">SER#</th>
                                <th style="text-align: center; width: 10%;">Subject Code</th>
                                <th style="text-align: center; width: 25%;">Subject Name</th>
                                <th style="text-align: center; width: 15%;">Status</th>
                                <th style="text-align: center; width: 33%;">Qualification</th>
                                <th style="text-align: center; width: 10%;">Select</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-stageThreeSubjects1>
                            <tr>
                                <td style="text-align: center;">{{stageThreeSubjects1.lineNumber}}</td>
                                <td style="text-align: center;">{{stageThreeSubjects1.subjectCode}}</td>
                                <td style="text-align: left;">{{stageThreeSubjects1.subjectName}}</td>
                                <td style="text-align: center;">
                                    <span *ngIf="stageThreeSubjects1.completed == 1"
                                        style="background-color: rgb(4, 2, 114); color: gainsboro;"><strong>Completed</strong></span>
                                    <span *ngIf="stageThreeSubjects1.completed == 0"
                                        style="background-color: crimson; color: gainsboro;"><strong>Not
                                            Completed</strong></span>
                                </td>

                                <td style="text-align: left;">
                                    <p-dropdown (onChange)="CalculateExamFees()" [style]="{'minWidth':'100%'}"
                                        [options]="ExemptionQualifications"
                                        [(ngModel)]="stageThreeSubjects1.qualificationCode"
                                        [disabled]="stageThreeSubjects1.completed == 1 " optionLabel="qualificationDesc"
                                        optionValue="qualificationCode"></p-dropdown>
                                </td>

                                <td style="text-align: center;">
                                    <p-tableCheckbox [disabled]="stageThreeSubjects1.completed == 1 "
                                        [value]="stageThreeSubjects1"
                                        (click)="clickMethod(stageThreeSubjects1,e.checked)" #e>
                                    </p-tableCheckbox>
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="summary">



                        </ng-template>

                    </p-table>
                </div>
            </div>
        </div>
    </div>
    <br>

    <div class="card">
        <div class="card-header" style="background-color: rgba(221, 230, 231, 0.952);">
            <div class="row">
                <div class="form-group col-6">
                    <h1>
                        <strong>
                            Talent Subject (TS)
                        </strong>
                    </h1>
                </div>


                <div class="form-group col-6" style="text-align: right;">
                    <h1>
                        <label style="color:coral; font-weight: bolder;" *ngIf="SubjectsSelectedSKI.length > 0">
                            Selected Subjects : {{SubjectsSelectedSKI ? SubjectsSelectedSKI.length
                            :
                            0 }}
                        </label>
                    </h1>

                </div>
            </div>
        </div>

        <div class="row" *ngIf="SubjectsSelectedSKI.length > 0">
            <div class="form-group col-12">
                <div class="text-left">
                    <label style="color:coral; font-weight: bolder;" *ngIf="StageSKIQualificationSelect != undefined">
                        Please Select Qualification for Applied Subjects : {{StageSKIQualificationSelect}}
                    </label>
                </div>
            </div>

        </div>

        <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);">

            <div class="row">
                <div class="form-group col-12">
                    <p-table #dt [value]="stageSKISubjects" dataKey="subjectCode" responsiveLayout="scroll"
                        [(selection)]="SubjectsSelectedSKI" [rowHover]="true">

                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center; width: 7%;">SER#</th>
                                <th style="text-align: center; width: 10%;">Subject Code</th>
                                <th style="text-align: center; width: 25%;">Subject Name</th>
                                <th style="text-align: center; width: 15%;">Status</th>
                                <th style="text-align: center; width: 33%;">Qualification</th>
                                <th style="text-align: center; width: 10%;">Select</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-stageSKISubjects1>
                            <tr>
                                <td style="text-align: center;">{{stageSKISubjects1.lineNumber}}</td>
                                <td style="text-align: center;">{{stageSKISubjects1.subjectCode}}</td>
                                <td style="text-align: left;">{{stageSKISubjects1.subjectName}}</td>
                                <td style="text-align: center;">
                                    <span *ngIf="stageSKISubjects1.completed == 1"
                                        style="background-color: chartreuse; color: gainsboro;"><strong>Completed</strong></span>
                                    <span *ngIf="stageSKISubjects1.completed == 0"
                                        style="background-color: crimson; color: gainsboro;"><strong>Not
                                            Completed</strong></span>
                                </td>

                                <td style="text-align: left;">
                                    <p-dropdown (onChange)="CalculateExamFees()" [style]="{'minWidth':'100%'}"
                                        [options]="ExemptionQualifications"
                                        [(ngModel)]="stageSKISubjects1.qualificationCode"
                                        [disabled]="stageSKISubjects1.completed == 1 " optionLabel="qualificationDesc"
                                        optionValue="qualificationCode"></p-dropdown>
                                </td>
                                <td style="text-align: center;">
                                    <p-tableCheckbox [disabled]="stageSKISubjects1.completed == 1 "
                                        [value]="stageSKISubjects1" (click)="clickMethod(stageSKISubjects1,e.checked)"
                                        #e>
                                    </p-tableCheckbox>
                                </td>
                            </tr>
                        </ng-template>

                    </p-table>
                </div>
            </div>
        </div>
    </div>

    <hr>
    <br>

    <div class="row">
        <div class="form-group col-4">
            <div class="text-left">
                <label style="color:coral; font-weight: bolder;">
                    Total Exam Fees Applied : {{ CalculateExamFees() | number:'1.0-0'}}
                </label>
            </div>
        </div>

    </div>

    <div class="row">
        <div class="form-group col-4">
            <div class="text-left">

                <button pButton icon="pi pi-check" label="Confirm" (click)="checkAppliedStatus()"></button>


            </div>
        </div>

    </div>




</div>

<br>



<div class="container  " *ngIf="!renevalStatus && readStatus" style="margin-top: 10px;">
    <div class="row alert alert-dismissible alert-danger">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <div class="col-12" style="text-align: center;">
            <h1>
                <strong>
                    You are not allowed to submit a subject exemption request !
                </strong>
            </h1>
        </div>

        <div class="col-12" style="text-align: center;">
            <p>
                You have not renewed your Studentship for the current examination Year, Due to that, you are not allowed
                to apply for the examination. Please renew your AAT Studentship for the Current Year.
            </p>
        </div>



    </div>
</div>


<div class="container  " *ngIf="!readStatus" style="margin-top: 10px;">
    <div class="row alert alert-dismissible alert-danger">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <div class="col-12" style="text-align: center;">
            <h1>
                <strong>
                    Student's Notice
                </strong>
            </h1>
        </div>
    </div>

    <div class="col-12" style="text-align: center;">
        <p>
            Registration Division Please Provide your Notice here ,
        </p>
        <p-checkbox name="groupname" value="val1" label="I here by agree above " [(ngModel)]="selectedValues">
        </p-checkbox>
    </div>

    <div class="row">
        <div class="form-group col-4">
            <div class="text-left">

                <button pButton icon="pi pi-check" (click)="UpdateReadStatus()" label="Confirm"></button>


            </div>
        </div>

    </div>



</div>



<div class="container  " *ngIf="BlackListedStatus && readStatus" style="margin-top: 10px;">
    <div class="row alert alert-dismissible alert-danger">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <div class="col-12" style="text-align: center;">
            <h1>
                <strong>
                    You are not allowed to apply for the Examination !
                </strong>
            </h1>
        </div>

        <div class="col-12" style="text-align: center;">
            <p>
                Your Studentship has been suspended, if you need further clarification please contact AAT Sri Lanka
                Registration Division.
            </p>
        </div>



    </div>
</div>



<p-dialog header='Confirmation' [(visible)]="DisplayConfirm" [modal]="true" [style]="{width: '25vw'}"
    [breakpoints]="{'960px': '75vw', '320px': '50vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">

    <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);">

        <div class="row">
            <div class="col-12">
                <Label style="color:coral; font-weight: bolder;">
                    Are You Sure want Add This Subject, {{SelectedSubjectName}} It may not grant since you have not
                    completed Stage One and Two Subjects ?
                </Label>
            </div>

        </div>

    </div>

    <ng-template pTemplate="footer">

        <div class="row">
            <div class="col-9">

            </div>
            <div class="col-3">
                <p-button icon="pi pi-check" inputmode="submit" (click)="UpdateConfirmation()" label="OK"
                    class="p-button-text">
                </p-button>
            </div>


        </div>
    </ng-template>

</p-dialog>




<p-dialog header='Validation' [(visible)]="ValidateVerification" [modal]="true" [style]="{width: '25vw'}"
    [breakpoints]="{'960px': '75vw', '320px': '50vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">

    <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);">

        <div class="row">
            <div class="col-12">
                <Label style="color:coral; font-weight: bolder;">
                   {{ValidationError}}

                </Label>
            </div>

        </div>

    </div>

    <ng-template pTemplate="footer">

        <div class="row">
            <div class="col-9">

            </div>
            <div class="col-3">
                <p-button icon="pi pi-check" inputmode="submit" (click)="validationConfirmationCheck()" label="OK"
                    class="p-button-text">
                </p-button>
            </div>


        </div>
    </ng-template>

</p-dialog>


<p-dialog header='Save Confirmation' [(visible)]="SaveConfirmation" [modal]="true" [style]="{width: '25vw'}"
    [breakpoints]="{'960px': '75vw', '320px': '50vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">

    <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);">

        <div class="row">
            <div class="col-12">
                <Label style="color:coral; font-weight: bolder;">
                    Please Confirm, Are you sure want to Proceed with Selected Subject. If press yes you will proceed to
                    Qualification Certificate Upload Screen.

                </Label>
            </div>

        </div>

    </div>

    <ng-template pTemplate="footer">

        <div class="row">
            <div class="col-6">

            </div>
            <div class="col-3">
                <p-button icon="pi pi-check" inputmode="submit" (click)="proceedtoSaveFiles()" label="YES"
                    class="p-button-text">
                </p-button>

                
            </div>

            <div class="col-3">
                <p-button icon="pi pi-check" inputmode="submit" (click)="proceedtoSaveFiles()" label="NO"
                    class="p-button-text">
                </p-button>

                
            </div>


        </div>
    </ng-template>

</p-dialog>





<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>