import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {NgxGalleryModule} from '@kolkov/ngx-gallery';
import {MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MatInputModule} from '@angular/material/input';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import {ToastModule} from 'primeng/toast';
import { MDBBootstrapModule, WavesModule,NavbarModule } from 'angular-bootstrap-md';
import {CardModule} from 'primeng/card';
import {DividerModule} from 'primeng/divider';





@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot({
      positionClass:'toast-bottom-right'
    }),
    TabsModule.forRoot(),
    NgxGalleryModule,
    MatDialogModule,
    MatDividerModule,
    MatListModule,
    TextFieldModule,
    MatInputModule,
    CarouselModule.forRoot(),
    ToastModule,
    CarouselModule.forRoot(),
    WavesModule.forRoot(),
    MDBBootstrapModule.forRoot(),

    
    
  ],

  exports:[
    BsDropdownModule,
    ToastrModule,
    TabsModule,
    NgxGalleryModule,
    MatDialogModule,
    MatToolbarModule,
    MatDividerModule,
    MatListModule,
    TextFieldModule,
    MatInputModule,
    PaginationModule,
    CarouselModule,
    ToastModule,
    CardModule,
    DividerModule,
    NavbarModule
    

  ]


})
export class SharedModule { }
