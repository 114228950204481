<div class="container">
    <div class="mainCover" style="margin-top: 100px;">

        <mat-card class="boxModel">
            <h1 class="headerfont" style="text-align: center;">
                <strong>
                    To resend User Activation Email

                </strong>
            </h1>

            <br>

            <p>If you have registered in the Student Portal and have not received The Account Activation Email, please
                use the below button to send the Activation email again. You will receive an email with a link to
                activate the user. If you have forgotten the user email address or if you feel you have entered an
                incorrect email address when you are registering, please contact AAT Examination Division to reset the
                user email address. </p>

            <br>
            <p>ඔබ ශිෂ්‍ය ද්වාරයෙහි ලියාපදිංචි වී ගිණුම් සක්‍රිය කිරීමේ විද්‍යුත් තැපෑල නොලැබුනේ නම්, කරුණාකර සක්‍රිය
                කිරීමේ විද්‍යුත් තැපෑල නැවත යැවීමට පහත බොත්තම භාවිතා කරන්න. පරිශීලකයා සක්‍රිය කිරීමට සබැඳියක් සහිත
                විද්‍යුත් තැපෑලක් ඔබට ලැබෙනු ඇත. ඔබට පරිශීලක විද්‍යුත් තැපැල් ලිපිනය අමතක වී ඇත්නම් හෝ ඔබ ලියාපදිංචි වන
                විට වැරදි විද්‍යුත් තැපැල් ලිපිනයක් ඇතුළත් කර ඇති බව ඔබට හැඟේ නම්, කරුණාකර පරිශීලක විද්‍යුත් තැපැල්
                ලිපිනය නැවත සැකසීමට AAT විභාග අංශය අමතන්න. </p>

            <br>
            <p>நீங்கள் மாணவர் போர்ட்டலில் பதிவு செய்து கணக்கு செயல்படுத்தும் மின்னஞ்சலைப் பெறவில்லை என்றால்,
                செயல்படுத்தும் மின்னஞ்சலை மீண்டும் அனுப்ப கீழே உள்ள பொத்தானைப் பயன்படுத்தவும். பயனரைச் செயல்படுத்த
                இணைப்புடன் கூடிய மின்னஞ்சலைப் பெறுவீர்கள். நீங்கள் பயனர் மின்னஞ்சல் முகவரியை மறந்துவிட்டால் அல்லது
                நீங்கள் பதிவு செய்யும் போது தவறான மின்னஞ்சல் முகவரியை உள்ளிட்டதாக உணர்ந்தால், பயனர் மின்னஞ்சல் முகவரியை
                மீட்டமைக்க AAT தேர்வுப் பிரிவைத் தொடர்பு கொள்ளவும். </p>

            <br>

            <form #resetForm="ngForm" (ngSubmit)="resetPassword()" autocomplete="off">
                <div class="row">

                    <div class="col-10" style="text-align: right; width: 100%;">
                        <input name="emailAddress" required [(ngModel)]="emailAddress" class="form-control mr-sm-2"
                            type="text" placeholder="Email Address" style="width: 100%;">
                    </div>


                    <div class="col-2">
                        <button [disabled]="!resetForm.valid" class="btn btn-primary" type="submit">Resend Email </button>
                    </div>
                </div>

                <div *ngIf="resetStatus == true">
                    <strong>
                        <p>Activation Email dispatched to Your Registered Email Address, Please Check your Email. </p>
                    </strong>
                </div>
            </form>

            <br>
            <br>
            <br>


        </mat-card>


    </div>


    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>

</div>