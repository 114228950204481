<div class="container ">
    

        <div class="jumbotron">

            <div class="row ">
                <div class="col-12">
                    <P class="card-header fa-2x text-center font-weight-bold  alert alert-dismissible alert-primary"
                        style="margin-top: 20px;">
                        Exam Application Payment
                    </P>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-8" style="text-align: right;">
                    <label style="color:coral; font-weight: bolder;">Total Exam Fees :</label>

                </div>
                <div class="form-group col-4" style="text-align: right;">
                    <label style="color:rgb(24, 22, 22); font-weight: bolder;">{{totalPaymentValue| number :
                        '1.2-2'}}</label>

                </div>


            </div>

            <div class="row">
                <div class="form-group col-8" style="text-align: right;">
                    <label style="color:coral; font-weight: bolder;">Credit Amount :</label>

                </div>
                <div class="form-group col-4" style="text-align: right;">
                    <label style="color:rgb(24, 22, 22); font-weight: bolder;">{{creditValue| number : '1.2-2'}}</label>

                </div>


            </div>

            <div class="row">
                <div class="form-group col-8" style="text-align: right;">
                    <label style="color:coral; font-weight: bolder;">Net Payment Amount :</label>

                </div>
                <div class="form-group col-4" style="text-align: right;">
                    <label style="color:rgb(24, 22, 22); font-weight: bolder;">{{netPaymentValue| number :
                        '1.2-2'}}</label>

                </div>


            </div>

            <hr>
            <div class="row">

                <div class="alert alert-dismissible alert-danger form-group col-12">
                    <strong>Notice</strong>
                    <p style="text-align: justify;">
                        Please Check the applied city and applied Subjects, check your exam fees too. Once you confirm
                        you are not allowed to change applied subjects. Verify before confirming exam Application. Once
                        Confirm you will be directed to payment. If you want to furthermore change please press “No”.
                    </p>

                </div>
            </div>


            <hr>

            <div class="row">
                <div class=" col-6" style="text-align: right; width: 100%;">
                    <button inputmode="submit" pButton icon="pi pi-mobile" label="On-Line Payments" (click)="checkOnlinePayments()" style="width: 100%;"></button>

                </div>

                

                <div class=" col-6" style="text-align: right; width: 100%;">
                    <button inputmode="submit" pButton icon="pi pi-book" label="Offline Bank Payments" (click)="checkManualPayments()"  style="width: 100%;"></button>                   

                </div>


            </div>


        </div>
  

</div>






<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>