import { Component, OnInit } from '@angular/core';
import { StudentExamApplicationSubjects } from '../_models/StudentExamApplicationSubjects';
import { StudentExamApplication } from '../_models/StudentsExamApplication';
import { StudentsExamApplicationPayments } from '../_models/StudentsExamApplicationPayments';
import { ExamApplicationsService } from '../_services/exam-applications.service';


@Component({
  selector: 'app-manual-payment-confirmation',
  templateUrl: './manual-payment-confirmation.component.html',
  styleUrls: ['./manual-payment-confirmation.component.css']
})
export class ManualPaymentConfirmationComponent implements OnInit {


  constructor(private examService: ExamApplicationsService) { }

  appliedDetails: StudentExamApplication;
  studentsApplicationSubjects: StudentExamApplicationSubjects[];
  studentsPayments: StudentsExamApplicationPayments[];
  totalSubjectCount: number;
  totalPaymentLines: number;
  totalPaidAmount: number;


  ngOnInit(): void {

    this.getExamAppliedDetails();

  }

  getExamAppliedDetails() {

    this.examService.checkExamAppliedDetails().subscribe(details => {

      this.appliedDetails = details;
      this.studentsApplicationSubjects = this.appliedDetails.studentsAppliedExamApplicationSubjectsDto;
      this.studentsPayments = this.appliedDetails.studentsAppliedExamApplicationPaymentsDto;
      this.totalSubjectCount = this.studentsApplicationSubjects.length;
      this.totalPaymentLines = this.studentsPayments.length;
      this.getTotalPaidAmount();
      //this.totalPaidAmount = this.studentsPayments.values

    });
  }


  getTotalPaidAmount() {

    let p : number = 0;

    for (let item of this.studentsPayments) {
      p = p + item.paidAmount;
    }

    this.totalPaidAmount = p;
  }



}
