<div class="form-group">
    <label style="color:coral; font-weight: bolder;">{{label}}</label>
    <input [class.is-invalid]="ngControl.touched && ngControl.invalid " type={{type}} class="form-control"
        [formControl]="ngControl.control" [readOnly]="childmessage" placeholder={{label}}>
    <div *ngIf="ngControl.control?.errors?.required" class="invalid-feedback"> {{displayMessage}}</div>
    <div *ngIf="ngControl.control?.errors?.minlength" class="invalid-feedback">
        {{displayMessage}}</div>
    <div *ngIf="ngControl.control?.errors?.maxlength" class="invalid-feedback">
        {{displayMessage}} /div>
    </div>
    <div *ngIf="ngControl.control?.errors?.isMatching" class="invalid-feedback">
        Password Not Match / මුරපද සමාන විය යුතුය / கடவுச்சொற்கள் ஒரே மாதிரியாக இருக்க வேண்டும்
    </div>

    <div *ngIf="ngControl.control?.errors?.pattern" class="invalid-feedback">
        Please Enter a Valid Mobile Number, It should be 10 Digits / කරුණාකර වලංගු ජංගම දුරකථන අංකයක් ඇතුළත් කරන්න, එය අංක 10 ක් විය යුතුය / செல்லுபடியாகும் மொபைல் எண்ணை உள்ளிடவும், இது எண் 10 ஆக இருக்க வேண்டும்
    </div>

    <div *ngIf="ngControl.control?.errors?.email" class="invalid-feedback">
        Please Enter a Valid Email Address / කරුණාකර වලංගු ඊතැපැල් ලිපිනයක් ඇතුළු කරන්න / செல்லுபடியாகும் மின்னஞ்சல் முகவரியை உள்ளிடவும
    </div>