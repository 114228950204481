<nav class="navbar fixed-top  backGround" style="background-color: light-blue darken-2;">

    <div class="row" style="width: 100%;">
        <div class="col-1" style="">
            <img src="https://www.aatsl.lk/templates/assets/images/aat_logo.png"  alt="Responsive image" class="imageSize" >
        </div>
        <div class="col-4">2</div>
        <div class="col-4">3</div>
        <div class="col-3">4</div>
      </div>

    
      

  </nav>