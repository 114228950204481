<div class="container" style="margin-top:50px">
    <p-card header="The following Steps will assist Students to create Student's Profile " subheader=""
        [style]="{width: '100%'}" styleClass="p-card-shadow">
        <ng-template pTemplate="header">
            <P class="card-header fa-2x text-center font-weight-bold  alert alert-dismissible alert-primary">
                Help
            </P>

        </ng-template>
        <p-divider></p-divider>

        <h5>
            <strong>
                පියවර 01 / படிமுறை 1 / Step 1 - ඔබගේ ඒඒටී ශිෂ්‍ය ගිණුම සෑදීම / உங்கள் விபரக்கோவையை உருவாக்குதல் /
                Creating your AAT Student profile
            </strong>

        </h5>
        <p>
            <li>
                ඒඒටී වෙබ් අඩවියේ මැද ඇති [Sign-up] බොත්තම සොයා ගන්න. ඊළඟට "Start Register" යන්න ක්ලික් කර ශිෂ්‍ය ගිණුම්
                වෙබ් අඩවියට පිවිසෙන්න. මේ සඳහා ඔබට ඒඒටී හි වලංගු ශිෂ්‍ය ලියාපදිංචි අංකයක් තිබිය යුතුය / திரையில்
                நடுப்பகுதியில் உள்ள “பதிவுசெய்தல்” (Sign up) சுட்டியை தெரிவுசெய்து மாணவர் விபரக்கோவைக்கான பதிவுசெய்தலை
                ஆரம்பியுங்கள். இதற்காக உங்களுக்கு AAT Srilanka நிறுவனத்தால் வழங்கப்பட்ட செல்லுபடியாகும் மாணவர் பதிவு
                அடையாள அட்டை தேவைப்படும். / Search and Find the Sign-Up Button on the Center of the Screen, Click to
                Start Register on Student’s
                Profile. You are required to have a valid Student Registration ID at AAT Sri Lanka.
            </li>
            <br>
            <img src="assets/SignUp.bmp" class="img-fluid" alt="Responsive image">
            <br>
            <br>
            <li>
                Sign up බොත්තම එබූ විට ඔබව ශිෂ්‍ය දත්ත ලියාපදිංචි කිරීමේ තිරයකට යොමු කරයි / நீங்கள் ஒருமுறை பதிவுசெய்தல்
                Sign up) சுட்டியை தெரிவுசெய்வதன் மூலம் மாணவர் விபரக்கோவைக்கான பதிவிற்கான பக்கத்திற்கு வழிகாட்டப்படும்.
                / Once you click sign up, you will be directed to Student Profile Registration Page.
            </li>
            <br>
            <li>
                ඔබගේ ශිෂ්‍ය ලියාපදිංචි අංකය [Student Registration ID] යන කොටුවට ඇතුළත් කර, ඊට යටින් ඇති "Search" බොත්තම
                ඔබන්න. / மாணவர் பதிவிலக்கத்திற்கான பெட்டியினை (Student Registration ID) தெரிவுசெய்து உங்கள்
                பதிவிலக்கத்தை உள்ளிடவும். / Type your Student Registration ID on the Appeared Student Reg ID Box and
                Press Search button below to
                the
                Student Reg ID Box.
            </li>
            <br>
            <img src="assets/SearchStudentRegistrationID.jpg" class="img-fluid" alt="Responsive image">
            <br>
            <li>

                ශිෂ්‍යයාගේ නමට යටින් ඇති කොටුවේ ඔබගේ නම දිස්වේ. යම්කිසි හේතුවකින්

                <strong style="color: red;">

                    "ලියාපදිංචි අංකය දැනටමත් ලියාපදිංචි
                    වී ඇත"

                </strong>



                යනුවෙන් පණිවිඩයක් ලැබෙන්නේ නම්, ඒඒටී ශ්‍රී ලංකා
                ආයතනයේ විභාග අංශය අමතන්න. / உங்கள் பெயர் கீழுள்ள பெட்டியில் Student Name என்பதன் கீழ் தோன்றும்.

                <strong style="color: red;">

                    "ஏதாவது ஒரு சமயத்தில் உங்களது பதிவிலக்கம் ஏற்கனவே "

                </strong>

                பதிவுசெய்யப்பட்டுள்ளது என்ற செய்தி வந்தால் தயவு செய்து பரீட்சை பிரிவினை தொடர்பு கொள்ளுங்கள் .

                /



                Your name will appear in the Below box under Student Name, If any case if you get a message saying
                <strong style="color: red;">

                    Registration Number has already been registerd

                </strong>
                , please contact AAT Examination Division
            </li>

            <br>
            <img src="assets/StudentNameAppear.jpg" class="img-fluid" alt="Responsive image">

            <br>


            <li>

                ඔබගේ නම නිවැරදි නම්
                <strong style="color: red;">

                    ඔබට අයත්, අන් අය සමග පොදුවේ භාවිතා නොකරන විද්‍යුත් ලිපිනයක් [e-mail address]
                </strong>

                කොටුවට ඇතුළත් කරන්න. / உங்கள் பெயர் இருந்தால்

                <strong style="color: red;">

                    இருந்தால் உங்களுக்கு சொந்தமான மற்றும் யாரிடத்திலும் பகிராத செல்லுபடியாகும் email முகவரியினை
                </strong>

                உள்ளிடுங்கள் . உங்கள் பரீட்சை தொடர்பான அனைத்து விடயங்களும் நீங்கள் பதிவு செய்த email முகவரிக்கே
                அனுப்பப்படும் . /


                If your name is correct, please enter a
                <strong style="color: red;">

                    valid email address to Email Address box that belongs to you and it shouldn't be a
                    shared one.
                </strong>

                All the information related to your examination will be received
                at your registered email address.
            </li>
            <br>
            <br>
            <li>
                ඔබව සම්බන්ධ කරගත හැකි වලංගු දුරකතන අංකයක් [mobile phone] ඇතුළත් කරන්න. /  சரியான தொலைபேசி இலக்கத்தை
                தொலைபேசி இலக்கம் என்ற பெட்டியில் உள்ளிடுங்கள். AAT நிகழ்வுகள் தொடர்பான அறிவித்தல்கள் நீங்கள் பதிவு செய்த
                தொலைபேசி இலக்கத்துக்கு SMS மூலம் உங்களுக்கு அறிவிக்கப்படும் . /
                Enter a valid Contact Number to Mobile Phone Number box, You will be notified of all the necessary
                events via SMS to the entered number.
            </li>
            <br>
            <br>
            <li>
                ඔබ විද්‍යුත් තැපැල් ලිපිනය හා දුරකතන අංකය ඇතුළත් කළපසු මුරපදයක් ඇතුළත් කරන්න. මෙම මුරපදය අවම වශයෙන්
                අකුරු, සංඥා හා ඉලක්කම් 8 කින් සමන්විත විය යුතු අතර එය ඔබගේ නම නොවිය යුතුය. මුරපදය තහවුරු කර ගැනීම සඳහා
                නැවතත් එයම ඇතුලත් කරන්න. / கடவுச்சொல்
                உங்களது பெயர் அல்லாமல் 8 எழுத்துக்களை உள்ளடக்கியதாக அமைய வேண்டும். கட்டாயமாக எழுத்துக்கள் இலக்கங்கள்
                மற்றும் குறியீடுகளை உள்ளடக்க வேண்டும். அத்துடன் கடவுச்சொல்லை உறுதிசெய்ய மீண்டும் உறுதிசெய்யும்
                பெட்டியில் உள்ளிடவும். உதாரணம் - ABC123@# /
                Once you complete enter Email address and the Mobile Phone Number, Enter a valid password. Password
                should not be consist of your name and need to be at least 8 characters long. It should be included
                letters, numbers, and symbols. And enter the same password to the verify password box.
            </li>
            <br>
            <br>
            <li>
                ඔබ සියලුම තොරතුරු සහ මුරපදය ඇතුළත් කළපසු "Register" යන බොත්තම මත ක්ලික් කර පහත සඳහන් තහවුරු කිරීමේ
                පණිවුඩය ලැබෙන තෙක් මදක් රැදී සිටින්න. / கடவுச்சொல்லை முழுமையாக பூர்த்தி செய்த பின்னர் உங்களுக்கு
                Registration சுட்டி கிடைக்கப்பெறும் , அதனை அழுத்திய பின்னர் உறுதிப்படுத்திய செய்தி கிடைக்கும் வரை
                காத்திருக்கவும். /
                Once you complete all pieces of information and passwords, the Register button will enable you to press.
                Press-Register button and wait until the below verification Message appears.
            </li>
            <br>
            <br>
            <img src="assets/RegistrationSuccess.jpg" class="img-fluid" alt="Responsive image">
            <br>
        </p>

        <p-divider></p-divider>

        <h5>
            <strong>
                පියවර 2 / படி 2 / Step 2 - ඔබගේ විද්‍යුත් ලිපිනය තහවුරු කරන්න / உங்கள் மின்னஞ்சல் முகவரியை
                சரிபார்க்கவும் / Verify Your Email Address
            </strong>

        </h5>

        <p>
            <br>
            <li>

                ඔබගේ තහවුරු කිරීමේ පණිවිඩය ලැබෙන විටම, විද්‍යුත් ලිපිනය තහවුරු කිරීම සඳහා e-mail එකක් ලැබෙනු ඇත. ඔබ
                e-mail ගිණුමට ඇතුළත් වී ලැබී ඇති e-mail එක පරීක්ෂා කර බැලිය යුතු වේ. සමහරවිට එය "Spam" ගොනුව තුළට ගොස්
                තිබීමට හැකි නිසා inbox ගොනුවේ e-mail එක නැත්නම් "Spam" ගොනුව පරීක්ෂා කර බලන්න.
                <strong>ඔබගේ ගිණුම සක්‍රීය කිරීමට
                    පහත දක්වා ඇති පරිදි "Click here to activate" යන බොත්තම ඔබන්න</strong>


                / உறுதிப்படுத்தல் திரையைப் பெறும்போது, அதே நேரத்தில் உங்கள் மின்னஞ்சல் முகவரியைச் சரிபார்க்க
                மின்னஞ்சலைப் பெறுவீர்கள். நீங்கள் பதிவு செய்யப்பட்ட மின்னஞ்சல் கணக்கில் உள்நுழைந்து மின்னஞ்சலைத் தேட
                வேண்டும். சில நேரங்களில் அது ஸ்பேம் கோப்புறைக்குச் சென்றிருக்கலாம், எனவே ஸ்பேம் கோப்புறை உங்கள்
                இன்பாக்ஸில் கிடைக்கவில்லை என்றால் அதைச் சரிபார்க்கவும். " அச்சகம்,
                <strong>கீழே காட்டப்பட்டுள்ளபடி, உங்கள் கணக்கைச் செயல்படுத்த, செயல்படுத்த இங்கே கிளிக்
                    செய்யவும்"</strong>

                /

                When you receive the confirmation screen, same time you will receive an email to verify your email
                address.
                You are required to log in to the registered email account and search email. Sometimes it may have gone
                to
                the spam folder, so please check on the spam folder if it is not available in your inbox. “ Press,
                <strong>Click here to activate” in order to activate your account , as shown below”</strong> “

            </li>

            <br>
            <br>
            <img src="assets/EmailVerification.jpg" class="img-fluid" alt="Responsive image">

            <br>
            <li>
                ඔබ තහවුරු කිරීමේ link එක එබූ විට සාර්ථකව ලියාපදිංචි කර ඇත "successfully registered" යන තීරයකට යොමු වේ.
                සුබ පැතුම්! ඔබ මෙම පද්ධතියේ සාර්ථකව ලියාපදිංචි වී ඇත. ඔබගේ username හා password (මුරපදය) භාවිතා කර
                පද්ධතියට ඇතුල්වන්න. / சரிபார்ப்பு இணைப்பைக் கிளிக் செய்தவுடன், வெற்றிகரமாக பதிவுசெய்யப்பட்ட திரைக்கு
                நீங்கள் அனுப்பப்படுவீர்கள். வாழ்த்துக்கள், நீங்கள் கணினியில் பதிவு செய்துள்ளீர்கள். கணினியில் உள்நுழைய
                உங்கள் பதிவு எண் மற்றும் கடவுச்சொல்லைப் பயன்படுத்தவும். /
                Once you click the verification link you will be directed to the successfully registered screen.
                Congratulations, You are registered in the System. Use your registration number and password to login
                into the system.
            </li>
            <br>
            <br>
            <img src="assets/VerifiedScreen.jpg" class="img-fluid" alt="Responsive image">

            <br>

        </p>

        <p-divider></p-divider>

        <ng-template pTemplate="footer">

        </ng-template>
    </p-card>
</div>