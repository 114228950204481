<div class="container">

    <p-fieldset legend={{useractivestatus.activationStatus}} *ngIf="useractivestatus != undefined">
        <p>Welcome to AATSL Student Portal. Your Account Registration on AAT Student Portal Application is
            completed, You may not receive any activation email furthermore. Please login one time to check your
            credential. If you have forgotten your password please use “Forget password” option to restore your user
            name and password. </p>

        <br>
        <br>
        <p>
            AATSL ශිෂ්‍ය තොරතුරු පද්ධතිය වෙත සාදරයෙන් පිළිගනිමු. ඔබගේ Student Portal හි ලියාපදිංචිය සම්පුර්ණ වී ඇති
            බැවින් තවදුරටත් සක්‍රිය කිරීමේ ඊ මේල් පණිවිඩයක් නොලැබෙනු ඇත.
            ඔබගේ තොරතුරු පරික්ෂා කිරීම සදහා ඔබගේ පරිශීලක නාමය (Username) සහ මුරපදය (Password) භාවිතා කොට එක්වරක් ශිෂ්‍ය
            ගිණුමට Log වන්න.
            ඔබගේ මුරපදය අමතක වී ඇත්නම් කරුණාකර එය ප්‍රතිසාධනය (Recover) කිරීමට “මුරපදය අමතක ද” යන විකල්පය භාවිතා කරන්න.
        </p>

        <br>
        <br>
        <p>AAT இலங்கை மாணவர் தரவு தளத்திற்கு வரவேற்கின்றோம் .
            AAT இணைய மாணவர் தரவு தள விண்ணப்பத்தில் உங்கள் கணக்கு பதிவு நிறைவடைந்தது.மேலும் நீங்கள் எந்த செயற்படுத்தல்
            மின் அஞ்சலும் பெறமாட்டீர்கள்.உங்கள் பதிவு விபரங்களை சரிபார்க்க ஒருமுறை உள்நுழையவும். உங்கள் கடவுச்சொல்லை
            மறந்துவிட்டால் பயன்படுத்துபவர் பெயர் மற்றும் கடவுச்சொல்லை மீள் செயற்படுத்த "Forgot Password " என்ற இணைப்பை
            பயன்படுத்தவும் .</p>


    </p-fieldset>


    <div class="mainCover">

        <mat-card class="boxModel" *ngIf="useractivestatus != undefined">
            <h1 class="headerfont" style="text-align: center;">
                <strong>
                    Activation Details
                </strong>
            </h1>

            <div class="row">
                <div class="col-3">
                    <p>
                        <strong>
                            Student Reg ID :
                        </strong>

                    </p>
                </div>
                <div class="col-9">
                    <p>{{useractivestatus.studentRegID}}</p>
                </div>
                <div class="col-3">
                    <p>
                        <strong>
                            User Full Name :
                        </strong>
                    </p>
                </div>
                <div class="col-9">
                    <p>{{useractivestatus.studentName}}</p>
                </div>
                <div class="col-3">
                    <p>
                        <strong>
                            User Email Address :
                        </strong>
                    </p>
                </div>
                <div class="col-9">
                    <p>{{useractivestatus.emailAddress}}</p>
                </div>
                <div class="col-3">
                    <p>
                        <strong>
                            User Contact Number :
                        </strong>

                    </p>
                </div>
                <div class="col-9">
                    <p>{{useractivestatus.contactNumber}}</p>
                </div>

                <br>
                <br>
                <br>
                <br>


            </div>
            <br>
            <br>
            <button class="btn btn-info btn-lg" routerLink='/'>Return to Home Page</button>
            <br>
            <br>
            <br>
            <br>

        </mat-card>


        <mat-card class="boxModel" *ngIf="useractivestatus == undefined">
            <h1 class="headerfont" style="text-align: center;">Invalid Activation Code </h1>

            <div>
                Please Check your email or Register again
            </div>
            <br>
            <br>
            <button class="btn btn-info btn-lg" routerLink='/'>Return to Home Page</button>
        </mat-card>

    </div>
</div>