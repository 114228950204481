<form [formGroup]='registerForm' (ngSubmit)=" registerForm.valid && register()" autocomplete="off">
   <br>
   <br>
   <div class="alert alert-dismissible alert-primary">
      <h1>
         <strong>Sign UP !</strong>
      </h1>

      <br>
      <p>
         Fill your details for Sign Up (*All the fields are mandatory).
         ලියාපදිංචි වීම සඳහා ඔබේ තොරතුරු පුරවන්න (* සියලුම කොටස් අනිවාර්ය වේ).
         பதிவுபெறுவதற்கு உங்கள் விவரங்களை நிரப்பவும் (* எல்லா புலங்களும் கட்டாயமாகும்).
      </p>
      <br>
   </div>
   <br>
   <div class="alert alert-dismissible alert-danger">
      <h1>
         <strong>Notice</strong>
      </h1>
      <p>
         Please don’t enter the details twice. Don’t forget your Student Registration Number & the password and keep them
         confidential.
         කරුණාකර විස්තර දෙවරක් ඇතුළත් නොකරන්න. ඔබගේ පරිශීලක නාමය සහ මුරපදය අමතක නොකර ඒවා රහසිගතව තබා ගන්න.
         தயவுசெய்து இரண்டு முறை விவரங்களை உள்ளிட வேண்டாம். உங்கள் பயனர் பெயர் மற்றும் கடவுச்சொல்லை மறந்து அவற்றை
         ரகசியமாக வைக்க வேண்டாம்.
      </p>

   </div>
   <hr>

   <br>

   <form #searchStudentForm="ngForm" (ngSubmit)="GetStudentDetails()" autocomplete="off">

      <div>
         <app-text-input [label]='"Student Registration Number"' [formControl]='registerForm.controls["studentRegID"]'
            [displayMessage]='"Please Enter a Valid Student Registration Number "'>
         </app-text-input>
      </div>

      <div>
         <button [disabled]="!searchStudentForm.valid" class="btn btn-primary" type="submit"
            style="width:100%;">Search</button>
      </div>
   </form>

   <hr>

   <div *ngIf="this.StudentDetails != null">
      <form #StudentDetailForm="ngForm" autocomplete="off">       
         <div>
            <app-text-input [(ngModel)]="StudentDetails.fullName +''+ StudentDetails.surname" [label]='"Student Name"'
               name="fullname" [childmessage]="true" [displayMessage]='"Student Name"'></app-text-input>         
         </div>
      </form>
   </div>

   <hr>


   <app-text-input [formControl]='registerForm.controls["emailAddress"]' [label]='"Email Address"'
      [displayMessage]='"Please Enter a Valid Email Address / කරුණාකර වලංගු ඊතැපැල් ලිපිනයක් ඇතුළු කරන්න / செல்லுபடியாகும் மின்னஞ்சல் முகவரியை உள்ளிடவும்"'>
   </app-text-input>


   <app-text-input [formControl]='registerForm.controls["contactNumber"]' [label]='"Mobile Number"'
      [displayMessage]='"Please Enter a Valid Mobile Number, It should be 10 Digits / කරුණාකර වලංගු ජංගම දුරකථන අංකයක් ඇතුළත් කරන්න, එය අංක 10 ක් විය යුතුය / செல்லுபடியாகும் மொபைல் எண்ணை உள்ளிடவும், இது எண் 10 ஆக இருக்க வேண்டும்"'>
   </app-text-input>


   <app-text-input [formControl]='registerForm.controls["userPassword"]' [label]='"Password"' [type]='"password"'
      [displayMessage]='"Passwords cannot contain your username, and need to be at least 8 characters long. It should include letters, numbers, and symbols. Dont forget your password and keep it confidential.
      මුරපදවලට ඔබේ පරිශීලක නාමය අඩංගු විය නොහැකි අතර අවම වශයෙන් අක්ෂර 8 ක්වත් තිබිය යුතු අතර එහි අකුරු, අංක සහ සංකේත අඩංගු විය යුතුය. ඔබගේ මුරපදය අමතක නොකර රහස්‍යව තබා ගන්න.
      கடவுச்சொற்களில் உங்கள் பயனர்பெயர் இருக்கக்கூடாது, மேலும் குறைந்தது 8 எழுத்துக்கள் நீளமாக இருக்க வேண்டும். அதில் எழுத்துக்கள், எண்கள் மற்றும் சின்னங்கள் இருக்க வேண்டும். உங்கள் கடவுச்சொல்லை மறந்துவிடாதீர்கள், அதை ரகசியமாக வைத்திருங்கள்      "'>
   </app-text-input>


   <app-text-input [label]='"Confirm Password"' [formControl]='registerForm.controls["confirmPassword"]'
      [displayMessage]='"Re-enter Password Here"' [type]='"password"'></app-text-input>



   <hr>
   <br>
   <div class="form-group text-center">
      <button [disabled]='!registerForm.valid' class="btn btn-success mr-2" type="submit">Register</button>
      <button class="btn btn-danger mr-2" (click)="cancel()" type="button">Cancel</button>
   </div>

   <hr>


   <br>
   <br>
   <br>
   <br>
   <br>
</form>