import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserWiseSalesItems } from '../_models/purchasedItems';
import { SalesInvoiceService } from '../_services/sales-invoice.service';


@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

  salesInvoiceItems: UserWiseSalesItems[] = [];

  constructor(private invoiceItems : SalesInvoiceService, private toastr:ToastrService) { }

  ngOnInit(): void {
    this.loadPurchasedItems();
  }


  loadPurchasedItems(){
    this.invoiceItems.getPurchasedItems().subscribe(itemCarts=>{
      this.salesInvoiceItems = itemCarts
      
    })
  }

}
