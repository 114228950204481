import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OutInvoice } from '../_models/outinvoice';
import { OutInvoiceItems } from '../_models/outInvoiceItems';
import { UserWiseSalesItems } from '../_models/purchasedItems';
import { PurchaseRequest } from '../_models/purchaseRequest';

@Injectable({
  providedIn: 'root'
})
export class SalesInvoiceService {

  baseUrl = environment.apiUrl;
  salesInvoiceItems: UserWiseSalesItems[] = [];

  constructor(private http: HttpClient) {

  }

  

  getPurchasedItems(){
    return this.http.get<UserWiseSalesItems[]>(this.baseUrl+'SalesInvoice/ItemDetails');
  }

  ConfirmPayment(discountValue:Number){
    return this.http.get<PurchaseRequest>(this.baseUrl+'SalesInvoice/updateInvoice/discountValue?discountValue=' + discountValue);
  }

  CreateDiscount(itemString:string){
    return this.http.get<number>(this.baseUrl+'SalesInvoice/GetInvDiscount/itemList?itemList=' + itemString);
  }

  GetOutstandingInvoice(){
    return this.http.get<OutInvoice[]>(this.baseUrl+'SalesInvoice/GetOutstandingInvoice');
  }

  GetOutInvoiceItems(invNumber:Number){
    return this.http.get<OutInvoiceItems[]>(this.baseUrl+'SalesInvoice/GetOutstandingInvoiceDetails/invoiceID?invoiceID=' +invNumber );
  }

  DeleteOutInvoiceItems(invNumber:Number){
    return this.http.delete<OutInvoiceItems>(this.baseUrl+'SalesInvoice/DeleteInvoice/invoiceID?invoiceNumber=' +invNumber );
  }



}
