<div class="jumbotron container">

    <div style="margin-top: 10px;">


        <div class="row">
            <div class="col-12">

                <P class="card-header fa-2x text-center font-weight-bold  alert alert-dismissible alert-primary"
                    style="margin-top: 20px;">
                    Students Activity Tracker
                </P>

                <div class="alert alert-dismissible alert-warning ">

                    <p style="text-align: justify;">
                        <strong>
                            Dear Student,
                        </strong>
                    </p>

                    <p style="text-align: justify;">
                        Students Can track Activity Using Students Activity tracker.
                    </p>


                </div>
            </div>
        </div>
    </div>

    <br>
    <br>
    <div style="margin-top: 10px;" *ngIf="eAdmissionAllow && examApplied">

        <div class="row">
            <div class="col-12">
                <div class="alert alert-dismissible alert-info">
                    <h1>
                        <strong>Download eAdmission </strong>
                    </h1>

                    <p style="text-align: justify;">
                        Please Use this to Download your eAdmission
                    </p>
                    <h1>
                        <p style="text-align: justify; color: red;">
                            <strong>
                                Please read the instructions carefully before appearing for the exam.
                            </strong>
                        </p>
                    </h1>

                    <br>
                    <hr class="my-4">
                    <p>Click Here to download eAdmission</p>
                    <p>

                        <a href={{downloadsString}} target="_blank">
                            <p-button label="Download" icon="pi pi-check" styleClass="p-button-lg"></p-button>
                        </a>
                        <br>



                    </p>

                    <hr class="my-4">
                    <p>Click Here to download Exam Instructions - {{StudentAppliedMedium}}</p>
                    <p>

                        <a href={{InstructionSheetName}} target="_blank">
                            <p-button label="Download Exam Instructions" icon="pi pi-check" styleClass="p-button-lg">
                            </p-button>
                        </a>
                        <br>



                    </p>


                </div>
            </div>
        </div>
    </div>

    <br>


    <div style="margin-top: 10px;" *ngIf="eAdmissionAllow && !examApplied">

        <div class="row">
            <div class="col-12">
                <div class="alert alert-dismissible alert-danger">
                    <h1>
                        <strong>eAdmission </strong>
                    </h1>

                    <p style="text-align: justify;">
                        You have not submitted a valid Examination Application, If you have applied Online or Submitted
                        your application to AAT Sri Lanka Examination Division please contact AAT Examination Division.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>

</div>