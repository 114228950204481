import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { userActiveStatus } from '../_models/activeStatus';
import { ActiveStudentDetails } from '../_models/ActiveStudentDetails';
import { AppliedCity } from '../_models/AppliedCity';
import { EditUserProfile } from '../_models/editUserProfile';
import { StudentsComplateDetails } from '../_models/StudentsCompleteDetails';
import { StudentsMasterDetails } from '../_models/StudentsMasterDetails';
import { SystemControlFile } from '../_models/SystemControlFile';
import { LogUsers } from '../_models/user';
import { UserCurrentStatus } from '../_models/usercurrentstatus';



@Injectable({
  providedIn: 'root'
})
export class AccountService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  private CurrentUserSource = new ReplaySubject<any>(1);
  currentUser$ = this.CurrentUserSource.asObservable();

  login(model: any) {
    return this.http.post(this.baseUrl + 'StudentProfile/LoginProfileUsers', model).pipe(
      map((response: any) => {
        const user = response;
        if (user) {
          localStorage.setItem('user', JSON.stringify(user));
          this.CurrentUserSource.next(user);
        }
        return user;
      })
    )
  }

  register(model: any) {
    return this.http.post(this.baseUrl + 'StudentProfile/CreateStudentProfileLogin', model).pipe(
      map((user: any) => {
        if (user) {
          //localStorage.setItem('user', JSON.stringify(user));
          //this.CurrentUserSource.next(user);
        }
      })
    )
  }

  setCurrentUser(user: any) {
    this.CurrentUserSource.next(user);
  }

  logout() {
    localStorage.removeItem('user');
    this.CurrentUserSource.next(undefined);
  }


  activeUserLogin(regNumber:string){
    return this.http.get<ActiveStudentDetails>(this.baseUrl+'StudentProfile/ActiveStudent?studentRegNumber='+regNumber);
  }

  resetPassword(emailaddress:string){
    return this.http.get<boolean>(this.baseUrl+'StudentProfile/resetPassword?emailAddress='+emailaddress);
  }


  getUserEditProfile(){
    return this.http.get<EditUserProfile>(this.baseUrl+'SystemUserPassword/viewUserProfile');
  }

  updateUserProfile(userProfile: EditUserProfile){
    return this.http.post<boolean>(this.baseUrl+'SystemUserPassword/UpdateUserProfile',userProfile);
  }

  updateUserPassword(userProfile: EditUserProfile){
    return this.http.post<boolean>(this.baseUrl+'SystemUserPassword/UpdateUserPassword',userProfile);
  }


  getUserDetailsStatus(fromDate:Date, toDate:Date){
    return this.http.get<UserCurrentStatus[]>(this.baseUrl+'SystemUserPassword/GetRegisterUserStatus/'+fromDate+','+toDate);
  }

  getStudentsDetails(studRegNumber:string){
    return this.http.get<StudentsMasterDetails>(this.baseUrl+'StudentProfile/GetStudentDetails?studentRegNumber='+studRegNumber);
  }

  getStudentsDetailResults(){
    return this.http.get<StudentsComplateDetails>(this.baseUrl+'StudentProfile/StudentResultDetail');
  }

  getRenevalStatus(){
    return this.http.get<boolean>(this.baseUrl+'StudentProfile/CheckExamReneval');
  }

  getBlackListedStatus(){
    return this.http.get<boolean>(this.baseUrl+'StudentProfile/CheckBlackListed');
  }


  getSystemControlFiles(){
    return this.http.get<SystemControlFile>(this.baseUrl+'StudentProfile/GetSystemProfile');
  }


  getExamAppliedCity(){
    return this.http.get<AppliedCity[]>(this.baseUrl+'StudentProfile/GetAppliedCityList/');
  }

}
