<div class="container" style="margin-top: 10px;">

    <div class="row">
        <div class="col-12">
            <div class="alert alert-dismissible alert-success ">
                <h1>
                    <strong>Application Submitted Successfully . . . . . . </strong>
                </h1>

                <p style="text-align: justify;">
                    <strong>
                        Dear Student,
                    </strong>
                </p>

                <p style="text-align: justify;">
                    You have successfully applied for the examination. If there are any clarification and shortcomings
                    on exam application, examination division will contact you. You can check further details through
                    ,'Student Status
                </p>


            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-12">
            <p-panel>
                <ng-template pTemplate="header">
                    <h1>
                        <strong>
                            Applied Details
                        </strong>
                    </h1>
                </ng-template>

                <form #StudentAppliedDetailForm="ngForm" autocomplete="off">
                    <div class="row">
                        <div class="col-4">
                            <app-text-input [(ngModel)]="appliedDetails.applicationNumber"
                                [label]='"Online Exam Application ID"' name="applicationID" [childmessage]="true"
                                [displayMessage]='"Online Exam Application ID"'>
                            </app-text-input>
                        </div>

                        <div class="col-4">

                            <app-text-input [ngModel]="appliedDetails.appliedDate| date: 'yyyy/MM/dd'"
                                [label]='"Applied Date"' name="appliedDate" [childmessage]="true"
                                [displayMessage]='"Applied Date"'>
                            </app-text-input>
                        </div>

                        <div class="col-4">
                            <app-text-input [(ngModel)]="appliedDetails.examCode" [label]='"Exam Code"' name="examCode"
                                [childmessage]="true" [displayMessage]='"Exam Code"'>
                            </app-text-input>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-4">
                            <app-text-input [(ngModel)]="appliedDetails.cityName" [label]='"Applied City"'
                                name="appliedCity" [childmessage]="true" [displayMessage]='"Applied City"'>
                            </app-text-input>
                        </div>

                        <div class="col-4">
                            <app-text-input [(ngModel)]="appliedDetails.stageCode" [label]='"Applied Stage"'
                                name="appliedStage" [childmessage]="true" [displayMessage]='"Applied Stage"'>
                            </app-text-input>
                        </div>

                        <div class="col-4">
                            <app-text-input [ngModel]="appliedDetails.totalExamFees | number:'1.2-2'"
                                [label]='"Exam Fees"' name="examFees" [childmessage]="true"
                                [displayMessage]='"Exam Fees"'>
                            </app-text-input>
                        </div>

                    </div>



                </form>

                <hr>

                <div class="row">
                    <div class="col-12">
                        <P class="card-header fa-1x text-center font-weight-bold  alert alert-dismissible alert-primary"
                            style="margin-top: 20px;">
                            Applied Subjects
                        </P>
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-12">

                        <p-table [value]="studentsApplicationSubjects">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center; width: 10%; word-wrap: break-word;">Subject Code</th>
                                    <th style="text-align: center; width: 45%; word-wrap: break-word;">Subject
                                        Description</th>
                                    <th style="text-align: center; width: 10%; word-wrap: break-word;">Subject Level
                                    </th>
                                    <th style="text-align: center; width: 7%; word-wrap: break-word;">Medium</th>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-studentsApplicationSubjects>
                                <tr>
                                    <td style="text-align: center; word-wrap: break-word;">
                                        {{studentsApplicationSubjects.subjectCode}}</td>
                                    <td style="text-align: left; word-wrap: break-word;">
                                        {{studentsApplicationSubjects.subjectName}}</td>
                                    <td style="text-align: center; word-wrap: break-word;">
                                        {{studentsApplicationSubjects.stageCode}}</td>
                                    <td style="text-align: center; word-wrap: break-word;">
                                        {{studentsApplicationSubjects.mediumCode}}</td>
                                </tr>
                            </ng-template>
                        </p-table>

                    </div>
                </div>

                <hr>


                <div class="row">
                    <div class="col-12">
                        <P class="card-header fa-1x text-center font-weight-bold  alert alert-dismissible alert-primary"
                            style="margin-top: 20px;">
                            Payment Details
                        </P>
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-12">

                        <p-table [value]="studentsPayments">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center; width: 10%; word-wrap: break-word;">Line#</th>
                                    <th style="text-align: center; width: 10%; word-wrap: break-word;">Payment Type</th>
                                    <th style="text-align: center; width: 30%; word-wrap: break-word;">Description</th>
                                    <th style="text-align: center; width: 30%; word-wrap: break-word;">Date</th>
                                    <th style="text-align: center; width: 20%; word-wrap: break-word;">Amount</th>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-studentsPayments>
                                <tr>
                                    <td style="text-align: center; word-wrap: break-word;">
                                        {{studentsPayments.paymentLineID}}</td>
                                    <td style="text-align: center; word-wrap: break-word;">
                                        {{studentsPayments.paymentTypeCode}}</td>
                                    <td style="text-align: left; word-wrap: break-word;">
                                        {{studentsPayments.paymentTypeDesc}}</td>
                                    <td style="text-align: center; word-wrap: break-word;">
                                        {{studentsPayments.paidDate | date: 'yyyy/MM/dd'}}</td>
                                    <td style="text-align: right; word-wrap: break-word;">
                                        {{studentsPayments.paidAmount | number:'1.2-2'}}</td>
                                </tr>
                            </ng-template>
                        </p-table>

                    </div>
                </div>

                <hr>





                <ng-template pTemplate="footer">

                    <form #StudentAppliedDetailSummery="ngForm" autocomplete="off">
                        <div class="row">
                            <div class="col-12">
                                <P class="card-header fa-1x text-center font-weight-bold  alert alert-dismissible alert-primary"
                                    style="margin-top: 20px;">
                                    Summery Detail
                                </P>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-4">
                                <app-text-input [(ngModel)]="totalSubjectCount" [label]='"Total Applied Subject Count"'
                                    name="subjectCount" [childmessage]="true"
                                    [displayMessage]='"Applied Subject Count"'>
                                </app-text-input>
                            </div>

                            <div class="col-4">

                                <app-text-input [ngModel]="totalPaymentLines" [label]='"Payment lines"'
                                    name="paymentLines" [childmessage]="true" [displayMessage]='"Payment Lines"'>
                                </app-text-input>
                            </div>

                            <div class="col-4">
                                <app-text-input [ngModel]="totalPaidAmount | number:'1.2-2'"
                                    [label]='"Total Payment Amount"' name="paidAmount" [childmessage]="true"
                                    [displayMessage]='"Total Paid"'>
                                </app-text-input>
                            </div>

                        </div>
                    </form>

                </ng-template>
            </p-panel>
        </div>

    </div>





</div>
<br>
