<div class="container p-md-4">
    <form #ViewReport="ngForm">
        <div style="text-align: center;">
            <h1>
                <strong>
                    Member Details
                </strong>
                
            </h1>
            
        </div>

        <div class="row">

            <div class="col-5">
                <label>From Date</label>
                &nbsp;
                &nbsp;
                <p-calendar name="fromDate" required inputId="basic" [(ngModel)]="model.fromDate"
                    placeholder="From Date"></p-calendar>
            </div>

            <div class="col-5">
                <label>To Date</label>
                &nbsp;
                &nbsp;
                <p-calendar name="todate" required inputId="basic" [(ngModel)]="model.todate" placeholder="To Date">
                </p-calendar>
            </div>

            <div class="col-2">
                <button [disabled]="!ViewReport.valid" class="btn btn-primary" (click)="getUserDetails()"
                    style="width: 100%;" type="submit">View</button>
            </div>

        </div>

        <br>
        <br>

        <div>
            <p-table #dt [value]="userItemDetails">

                <ng-template pTemplate="caption">
                    <div class="p-d-flex" *ngIf="enablebutton === true" >
                        <angular2csv [data]="userItemDetails" filename="MemberDetails.csv" [options]="options">
                            Member Details
                        </angular2csv>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th style="text-align: center; width: 8%;">Member ID</th>
                        <th style="text-align: center; width: 15%;">User Name</th>
                        <th style="text-align: center;">User Full Name</th>
                        <th style="text-align: center;">Mobile Number</th>
                        <th style="text-align: center;">Email Address</th>
                        <th style="text-align: center;">Purchased Status</th>
                        <th style="text-align: center;">Installed Status</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-userItemDetails>
                    <tr>
                        <td>{{userItemDetails.userID}}</td>
                        <td style="word-wrap: break-word;">{{userItemDetails.userName}}</td>
                        <td style="word-wrap: break-word;">{{userItemDetails.userFullName}}</td>
                        <td style="word-wrap: break-word;">{{userItemDetails.userMobileNumber}}</td>
                        <td style="word-wrap: break-word;">{{userItemDetails.userEmailAddress}}</td>
                        <td style="word-wrap: break-word;">{{userItemDetails.purvchasedStatus}}</td>
                        <td style="word-wrap: break-word;">{{userItemDetails.installedStatus}}</td>

                    </tr>
                </ng-template>
            </p-table>
        </div>


    </form>
</div>