import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-howto-register',
  templateUrl: './howto-register.component.html',
  styleUrls: ['./howto-register.component.css']
})
export class HowtoRegisterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
