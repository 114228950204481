<div class="container" style="margin-top:50px">
<!-- Editable table -->
<div class="jumbotron">
  <P class="card-header fa-2x text-center font-weight-bold  alert alert-dismissible alert-primary"  >
      My Shopping Cart
  </P>
  <div class="card-body">
    <div id="table" class="table-editable">
      <span class="table-add float-right mb-3 mr-2">
        <a class="text-success" >
  
        </a>
      </span>
      <table class="table table-bordered table-responsive-md table-striped text-center table-hover ">
          <tr class="headerRow">
             <th class="text-center" style="width: 10%;">Item Line</th>
              <th class="text-center">Item Code</th>
              <th class="text-center">Item Name</th>
              <th class="text-center">Orderd Qty</th>
              <th class="text-center">Rate</th>
              <th class="text-center">Sales Value</th>
              <th class="text-center">Remove</th>
            </tr>

            <tr *ngFor="let itemincart of itemsinCart" [attr.id]="itemincart.itemLineNumber"  >
              <td>
                <span contenteditable="false">{{itemincart.itemLineNumber}} </span>
              </td>
              <td>
                <span contenteditable="false" >{{itemincart.itemCode}}</span>
              </td>
              <td style="text-align: left;">
                <span contenteditable="false" style="text-align: left;" >{{itemincart.itemName}}</span>
              </td>
              <td >
                <span contenteditable="false" >{{itemincart.purchasedQty | number : '1.2-2'}}</span>
              </td>
              <td>
                <span contenteditable="false" >{{itemincart.itemRate | number : '1.2-2'}}</span>
              </td>

              <td>
                      <span contenteditable="false" style="text-align:right;" >{{itemincart.salesValue | number : '1.2-2'}}</span>
              </td>
              
              <td>
                <span class="table-remove" style="align-items: center;">
                  <button mat-fab color="warn"  (click)="removeItemfromCart(itemincart.itemLineNumber)" >
                    <mat-icon>delete</mat-icon>
                  </button>
                </span>                

              </td>
            </tr>
      </table>
    </div>
  </div>
</div>
<div class="jumbotron">
  <div class="card card-header font-weight-bold " style="color: rgb(10, 68, 119); text-shadow: aquamarine; font-weight: bolder;">

    <div class="row" id="info">
      <div class="col-md-12 mb-3 alert alert-dismissible alert-info">
      <h2 class="fa-2x">Sales Summery</h2>
      </div>
      <div class="col-6 col-md-3">
       
      </div>
      <div class="col-6 col-md-3">
      
      </div>
      <div class="col-6 col-md-3">
        <p class="msg">Total Sales :</p>
        <p class="msg">Shipping Charges :</p>
        <p class="msg">Total Discount :</p>
        <p class="msg">Net Sales :</p>

        <br>
        <br>

        <div>

          <button class="btn btn-primary btn-lg btn-block" routerLink='/salesItems' routerLinkActive='active'>Buy More Items</button>
        
        </div>
        
      </div>
      <div class="col-6 col-md-3" style="text-align: right;">
        <p>{{this.totalSales | number : '1.2-2'}}</p>
        <p>{{0 | number : '1.2-2'}}</p>
        <p>{{this.totalDiscount | number : '1.2-2'}}</p>
        <p>{{this.netSales | number : '1.2-2'}} </p>
        <br>
        <br>

        <div>

          <button *ngIf="totalSales > 0" class="btn btn-success btn-lg btn-block" routerLink='/shopcartFinal' routerLinkActive='active'>Check Out</button>

        </div>
        
      </div>
    </div>
   
  </div>
</div>





<br>
<br>
<br>
<br>
<br>

<!-- Editable table -->

</div>
  
