import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DownloadsComponent } from './downloads/downloads.component';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { ServerErrorComponent } from './error/server-error/server-error.component';
import { ExamAppPaymentComponent } from './exam-app-payment/exam-app-payment.component';
import { ExamApplicationConfirmationComponent } from './exam-application-confirmation/exam-application-confirmation.component';
import { ExamApplicationHelpComponent } from './exam-application-help/exam-application-help.component';
import { ExameAdmissionHelpComponent } from './exame-admission-help/exame-admission-help.component';
import { ExaminationAppStudentComponent } from './examination-app-student/examination-app-student.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { HelpComponent } from './help/help.component';
import { HomeComponent } from './home/home.component';
import { HowtoRegisterComponent } from './howto-register/howto-register.component';
import { ListsComponent } from './lists/lists.component';
import { LoginIssuesComponent } from './login-issues/login-issues.component';
import { ManualPaymentConfirmationComponent } from './manual-payment-confirmation/manual-payment-confirmation.component';
import { ManualPaymentUpdateComponent } from './manual-payment-update/manual-payment-update.component';
import { MessagesComponent } from './messages/messages.component';
import { NewExemptionDisplayBannerComponent } from './new-exemption-display-banner/new-exemption-display-banner.component';
import { OutinvDispComponent } from './outinv-disp/outinv-disp.component';
import { RegiConfComponent } from './regi-conf/regi-conf.component';
import { RegisterComponent } from './register/register.component';
import { ResendUserActivationEmailComponent } from './resend-user-activation-email/resend-user-activation-email.component';
import { SalesItemsDetailsComponent } from './salesItems/sales-items-details/sales-items-details.component';
import { SalesItemsListComponent } from './salesItems/sales-items-list/sales-items-list.component';
import { ShopcartFinalComponent } from './shopcart-final/shopcart-final.component';
import { StudentDetailsComponent } from './student-details/student-details.component';
import { StudentHistoryComponent } from './student-history/student-history.component';
import { StudentsExewmptionRequestComponent } from './students-exewmption-request/students-exewmption-request.component';
import { StudentsMyTrackerComponent } from './students-my-tracker/students-my-tracker.component';
import { StudentzregistrationNewComponent } from './studentzregistration-new/studentzregistration-new.component';
import { UserActiveComponent } from './user-active/user-active.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserprofileEditComponent } from './userprofile-edit/userprofile-edit.component';
import { AuthGuard } from './_guards/auth.guard';


const routes: Routes = [
  { path: '', component: HomeComponent },
  {

    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      { path: 'StudentsDetails', component: StudentDetailsComponent },
      { path: 'ExamApplication', component: ExaminationAppStudentComponent },
      { path: 'lists', component: ListsComponent },
      { path: 'messages', component: MessagesComponent },
      { path: 'downloads', component: DownloadsComponent },
      { path: 'shopcartFinal', component: ShopcartFinalComponent },
      { path: 'userprofileview', component: UserprofileEditComponent },
      {
        path: 'help', component: HelpComponent,
        children: [
          { path: 'ExamHelp', component: ExamApplicationHelpComponent },
          { path: 'EAdmissionHelp', component: ExameAdmissionHelpComponent }
        ]
      },
      { path: 'OutInvoices', component: OutinvDispComponent },
      { path: 'Reports', component: UserDetailsComponent },
      { path: 'ExamPayments', component: ExamAppPaymentComponent },
      { path: 'ExamPayConfirm', component: ExamApplicationConfirmationComponent },
      { path: 'ManualPaymentUpdate', component: ManualPaymentUpdateComponent },
      { path: 'ManualPaymentConfirm', component: ManualPaymentConfirmationComponent },
      { path: 'StudentExempRequest', component: StudentsExewmptionRequestComponent },
      { path: 'StudentsMyTracker', component: StudentsMyTrackerComponent },
      { path: 'StudentsResultHistory', component: StudentHistoryComponent },
      { path: 'ExempReqestBanner', component: NewExemptionDisplayBannerComponent },


      




    ]
  },

  { path: 'not-found', component: NotFoundComponent },
  { path: 'StudRegister', component: StudentzregistrationNewComponent },
  { path: 'server-error', component: ServerErrorComponent },
  { path: 'regi-conf', component: RegiConfComponent },
  { path: 'userActive/:regNumber', component: UserActiveComponent },
  { path: 'forgetpassword', component: ForgetpasswordComponent },
  { path: 'HowtoRegister', component: HowtoRegisterComponent },
  { path: 'LoginIssues', component: LoginIssuesComponent },
  { path: 'ResendActivationLink', component: ResendUserActivationEmailComponent },

  { path: '**', component: NotFoundComponent, pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
