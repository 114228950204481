import { NumberSymbol } from "@angular/common";

export interface pagination{
    CurrentPage: number;
    ItemsPerPage: number;
    TotalItems: number;
    TotalPages:number;
}

export class PaginatedResult<T>{
    result: T;
    pagination: pagination;
}