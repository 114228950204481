import { Component, OnInit } from '@angular/core';
import { StudentResult } from '../_models/StudentResult';
import { StudentsComplateDetails } from '../_models/StudentsCompleteDetails';
import { AccountService } from '../_services/account.service';

@Component({
  selector: 'app-student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.css']
})
export class StudentDetailsComponent implements OnInit {

  studentRegID: string;
  studRegCompDetails: StudentsComplateDetails;
  studentResult: StudentResult[];

  constructor(public accountservive: AccountService) { }

  ngOnInit(): void {
    this.GetStudentsDetails();
  }


  GetStudentsDetails() {
    this.accountservive.getStudentsDetailResults().subscribe(userdetails => {
      this.studRegCompDetails = userdetails;
      this.studentResult = this.studRegCompDetails.studentResult;
    }

    );
  }






}
