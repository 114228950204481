import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { SalesItemsInter } from 'src/app/_models/SalesItemsInt';
import { LogUsers } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account.service';
import { SalesitemsService } from 'src/app/_services/salesitems.service';


@Component({
  selector: 'app-salesitem-card',
  templateUrl: './salesitem-card.component.html',
  styleUrls: ['./salesitem-card.component.css']
  
})
export class SalesitemCardComponent implements OnInit {
  
  @Input() item: SalesItemsInter;
  
  CartItems: any = {} ;
  user :LogUsers;

  constructor(private itemservice : SalesitemsService,public accountservive: AccountService, private route : ActivatedRoute, private dialog : MatDialog, private toastr:ToastrService) { 
    this.accountservive.currentUser$.pipe(take(1)).subscribe(user=>this.user = user);
  }

  ngOnInit(): void {
  }

  AddItemToCart(){  
    
    this.CartItems.itemCode = this.item.itemCodeID;
    this.CartItems.userName= this.user.userId;
    this.CartItems.purchasedQty = 1;
    this.CartItems.itemRate = this.item.itemPrice;
    this.CartItems.salesValue = this.item.itemPrice;
    this.CartItems.addedDateTime = new Date();

   
    this.itemservice.addUserItemtoCart(this.CartItems).subscribe(response=>{
      console.log(response);
      this.toastr.success("Your Item Added to Cart");  
    },error=>{
      console.log(error);
      this.toastr.error(error.error);             
    }
    )
  }

}
