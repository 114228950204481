<div class="container "
    *ngIf="renevalStatus && !BlackListedStatus && examAppliedCityList != undefined && !examApplied && examApplicationAllow && calssCodeEnterd">
    <form [formGroup]="examAppForm" (ngSubmit)=" CheckApplicationVerification()" autocomplete="off">

        <div class="jumbotron">

            <div class="row ">
                <div class="col-12">
                    <P class="card-header fa-2x text-center font-weight-bold  alert alert-dismissible alert-primary"
                        style="margin-top: 20px;">
                        Exam Application for The {{sysConFile.examName}}

                    </P>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-4">
                    <label style="color:coral; font-weight: bolder;">Applying City</label>
                    <select id="select1" name="AppliedCity" class="form-control"
                        [formControl]='examAppForm.controls["cityCode"]'>
                        <option *ngFor="let city of examAppliedCityList;" [value]="city.cityCode + '-' + city.cityName">
                            {{city.cityName}}
                        </option>
                    </select>
                </div>

            </div>
            <div class="row">
                <div form-group col-6>
                    <div *ngIf="examAppForm.controls['cityCode'].invalid " class="alert">
                        <div *ngIf="examAppForm.controls['cityCode'].errors.required">
                            <p-message severity="error" text="Please Select Applying City">
                            </p-message>
                        </div>
                    </div>
                </div>

            </div>
        </div>



        <div class="card">

            <div class="card-header" style="background-color: rgba(221, 230, 231, 0.952);">

                <div class="row">
                    <div class="form-group col-6">
                        <h1>
                            <strong>
                                Level 1 - LE1
                            </strong>
                        </h1>
                    </div>


                    <div class="form-group col-6" style="text-align: right;">
                        <h1>
                            <label style="color:coral; font-weight: bolder;" *ngIf="SubjectsSelectedOne.length > 0">
                                Selected Subjects : {{SubjectsSelectedOne ? SubjectsSelectedOne.length
                                :
                                0 }}
                            </label>
                        </h1>

                    </div>
                </div>
            </div>

            <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);">
                <div class="row">
                    <div class="form-group col-3" *ngIf="SubjectsSelectedOne.length > 0">
                        <label style="color:coral; font-weight: bolder;">Medium Apply</label>
                        <select id="select1" name="mediumApply" class="form-control"
                            [formControl]='examAppForm.controls["stageOneMedium"]'>
                            <option value='SINHALA'>SINHALA</option>
                            <option value='TAMIL'>TAMIL</option>
                            <option value='ENGLISH'>ENGLISH</option>
                        </select>
                    </div>
                    <div *ngIf="SubjectsSelectedOne.length > 0">

                    </div>

                </div>

                <div class="row">
                    <div class="form-group col-12">
                        <p-table #dt [value]="stageOneSubjects" dataKey="subjectCode" responsiveLayout="scroll"
                            [(selection)]="SubjectsSelectedOne" [rowHover]="true">

                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center; width: 10%;">SER#</th>
                                    <th style="text-align: center; width: 20%;">Subject Code</th>
                                    <th style="text-align: center; width: 40%;">Subject Name</th>
                                    <th style="text-align: center; width: 20%;">Status</th>
                                    <th style="text-align: center; width: 10%;">Select</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-stageOneSubjects>
                                <tr>
                                    <td style="text-align: center;">{{stageOneSubjects.lineNumber}}</td>
                                    <td style="text-align: center;">{{stageOneSubjects.subjectCode}}</td>
                                    <td style="text-align: left;">{{stageOneSubjects.subjectName}}</td>
                                    <td style="text-align: center;">
                                        <span *ngIf="stageOneSubjects.completed == 1"
                                            style="background-color: rgb(4, 2, 114); color: gainsboro;"><strong>Completed</strong></span>
                                        <span *ngIf="stageOneSubjects.completed == 0"
                                            style="background-color: crimson; color: gainsboro;"><strong>Not
                                                Completed
                                            </strong></span>
                                    </td>
                                    <td style="text-align: center;">

                                        <p-tableCheckbox
                                            [disabled]="stageOneSubjects.applyStatus == '1' || stageOneSubjects.completed == 1 "
                                            [value]="stageOneSubjects">
                                        </p-tableCheckbox>

                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="summary">
                                <div *ngIf="SubjectsSelectedOne.length > 0">
                                    <div *ngIf="examAppForm.controls['stageOneMedium'].invalid " class="alert">
                                        <div *ngIf="examAppForm.controls['stageOneMedium'].errors.required">
                                            <p-message severity="error" text="Please Select Stage One Medium">
                                            </p-message>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </p-table>
                    </div>
                </div>


            </div>
        </div>
        <br>

        <div class="card">
            <div class="card-header" style="background-color: rgba(221, 230, 231, 0.952);">
                <div class="row">
                    <div class="form-group col-6">
                        <h1>
                            <strong>
                                Level Two - LE2
                            </strong>
                        </h1>
                    </div>


                    <div class="form-group col-6" style="text-align: right;">
                        <h1>
                            <label style="color:coral; font-weight: bolder;" *ngIf="SubjectsSelectedTwo.length > 0">
                                Selected Subjects : {{SubjectsSelectedTwo ? SubjectsSelectedTwo.length
                                :
                                0 }}
                            </label>
                        </h1>

                    </div>
                </div>

            </div>

            <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);">
                <div class="row">
                    <div class="form-group col-3" *ngIf="SubjectsSelectedTwo.length > 0">
                        <label style="color:coral; font-weight: bolder;">Medium Apply</label>
                        <select id="select1" name="mediumApply" class="form-control"
                            [formControl]='examAppForm.controls["stageTwoMedium"]'>
                            <option value='SINHALA'>SINHALA</option>
                            <option value='TAMIL'>TAMIL</option>
                            <option value='ENGLISH'>ENGLISH</option>
                        </select>
                    </div>

                </div>

                <div class="row">
                    <div class="form-group col-12">
                        <p-table #dt [value]="stageTwoSubjects" dataKey="subjectCode" responsiveLayout="scroll"
                            [(selection)]="SubjectsSelectedTwo" [rowHover]="true">

                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center; width: 10%;">SER#</th>
                                    <th style="text-align: center; width: 20%;">Subject Code</th>
                                    <th style="text-align: center; width: 40%;">Subject Name</th>
                                    <th style="text-align: center; width: 20%;">Status</th>
                                    <th style="text-align: center; width: 10%;">Select</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-stageTwoSubjects>
                                <tr>
                                    <td style="text-align: center;">{{stageTwoSubjects.lineNumber}}</td>
                                    <td style="text-align: center;">{{stageTwoSubjects.subjectCode}}</td>
                                    <td style="text-align: left;">{{stageTwoSubjects.subjectName}}</td>
                                    <td style="text-align: center;">
                                        <span *ngIf="stageTwoSubjects.completed == 1"
                                            style="background-color: rgb(4, 2, 114); color: gainsboro;"><strong>Completed</strong></span>
                                        <span *ngIf="stageTwoSubjects.completed == 0"
                                            style="background-color: crimson; color: gainsboro;"><strong>Not
                                                Completed</strong></span>
                                    </td>
                                    <td style="text-align: center;">

                                        <p-tableCheckbox
                                            [disabled]="stageTwoSubjects.applyStatus == '1' || stageTwoSubjects.completed == 1 "
                                            [value]="stageTwoSubjects">
                                        </p-tableCheckbox>


                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="summary">

                                <div *ngIf="SubjectsSelectedTwo.length > 0">

                                    <div *ngIf="examAppForm.controls['stageTwoMedium'].invalid " class="alert">
                                        <div *ngIf="examAppForm.controls['stageTwoMedium'].errors.required">
                                            <p-message severity="error" text="Please Select Stage Two Medium"
                                                styleClass="p-mr-2">
                                            </p-message>
                                        </div>
                                    </div>
                                </div>


                            </ng-template>
                        </p-table>
                    </div>
                </div>

            </div>
        </div>
        <br>

        <div class="card">
            <div class="card-header" style="background-color: rgba(221, 230, 231, 0.952);">
                <div class="row">
                    <div class="form-group col-6">
                        <h1>
                            <strong>
                                Level Three - LE3
                            </strong>
                        </h1>
                    </div>


                    <div class="form-group col-6" style="text-align: right;">
                        <h1 style="color:coral; font-weight: bolder;">
                            <label style="color:coral; font-weight: bolder;" *ngIf="SubjectsSelectedThree.length > 0">
                                Selected Subjects : {{SubjectsSelectedThree ? SubjectsSelectedThree.length
                                :
                                0 }}
                            </label>
                        </h1>

                    </div>
                </div>
            </div>

            <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);">
                <div class="row">
                    <div class="form-group col-3" *ngIf="SubjectsSelectedThree.length > 0">
                        <label style="color:coral; font-weight: bolder;">Medium Apply</label>
                        <select id="select1" name="mediumApply" class="form-control"
                            [formControl]='examAppForm.controls["stageThreeMedium"]'>
                            <option value='SINHALA'>SINHALA</option>
                            <option value='TAMIL'>TAMIL</option>
                            <option value='ENGLISH'>ENGLISH</option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-12">
                        <p-table #dt [value]="stageThreeSubjects" dataKey="subjectCode" responsiveLayout="scroll"
                            [(selection)]="SubjectsSelectedThree" [rowHover]="true">

                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center; width: 10%;">SER#</th>
                                    <th style="text-align: center; width: 20%;">Subject Code</th>
                                    <th style="text-align: center; width: 40%;">Subject Name</th>
                                    <th style="text-align: center; width: 20%;">Status</th>
                                    <th style="text-align: center; width: 10%;">Select</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-stageThreeSubjects>
                                <tr>
                                    <td style="text-align: center;">{{stageThreeSubjects.lineNumber}}</td>
                                    <td style="text-align: center;">{{stageThreeSubjects.subjectCode}}</td>
                                    <td style="text-align: left;">{{stageThreeSubjects.subjectName}}</td>
                                    <td style="text-align: center;">
                                        <span *ngIf="stageThreeSubjects.completed == 1"
                                            style="background-color: rgb(4, 2, 114); color: gainsboro;"><strong>Completed</strong></span>
                                        <span *ngIf="stageThreeSubjects.completed == 0"
                                            style="background-color: crimson; color: gainsboro;"><strong>Not
                                                Completed</strong></span>
                                    </td>
                                    <td style="text-align: center;">
                                        <p-tableCheckbox
                                            [disabled]="stageThreeSubjects.applyStatus == '1' || stageThreeSubjects.completed == 1 "
                                            [value]="stageThreeSubjects">
                                        </p-tableCheckbox>
                                    </td>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="summary">

                                <div *ngIf="SubjectsSelectedThree.length > 0">

                                    <div *ngIf="examAppForm.controls['stageThreeMedium'].invalid " class="alert">
                                        <div *ngIf="examAppForm.controls['stageThreeMedium'].errors.required">
                                            <p-message severity="error" text="Please Select Stage Three Medium">
                                            </p-message>
                                        </div>
                                    </div>
                                </div>



                            </ng-template>

                        </p-table>
                    </div>
                </div>
            </div>
        </div>
        <br>

        <div class="card">
            <div class="card-header" style="background-color: rgba(221, 230, 231, 0.952);">
                <div class="row">
                    <div class="form-group col-6">
                        <h1>
                            <strong>
                                Talent Subject (TS)
                            </strong>
                        </h1>
                    </div>


                    <div class="form-group col-6" style="text-align: right;">
                        <h1>
                            <label style="color:coral; font-weight: bolder;" *ngIf="SubjectsSelectedSKI.length > 0">
                                Selected Subjects : {{SubjectsSelectedSKI ? SubjectsSelectedSKI.length
                                :
                                0 }}
                            </label>
                        </h1>

                    </div>
                </div>
            </div>

            <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);">

                <div class="row">
                    <div class="form-group col-12">
                        <p-table #dt [value]="stageSKISubjects" dataKey="subjectCode" responsiveLayout="scroll"
                            [(selection)]="SubjectsSelectedSKI" [rowHover]="true">

                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: center; width: 10%;">SER#</th>
                                    <th style="text-align: center; width: 20%;">Subject Code</th>
                                    <th style="text-align: center; width: 40%;">Subject Name</th>
                                    <th style="text-align: center; width: 20%;">Status</th>
                                    <th style="text-align: center; width: 10%;">Select</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-stageSKISubjects>
                                <tr>
                                    <td style="text-align: center;">{{stageSKISubjects.lineNumber}}</td>
                                    <td style="text-align: center;">{{stageSKISubjects.subjectCode}}</td>
                                    <td style="text-align: left;">{{stageSKISubjects.subjectName}}</td>
                                    <td style="text-align: center;">
                                        <span *ngIf="stageSKISubjects.completed == 1"
                                            style="background-color: chartreuse; color: gainsboro;"><strong>Completed</strong></span>
                                        <span *ngIf="stageSKISubjects.completed == 0"
                                            style="background-color: crimson; color: gainsboro;"><strong>Not
                                                Completed</strong></span>
                                    </td>
                                    <td style="text-align: center;">
                                        <p-tableCheckbox
                                            [disabled]="stageSKISubjects.applyStatus == '1' || stageSKISubjects.completed == 1 "
                                            [value]="stageSKISubjects">
                                        </p-tableCheckbox>
                                    </td>
                                </tr>
                            </ng-template>

                        </p-table>
                    </div>
                </div>
            </div>
        </div>

        <hr>
        <br>
        <div class="row">
            <div class="form-group col-4">
                <div class="text-left">

                    <button pButton icon="pi pi-check" label="Confirm"></button>


                </div>
            </div>

        </div>


    </form>

</div>

<br>

<div class="container "
    *ngIf="renevalStatus && !BlackListedStatus && examAppliedCityList != undefined && !examApplied && examApplicationAllow && !calssCodeEnterd && !paymentConfirmation">

    <div class="row alert alert-dismissible alert-danger">
        <button type="button" class="close" data-dismiss="info">&times;</button>
        <div class="col-12" style="text-align: center;">
            <h1>
                <strong>
                    Payment Notice !
                </strong>
            </h1>
        </div>

        <div class="col-12" style="text-align: center;">
            <p>
                Your application for the January 2025 Exam will be rejected if you have not made the exam fee on or
                before 20th November 2024. Please make the payment and complete the online application to be eligible for the
                examination.
            </p>
        </div>
    </div>

    <div class="row alert alert-dismissible alert-info">
        <button type="button" class="close" data-dismiss="info">&times;</button>
        <div class="col-12" style="text-align: center;">
            <h1>
                <strong>
                    Time Table !
                </strong>
            </h1>
        </div>

        <div class="col-12" style="text-align: center;">
            <p>
                AAT January 2025 Examination dates;
                1st and  2nd of February 2025 - Level II and III
                8th and 9th of February 2025  - Level I and BC
                Applications closing date 20th November 2024
            </p>
        </div>
    </div>

    <div>

        <hr>

        <div class="row">           

            <div class="col-12 " style="text-align: center;">
                <p-button label="I Agree" [style]="{'width':'100%'}"
                    styleClass="p-button-raised p-button-help" (onClick)="verifyConfirmation($event)"></p-button>
            </div>
        </div>

    </div>   

</div>


<div class="container "
    *ngIf="renevalStatus && !BlackListedStatus && examAppliedCityList != undefined && !examApplied && examApplicationAllow && !calssCodeEnterd && paymentConfirmation">

    <div class="row alert alert-dismissible alert-info">
        <button type="button" class="close" data-dismiss="info">&times;</button>
        <div class="col-12" style="text-align: center;">
            <h1>
                <strong>
                    Select or Add Your Class
                </strong>
            </h1>
        </div>

        <div class="col-12" style="text-align: center;">
            <p>
                Please Enter your class or If your class is not available in the List, please add your class.
            </p>
        </div>
    </div>

    <hr>

    <div class="jumbotron">

        <div class="row">

            <div class="col-12" style="text-align: center;">
                <h1>
                    <strong>
                        Select or Add Your Class
                    </strong>
                </h1>
            </div>

        </div>


        <hr>

        <div class="row">

            <div class="col-12" style="text-align: left;">

                <p-dropdown [options]="classList" [(ngModel)]="selectedClass" optionLabel="className" [filter]="true"
                    filterBy="className" [showClear]="true" placeholder="Search Your Class" [style]="{'width':'100%'}"
                    [inputStyle]="{'width':'100%'}">
                    <ng-template pTemplate="selectedItem">
                        <div class="flex align-items-left gap-2" *ngIf="selectedClass">
                            <div>{{ selectedClass.className }}</div>
                        </div>

                    </ng-template>
                    <ng-template let-classList pTemplate="item">
                        <div class="flex align-items-left gap-2">
                            <div>{{ classList.className }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>

            </div>

        </div>

        <hr>

        <div class="row">

            <div class="col-6 P" style="text-align: center;">
                <p-button label="Submit" icon="pi pi-check" [style]="{'width':'100%'}"
                    styleClass="p-button-raised p-button-success" (onClick)="checkValidClass($event)"></p-button>
            </div>

            <div class="col-6 " style="text-align: center;">
                <p-button label="My Class Not Available" icon="pi pi-plus" [style]="{'width':'100%'}"
                    styleClass="p-button-raised p-button-help" (onClick)="checkAddNewClass($event)"></p-button>
            </div>
        </div>
    </div>

    <div class="row alert alert-dismissible alert-danger">
        <button type="button" class="close" data-dismiss="info">&times;</button>
        <div class="col-12" style="text-align: center;">
            <h1>
                <strong>
                    Notice
                </strong>
            </h1>
        </div>

        <div class="col-12" style="text-align: center;">
            <p>
                Please Enter your class or If your class is not available in the List, please add your class.
            </p>
        </div>
    </div>

</div>




<div class="container  " *ngIf="renevalStatus && !examApplied && !examApplicationAllow " style="margin-top: 10px;">
    <div class="row alert alert-dismissible alert-danger">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <div class="col-12" style="text-align: center;">
            <h1>
                <strong>
                   
                    Exam Application Closed !
                    
                     <!--
                    Exam Application Not Opened Yet
                    -->
                </strong>
            </h1>
        </div>

        <div class="col-12" style="text-align: center;">
            <p>
                
                Exam Application Submission Date Passed, Please Contact AAT Examination Division for More Information.
                
                <!--
                Online exam application facility will be available from 10th November 2023. If you have not already
                applied for the AAT exam by completing an application form please apply via online.
                -->
            </p>
        </div>



    </div>
</div>


<div class="container  " *ngIf="!renevalStatus && !examApplied" style="margin-top: 10px;">
    <div class="row alert alert-dismissible alert-danger">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <div class="col-12" style="text-align: center;">
            <h1>
                <strong>
                    You are not allowed to apply for the Examination !
                </strong>
            </h1>
        </div>

        <div class="col-12" style="text-align: center;">
            <p>
                You have not renewed your Studentship for the current examination Year, Due to that, you are not allowed
                to apply for the examination. Please renew your AAT Studentship for the Current Examination Year.
            </p>
        </div>



    </div>
</div>





<div class="container  " *ngIf="examApplied && appliedDetails != undefined && examApplicationAllow "
    style="margin-top: 10px;">
    <div class="container" style="margin-top: 10px;">

        <div class="row">
            <div class="col-12">
                <div class="alert alert-dismissible alert-info " *ngIf="!examPaymentBalance">
                    <h1>
                        <strong>Application Submitted </strong>
                    </h1>

                    <p style="text-align: justify;">
                        <strong>
                            Dear Student,
                        </strong>
                    </p>

                    <p style="text-align: justify;">
                        You have successfully applied for the examination. If there are any clarification and
                        shortcomings on exam application, examination division will contact you.

                    </p>


                </div>

                <div class="alert alert-dismissible alert-danger " *ngIf="examPaymentBalance">
                    <h1>
                        <strong> Payment Not Completed - Complete Payment </strong>
                    </h1>

                    <p style="text-align: justify;">
                        <strong>
                            Dear Student,
                        </strong>
                    </p>

                    <p style="text-align: justify;">
                        Your Application has been submitted but you have not completed the payment. Please make arrange
                        your payment. If you have submitted a manual application, please ignore this application.

                    </p>


                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-12">
                <p-panel>
                    <ng-template pTemplate="header">
                        <h1>
                            <strong>
                                Applied Details
                            </strong>
                        </h1>
                    </ng-template>

                    <form #StudentAppliedDetailForm="ngForm" autocomplete="off">
                        <div class="row">
                            <div class="col-4">
                                <app-text-input [(ngModel)]="appliedDetails.applicationNumber"
                                    [label]='"Online Exam Application ID"' name="applicationID" [childmessage]="true"
                                    [displayMessage]='"Online Exam Application ID"'>
                                </app-text-input>
                            </div>

                            <div class="col-4">

                                <app-text-input [ngModel]="appliedDetails.appliedDate| date: 'yyyy/MM/dd'"
                                    [label]='"Applied Date"' name="appliedDate" [childmessage]="true"
                                    [displayMessage]='"Applied Date"'>
                                </app-text-input>
                            </div>

                            <div class="col-4">
                                <app-text-input [(ngModel)]="appliedDetails.examCode" [label]='"Exam Code"'
                                    name="examCode" [childmessage]="true" [displayMessage]='"Exam Code"'>
                                </app-text-input>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-4">
                                <app-text-input [(ngModel)]="appliedDetails.cityName" [label]='"Applied City"'
                                    name="appliedCity" [childmessage]="true" [displayMessage]='"Applied City"'>
                                </app-text-input>
                            </div>

                            <div class="col-4">
                                <app-text-input [(ngModel)]="appliedDetails.stageCode" [label]='"Applied Stage"'
                                    name="appliedStage" [childmessage]="true" [displayMessage]='"Applied Stage"'>
                                </app-text-input>
                            </div>

                            <div class="col-4">
                                <app-text-input [ngModel]="appliedDetails.totalExamFees | number:'1.2-2'"
                                    [label]='"Exam Fees"' name="examFees" [childmessage]="true"
                                    [displayMessage]='"Exam Fees"'>
                                </app-text-input>
                            </div>

                        </div>



                    </form>

                    <hr>

                    <div class="row">
                        <div class="col-12">
                            <P class="card-header fa-1x text-center font-weight-bold  alert alert-dismissible alert-primary"
                                style="margin-top: 20px;">
                                Applied Subjects
                            </P>
                        </div>
                    </div>

                    <hr>
                    <div class="row">
                        <div class="col-12">

                            <p-table [value]="studentsApplicationSubjects">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center; width: 10%; word-wrap: break-word;">Subject Code
                                        </th>
                                        <th style="text-align: center; width: 45%; word-wrap: break-word;">Subject
                                            Description</th>
                                        <th style="text-align: center; width: 10%; word-wrap: break-word;">Subject Level
                                        </th>
                                        <th style="text-align: center; width: 7%; word-wrap: break-word;">Medium</th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-studentsApplicationSubjects>
                                    <tr>
                                        <td style="text-align: center; word-wrap: break-word;">
                                            {{studentsApplicationSubjects.subjectCode}}</td>
                                        <td style="text-align: left; word-wrap: break-word;">
                                            {{studentsApplicationSubjects.subjectName}}</td>
                                        <td style="text-align: center; word-wrap: break-word;">
                                            {{studentsApplicationSubjects.stageCode}}</td>
                                        <td style="text-align: center; word-wrap: break-word;">
                                            {{studentsApplicationSubjects.mediumCode}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>

                        </div>
                    </div>

                    <hr>


                    <div class="row">
                        <div class="col-12">
                            <P class="card-header fa-1x text-center font-weight-bold  alert alert-dismissible alert-primary"
                                style="margin-top: 20px;">
                                Payment Details
                            </P>
                        </div>
                    </div>

                    <hr>
                    <div class="row">
                        <div class="col-12">

                            <p-table [value]="studentsPayments">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center; width: 10%; word-wrap: break-word;">Line#</th>
                                        <th style="text-align: center; width: 10%; word-wrap: break-word;">Payment Type
                                        </th>
                                        <th style="text-align: center; width: 30%; word-wrap: break-word;">Description
                                        </th>
                                        <th style="text-align: center; width: 30%; word-wrap: break-word;">Date</th>
                                        <th style="text-align: center; width: 20%; word-wrap: break-word;">Amount</th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-studentsPayments>
                                    <tr>
                                        <td style="text-align: center; word-wrap: break-word;">
                                            {{studentsPayments.paymentLineID}}</td>
                                        <td style="text-align: center; word-wrap: break-word;">
                                            {{studentsPayments.paymentTypeCode}}</td>
                                        <td style="text-align: left; word-wrap: break-word;">
                                            {{studentsPayments.paymentTypeDesc}}</td>
                                        <td style="text-align: center; word-wrap: break-word;">
                                            {{studentsPayments.paidDate | date: 'yyyy/MM/dd'}}</td>
                                        <td style="text-align: right; word-wrap: break-word;">
                                            {{studentsPayments.paidAmount | number:'1.2-2'}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>

                        </div>
                    </div>

                    <hr>





                    <ng-template pTemplate="footer">

                        <form #StudentAppliedDetailSummery="ngForm" autocomplete="off" *ngIf="!examPaymentBalance">
                            <div class="row">
                                <div class="col-12">
                                    <P class="card-header fa-1x text-center font-weight-bold  alert alert-dismissible alert-primary"
                                        style="margin-top: 20px;">
                                        Summery Detail
                                    </P>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-4">
                                    <app-text-input [(ngModel)]="totalSubjectCount"
                                        [label]='"Total Applied Subject Count"' name="subjectCount"
                                        [childmessage]="true" [displayMessage]='"Applied Subject Count"'>
                                    </app-text-input>
                                </div>

                                <div class="col-4">

                                    <app-text-input [ngModel]="totalPaymentLines" [label]='"Payment lines"'
                                        name="paymentLines" [childmessage]="true" [displayMessage]='"Payment Lines"'>
                                    </app-text-input>
                                </div>

                                <div class="col-4">
                                    <app-text-input [ngModel]="totalPaidAmount | number:'1.2-2'"
                                        [label]='"Total Payment Amount"' name="paidAmount" [childmessage]="true"
                                        [displayMessage]='"Total Paid"'>
                                    </app-text-input>
                                </div>

                            </div>
                        </form>

                        <form #StudentAppliedDetailSummery="ngForm" autocomplete="off" *ngIf="examPaymentBalance">
                            <div class="row alert alert-dismissible alert-danger">
                                <button type="button" class="close" data-dismiss="alert">&times;</button>

                                <div class="col-12" style="text-align: center;">
                                    <p>
                                        You have unpaid exam fees, please complete your payment. Otherwise, you will not
                                        be able to sit for the examination.
                                    </p>
                                </div>
                            </div>
                            <hr>
                            <div class="row">

                                <div class="col-4">
                                    <app-text-input [ngModel]="totalPaidAmount | number:'1.2-2'"
                                        [label]='"Total Payment Amount"' name="paidAmount" [childmessage]="true"
                                        [displayMessage]='"Total Paid"'>
                                    </app-text-input>
                                </div>

                                <div class="col-4">
                                    <app-text-input
                                        [ngModel]="appliedDetails.totalExamFees-totalPaidAmount | number:'1.2-2'"
                                        [label]='"Balance Payment Amount"' name="balanceAmount" [childmessage]="true"
                                        [displayMessage]='"Balance Amount"'>
                                    </app-text-input>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <p-button type="submit" icon="pi pi-check" (onClick)="payBalanceAmount()"
                                        label="Pay Balance Amount" class="p-button-text"></p-button>
                                </div>

                            </div>
                        </form>

                    </ng-template>
                </p-panel>
            </div>

        </div>





    </div>
    <br>
</div>



<div class="container  " *ngIf="BlackListedStatus && !examApplied" style="margin-top: 10px;">
    <div class="row alert alert-dismissible alert-danger">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <div class="col-12" style="text-align: center;">
            <h1>
                <strong>
                    You are not allowed to apply for the Examination !
                </strong>
            </h1>
        </div>

        <div class="col-12" style="text-align: center;">
            <p>
                Your Studentship has been suspended, if you need further clarification please contact AAT Sri Lanka
                Registration Division.
            </p>
        </div>



    </div>
</div>


<p-dialog header='Applied City : {{examAppForm.controls["cityCode"].value}}' [(visible)]="displayModal" [modal]="true"
    [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">

    <div class="card-body" style="background-color: rgba(236, 243, 235, 0.877);"
        *ngIf="AppliedCompleteSubjects.length > 0">

        <div class="row">
            <div class="form-group col-12">
                <p-table #dt [value]="AppliedCompleteSubjects" dataKey="subjectCode" responsiveLayout="scroll"
                    [rowHover]="true">

                    <ng-template pTemplate="header">
                        <tr>
                            <th style="text-align: center; width: 20%;">Subject Code</th>
                            <th style="text-align: center; width: 40%;">Subject Name</th>
                            <th style="text-align: center; width: 10%;">Stage</th>
                            <th style="text-align: center; width: 20%;">Medium</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-AppliedCompleteSubjects | orderby :subjectCode>
                        <tr>
                            <td style="text-align: center;">{{AppliedCompleteSubjects.subjectCode}}</td>
                            <td style="text-align: left;">{{AppliedCompleteSubjects.subjectName}}</td>
                            <td style="text-align: left;">{{AppliedCompleteSubjects.stageCode}}</td>
                            <td style="text-align: center;">
                                <span *ngIf="AppliedCompleteSubjects.stageCode == 'SKI'"><strong>English</strong></span>
                                <span
                                    *ngIf="AppliedCompleteSubjects.stageCode == 'LE1'"><strong>{{examAppForm.controls["stageOneMedium"].value}}</strong></span>
                                <span
                                    *ngIf="AppliedCompleteSubjects.stageCode == 'LE2'"><strong>{{examAppForm.controls["stageTwoMedium"].value}}</strong></span>
                                <span
                                    *ngIf="AppliedCompleteSubjects.stageCode == 'LE3'"><strong>{{examAppForm.controls["stageThreeMedium"].value}}</strong></span>
                            </td>

                        </tr>
                    </ng-template>

                </p-table>
                <hr>
                <div class="row">
                    <div class="col-12" style="text-align: right;">
                        <strong>Total Exam Payment Value : {{expectedExamFees | number : '1.2-2'}}</strong>
                    </div>
                </div>
                <div class="row" *ngIf="creditBalance.creditAmount != 0">
                    <div class="col-12" style="text-align: right;">
                        <strong>Credit Balance - {{creditBalance.voucherNumber}} : {{creditBalance.creditAmount | number
                            : '1.2-2'}}</strong>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12" style="text-align: right;">
                        <strong>Total Payable : {{expectedExamFees - creditBalance.creditAmount | number
                            : '1.2-2'}}</strong>
                    </div>
                </div>
                <hr>
                <div class="alert alert-dismissible alert-danger">
                    <strong>Notice</strong>
                    <p style="text-align: justify;">
                        Please Check the applied city and applied Subjects, check your exam fees too. Once you confirm
                        you are not allowed to change applied subjects. Verify before confirming exam Application. Once
                        Confirm you will be directed to payment. If you want to furthermore change please press “No”.
                    </p>

                </div>
            </div>
        </div>



    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (onClick)="ApplyExamination()" label="Yes" class="p-button-text"></p-button>
        <p-button icon="pi pi-times" (onClick)="formClose()" label="No"></p-button>
    </ng-template>

</p-dialog>


<p-dialog header='Class Code Entry Confirmation' [(visible)]="classEntrySuccess" [modal]="true"
    [style]="{width: '25vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <div class="card-body">
        Please Confirm Entered Class by pressing “Yes” and if you want to “Close”
    </div>

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (onClick)="UpdateClassCode()" label="Yes" class="p-button-text"></p-button>
        <p-button icon="pi pi-times" (onClick)="closeFormUser()" label="No"></p-button>
    </ng-template>

</p-dialog>


<p-dialog header='Add New Class Name' [(visible)]="newClassCode" [modal]="true" [style]="{width: '25vw'}"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <div class="card-body">

        <div style="size: 100%;">
            <label>
                Enter New Class Name :
            </label>
        </div>

        <div>
            <input pInputText type="text" class="p-inputtext-sm" placeholder="Class Name" [(ngModel)]="newClassName"
                [style]="{'width':'100%'}" />
        </div>

    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (onClick)="AddNewClassEntry()" label="Save New" class="p-button-text"></p-button>
        <p-button icon="pi pi-times" (onClick)="closeAddNewClass()" label="Close"></p-button>
    </ng-template>

</p-dialog>



<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>