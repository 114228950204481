<div class="container" style="margin-top:50px">
    <!-- Editable table -->
    <div >
      
      <div class="card-body">
        <div id="table" class="table-editable">
          <span class="table-add float-right mb-3 mr-2">
            <a class="text-success" >
      
            </a>
          </span>
          
          <p-table [value]="salesInvoiceItems" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="caption">
              My Purchased History
          </ng-template>
            <ng-template pTemplate="header">
                <tr>

                    <th>Name</th>
                    <th>Purchased Date</th>
                    <th style="text-align: center;">Item Qty</th>
                    <th>Item Rate</th>
                    <th>Total Sales</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-salesInvoiceItem>
                <tr>

                    <td style="text-align: left;">
                      {{salesInvoiceItem.itemName}}
                    </td>
                    <td>{{salesInvoiceItem.purchasedDate | date : 'medium'}}</td>
                    <td style="text-align:center;">
                      {{salesInvoiceItem.itemQty}}
                    </td>
                    <td style="text-align: right;">{{salesInvoiceItem.itemRate}}</td>
                    <td style="text-align: right;">{{salesInvoiceItem.totalSalesValue}}</td>
                </tr>
            </ng-template>
        </p-table>



        </div>
      </div>
    </div>  
    
    
    
    
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    
    <!-- Editable table -->
    
    </div>
      
