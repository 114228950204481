import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApplicationEntrySubjects } from '../_models/ApplicationEntrySubjects';
import { AppliedSubjects } from '../_models/AppliedSubjects';
import { ExaminationApplication } from '../_models/ExaminationApplication';
import { ExamPaidAmounts } from '../_models/ExamPaidAmounts';
import { StudentsCreditValues } from '../_models/StudentsCreditValues';
import { StudentExamApplication } from '../_models/StudentsExamApplication';
import { StudentsOfflinePayments } from '../_models/StudentsOfflinePayments';

@Injectable({
  providedIn: 'root'
})
export class ExamApplicationsService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }


  getApplyingSubjects() {
    return this.http.get<ApplicationEntrySubjects[]>(this.baseUrl + 'StudentProfile/GetPendingToApply');
  }


  getExamFees(appliedSubjects: AppliedSubjects[]) {
    return this.http.post<number>(this.baseUrl + 'StudentProfile/GetExamFees', appliedSubjects);
  }

  getStudentsCreditNote() {
    return this.http.get<StudentsCreditValues>(this.baseUrl + 'StudentProfile/GetCreditAmount');
  }

  ApplyForExam(examApplication: any) {
    return this.http.post<boolean>(this.baseUrl + 'StudentProfile/AddAppliedSubjects', examApplication);
  }

  GetExamPaidAmounts() {
    return this.http.get<ExamPaidAmounts>(this.baseUrl + 'StudentProfile/GetExamPaidAmounts');
  }

  checkExamAppliedservice() {
    return this.http.get<boolean>(this.baseUrl + 'StudentProfile/CheckExamAppliedforThisExam');
  }

  ManualBankPayments(offPayments: StudentsOfflinePayments[]) {
    return this.http.post<boolean>(this.baseUrl + 'StudentProfile/OfflineInvoicePaymentUpdate', offPayments);
  }

  UpdateFileToServer(formGroup: any) {
    return this.http.post(this.baseUrl + 'StudentProfile/UploadPicture', formGroup, { reportProgress: true, observe: 'events' });
  }


  checkExamAppliedDetails() {
    return this.http.get<StudentExamApplication>(this.baseUrl + 'StudentProfile/GetExamAppliedbyStudent');
  }


  checkPaymentBalance() {
    return this.http.get<boolean>(this.baseUrl + 'StudentProfile/CheckExamPaymentBalance');
  }


}
