import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProfileTracker } from '../_models/StudentProfileTracker';
import { ClassList } from '../_models/ClassList';

@Injectable({
  providedIn: 'root'
})
export class StudentProfileUtilityService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getProfileTrackerItems(trackingID: number) {
    return this.http.get<ProfileTracker>(this.baseUrl + 'StudentProfileUtility/GetStudentResults?TrackingID=' + trackingID);
  }


  getAppliedMedium() {
    return this.http.get<boolean>(this.baseUrl + 'StudentProfileUtility/GetApplicationMedium');
  }

  getProfileActivityTracking(trackingID: number) {
    return this.http.get<boolean>(this.baseUrl + 'StudentProfileUtility/GetProfileActivityStatus?trackingID=' + trackingID);
  }

  ResendEmailActivation(emailaddress: string) {
    return this.http.get<boolean>(this.baseUrl + 'StudentProfileUtility/ResendActivationEmailWeb?EmailAddress=' + emailaddress);
  }

  CheckExamApplicationAllow(){
    return this.http.get<boolean>(this.baseUrl + 'StudentProfileUtility/CheckCurrentExamAppAllow');
  }

  // Handling Classes

  GetStudentClassList(){
    return this.http.get<ClassList[]>(this.baseUrl + 'StudentProfileUtility/GetExamClassList');
  }

  CheckStudentUpdatedClass(){
    return this.http.get<boolean>(this.baseUrl + 'StudentProfileUtility/CheckValidClassEntry');
  }

  UpdateExistingClass(classCode :string){
    return this.http.get<boolean>(this.baseUrl + 'StudentProfileUtility/UpdateClassExamCode/' + classCode) ;
  }

  AddNewClassCode(ClassName : string){
    return this.http.get<boolean>(this.baseUrl + 'StudentProfileUtility/NewClassExamCode/' + ClassName) ;
  }






}
