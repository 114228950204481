<div class="container">
    <br>
    <br>
    <br>
    <div class="mainCover">

        <mat-card class="boxModel">
            <h1 class="headerfont">User Successfully Registered! </h1>

            <div>
                You have successfully registered, please check your email and click email verification link to activate
                your user account to Student Portal Service.
                <br>
                <br>
                <br>
                ඔබගේ Student Portal හි ලියාපදිංචි වීම සාර්ථකයි. කරුණාකර ඔබගේ E-mail ගිණුම වෙත යවා ඇති Email Verification
                ලින්ක් එක ක්ලික් කර ඔබගේ ගිණුම active කර ගන්න.
                <br>
                <br>
                <br>
                நீங்கள் மாணவர் தரவு தளத்தில் வெற்றிகரமாக பதிவு செய்துள்ளீர்கள். உங்களது கணக்கை செயற்படுத்த உங்களது
                மின்னஞ்சலுக்கு அனுப்பப்பட்டுள்ள இணைப்பினை click செய்யுங்கள்.


            </div>
            <br>
            <br>
            <br>
            <button class="btn btn-info btn-lg" routerLink='/'>Return to Home Page</button>
        </mat-card>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>

    </div>
</div>