import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
//import { ConfirmationService, Message, PrimeNGConfig, SelectItem } from 'primeng/api';
import { ApplicationEntrySubjects } from '../_models/ApplicationEntrySubjects';
import { AppliedCity } from '../_models/AppliedCity';
import { ExaminationApplication } from '../_models/ExaminationApplication';
import { StudentResult } from '../_models/StudentResult';
import { StudentsComplateDetails } from '../_models/StudentsCompleteDetails';
import { SystemControlFile } from '../_models/SystemControlFile';
import { AccountService } from '../_services/account.service';
import { ExamApplicationsService } from '../_services/exam-applications.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppliedSubjects } from '../_models/AppliedSubjects';
import { StudentsCreditValues } from '../_models/StudentsCreditValues';
import { take } from 'rxjs/operators';
import { LogUsers } from '../_models/user';
import { ApplyingSubjects } from '../_models/ApplyingSubjects';
import { StudentsPaymentsDetails } from '../_models/StudentsPaymentsDetails';
import { Router } from '@angular/router';
import { StudentExamApplication } from '../_models/StudentsExamApplication';
import { StudentExamApplicationSubjects } from '../_models/StudentExamApplicationSubjects';
import { StudentsExamApplicationPayments } from '../_models/StudentsExamApplicationPayments';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { StudentProfileUtilityService } from '../_services/student-profile-utility.service';
import { ClassList } from '../_models/ClassList';

//import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-examination-app-student',
  templateUrl: './examination-app-student.component.html',
  styleUrls: ['./examination-app-student.component.css']
})
export class ExaminationAppStudentComponent implements OnInit {

  newClassName: string = "";
  renevalStatus: boolean = true;
  calssCodeEnterd: boolean = false;
  paymentConfirmation: boolean = false;
  newClassCode: boolean = false;
  classEntrySuccess: boolean = false;
  BlackListedStatus: boolean = false;
  sysConFile: SystemControlFile;
  studentRegID: string;
  studRegCompDetails: StudentsComplateDetails;
  studentResult: StudentResult[];
  examAppliedCityList: AppliedCity[];
  examApplication: ExaminationApplication;
  examAppForm: FormGroup;
  StageOneSubjectArray: FormArray;
  stageOneSubjects: ApplicationEntrySubjects[];
  stageTwoSubjects: ApplicationEntrySubjects[];
  stageThreeSubjects: ApplicationEntrySubjects[];
  stageSKISubjects: ApplicationEntrySubjects[];
  SubjectsSelectedOne: ApplicationEntrySubjects[] = [];
  SubjectsSelectedTwo: ApplicationEntrySubjects[] = [];
  SubjectsSelectedThree: ApplicationEntrySubjects[] = [];
  SubjectsSelectedSKI: ApplicationEntrySubjects[] = [];
  AppliedCompleteSubjects: ApplicationEntrySubjects[] = [];
  classList: ClassList[] = [];
  selectedClass: ClassList;
  displayModal: boolean = false;
  //msgs: Message[] = [];
  position: string;
  nameofme: string;
  expectedExamFees: number;
  creditBalance: StudentsCreditValues;
  user: LogUsers;
  examApplied: boolean;
  examPaymentBalance: boolean = false;
  applicationAllowStatus: any;

  appliedDetails: StudentExamApplication;
  studentsApplicationSubjects: StudentExamApplicationSubjects[];
  studentsPayments: StudentsExamApplicationPayments[];
  totalSubjectCount: number;
  totalPaymentLines: number;
  totalPaidAmount: number;
  examApplicationAllow: boolean = true;

  currentDate: Date = new Date();

  constructor(private accountService: AccountService, private fb: FormBuilder, private examProUtility: StudentProfileUtilityService, private examService: ExamApplicationsService, private toastr: ToastrService, private dialog: MatDialog, private router: Router) {
    this.accountService.currentUser$.pipe(take(1)).subscribe(user => this.user = user);
  }

  ngOnInit(): void {
    this.CheckExamApplicationAllow();
  }

  checkExamApplied() {
    this.examService.checkExamAppliedservice().subscribe(response => {
      this.examApplied = response;

      if (response) {

        this.getExamAppliedDetails();
        this.checkExamPaymentBalance();
      }

    });
  }

  checkValidClass() {
    if (this.selectedClass != null) {
      this.classEntrySuccess = true;
    }
  }

  UpdateClassCode() {
    var classCode = this.selectedClass.classCode;
    this.examProUtility.UpdateExistingClass(classCode).subscribe(response => {
      if (response) {
        this.toastr.success("Updated Successfully !");
        this.classEntrySuccess = false;
        this.calssCodeEnterd = true;
      }
      else {
        this.toastr.error("Update Fail !");
      }

    })

  }

  closeFormUser() {
    this.classEntrySuccess = false;
  }

  getClascodeExist() {

    this.examProUtility.CheckStudentUpdatedClass().subscribe(response => {
      this.calssCodeEnterd = response;
      this.getClassList();
    });
  }

  getClassList() {
    this.examProUtility.GetStudentClassList().subscribe(response => {
      this.classList = response;
    });
  }


  CheckExamApplicationAllow() {
    this.examProUtility.CheckExamApplicationAllow().subscribe(response => {
      this.examApplicationAllow = response;

      if (response) {
        this.getRenevalStatus();
      }


    });
  }

  checkAddNewClass() {
    this.newClassCode = true;
  }

  AddNewClassEntry() {

    //Add New Class Entry
    var NewClassName = this.newClassName;

    this.examProUtility.AddNewClassCode(NewClassName).subscribe(response => {

      if (response) {
        this.newClassCode = false;
        this.calssCodeEnterd = true;
      }
      else {
        this.newClassCode = true;
      }

    });


  }

  closeAddNewClass() {
    this.newClassCode = false;
  }


  checkExamPaymentBalance() {
    this.examService.checkPaymentBalance().subscribe(response => {
      this.examPaymentBalance = response;
    });
  }

  payBalanceAmount() {
    this.router.navigateByUrl('ExamPayments');
  }



  inititializeForm() {

    this.examAppForm = this.fb.group({
      studentRegID: ["369258741"],
      cityCode: ['', Validators.required],
      stageOneMedium: ['', Validators.required],
      stageTwoMedium: ['', Validators.required],
      stageThreeMedium: ['', Validators.required]
    }

    )

  }

  getExamAppliedDetails() {

    this.examService.checkExamAppliedDetails().subscribe(details => {

      this.appliedDetails = details;
      this.studentsApplicationSubjects = this.appliedDetails.studentsAppliedExamApplicationSubjectsDto;
      this.studentsPayments = this.appliedDetails.studentsAppliedExamApplicationPaymentsDto;
      this.totalSubjectCount = this.studentsApplicationSubjects.length;
      this.totalPaymentLines = this.studentsPayments.length;
      this.getTotalPaidAmount();
      //this.totalPaidAmount = this.studentsPayments.values

    });
  }


  getTotalPaidAmount() {

    let p: number = 0;

    for (let item of this.studentsPayments) {
      p = p + item.paidAmount;
    }

    this.totalPaidAmount = p;
  }





  CheckApplicationVerification() {



    if (this.examAppForm.controls["cityCode"].invalid) {
      this.toastr.error("Please Select Applying City");
    }
    else {
      if (this.SubjectsSelectedOne.length > 0 || this.SubjectsSelectedTwo.length > 0 || this.SubjectsSelectedThree.length > 0 || this.SubjectsSelectedSKI.length > 0) {
        if (this.SubjectsSelectedOne.length > 0) {
          if (this.examAppForm.controls["stageOneMedium"].invalid) {
            this.toastr.error("Please Select Stage One Medium");
          }
          else {
            if (this.SubjectsSelectedTwo.length > 0) {
              if (this.examAppForm.controls["stageTwoMedium"].invalid) {
                this.toastr.error("Please Select Stage Two Medium");
              }
              else {
                if (this.SubjectsSelectedThree.length > 0) {
                  if (this.examAppForm.controls["stageThreeMedium"].invalid) {
                    this.toastr.error("Please Select Stage Three Medium");
                  }
                  else {
                    if (this.stageThreeSubjects.filter(subjects => subjects.completed === 0).length > this.SubjectsSelectedThree.length) {
                      this.toastr.error("You are required to apply all stage Three pending Subjects");
                    }
                    else {
                      this.getStudentsAppliedSubjects();
                    }
                  }
                }
                else {
                  this.getStudentsAppliedSubjects();
                }
              }
            }
            else {
              if (this.SubjectsSelectedThree.length > 0) {
                if (this.examAppForm.controls["stageThreeMedium"].invalid) {
                  this.toastr.error("Please Select Stage Three Medium");
                }
                else {
                  if (this.stageThreeSubjects.filter(subjects => subjects.completed === 0).length > this.SubjectsSelectedThree.length) {
                    this.toastr.error("You are required to apply all stage Three pending Subjects");
                  }
                  else {
                    this.getStudentsAppliedSubjects();
                  }

                }
              }
              else {
                this.getStudentsAppliedSubjects();
              }
            }
          }
        }
        else {
          if (this.SubjectsSelectedTwo.length > 0) {
            if (this.examAppForm.controls["stageTwoMedium"].invalid) {
              this.toastr.error("Please Select Stage Two Medium");
            }
            else {
              if (this.SubjectsSelectedThree.length > 0) {
                if (this.examAppForm.controls["stageThreeMedium"].invalid) {
                  this.toastr.error("Please Select Stage Three Medium");
                }
                else {
                  if (this.stageThreeSubjects.filter(subjects => subjects.completed === 0).length > this.SubjectsSelectedThree.length) {
                    this.toastr.error("You are required to apply all stage Three pending Subjects");
                  }
                  else {
                    this.getStudentsAppliedSubjects();
                  }
                }
              }
              else {
                this.getStudentsAppliedSubjects();
              }
            }
          }
          else {
            if (this.SubjectsSelectedThree.length > 0) {
              if (this.examAppForm.controls["stageThreeMedium"].invalid) {
                this.toastr.error("Please Select Stage Three Medium");
              }
              else {
                if (this.stageThreeSubjects.filter(stageThreeSubjects => stageThreeSubjects.completed === 0).length > this.SubjectsSelectedThree.length) {
                  this.toastr.error("You are required to apply all stage Three pending Subjects");
                }
                else {
                  this.getStudentsAppliedSubjects();
                }
              }
            }
            else {
              this.getStudentsAppliedSubjects();
            }
          }
        }

      }
      else {
        this.toastr.error("Please Select Subject to Apply");
      }
    }
  }

  formClose() {
    this.displayModal = false;
  }


  getStudentsAppliedSubjects() {

    this.AppliedCompleteSubjects.concat(this.SubjectsSelectedTwo);
    this.AppliedCompleteSubjects.concat(this.SubjectsSelectedSKI);

    const mergeArray = [].concat(...this.SubjectsSelectedSKI, ...this.SubjectsSelectedThree, ...this.SubjectsSelectedTwo, ...this.SubjectsSelectedOne).sort((a, b) => a.subjectCode.localeCompare(b.subjectCode));
    this.AppliedCompleteSubjects = [...mergeArray];

    this.getExamFees();
    this.displayModal = true;


  }

  getCreditBalance() {

    this.examService.getStudentsCreditNote().subscribe(response => {
      this.creditBalance = response;
    }
    );
  }

  getRenevalStatus() {
    this.accountService.getRenevalStatus().subscribe(response => {
      this.renevalStatus = response;
      if (this.renevalStatus) {
        this.getBlackListed();
        this.checkExamApplied();
        this.getExamAppliysubjects();
        this.inititializeForm();
        this.getSystemControl();
        this.GetStudentsDetails();
        this.getExamAppliedCity();
      }
    }
    );
  }

  verifyConfirmation() {
    this.paymentConfirmation = true;
  }

  getExamFees() {
    const applySubjects: AppliedSubjects[] = [];

    for (const appSubs of this.AppliedCompleteSubjects) {
      applySubjects.push({
        subjectCode: appSubs.subjectCode,
        stageCode: appSubs.stageCode
      })
    }
    this.examService.getExamFees(applySubjects).subscribe(response => {
      this.expectedExamFees = response;
      this.getCreditBalance();
    })
  }


  ApplyExamination() {

    const applySubjects: AppliedSubjects[] = [];
    const applyingSubjects: ApplyingSubjects[] = [];
    const studentPayments: StudentsPaymentsDetails[] = [];

    for (const appSubs of this.AppliedCompleteSubjects) {
      applySubjects.push({
        subjectCode: appSubs.subjectCode,
        stageCode: appSubs.stageCode
      })

      applyingSubjects.push({
        subjectCode: appSubs.subjectCode,
        applyingStageCode: appSubs.stageCode == 'LE1' ? '01' : appSubs.stageCode == 'LE2' ? '02' : '03',
        stageCode: appSubs.stageCode,
        mediumCode: appSubs.stageCode == 'LE1' ? this.examAppForm.controls["stageOneMedium"].value : appSubs.stageCode == 'LE2' ? this.examAppForm.controls["stageTwoMedium"].value :
          appSubs.stageCode == 'SKI' ? 'ENGLISH' : this.examAppForm.controls["stageThreeMedium"].value,
      })

    }

    if (this.creditBalance.creditAmount > 0) {
      studentPayments.push({
        paymentType: 1,
        bankCode: 'NOBANK',
        branchCode: 'NOBANK',
        paidAmount: this.creditBalance.creditAmount,
        balanceAmount: (this.expectedExamFees - this.creditBalance.creditAmount),
        voucherReferance: this.creditBalance.voucherNumber
      });

      studentPayments.push({
        paymentType: 0,
        bankCode: 'NOBANK',
        branchCode: 'NOBANK',
        paidAmount: this.expectedExamFees - this.creditBalance.creditAmount,
        balanceAmount: 0,
        voucherReferance: 'No Referance'
      });
    }
    else {
      studentPayments.push({
        paymentType: 0,
        bankCode: 'NOBANK',
        branchCode: 'NOBANK',
        paidAmount: this.expectedExamFees,
        balanceAmount: 0,
        voucherReferance: 'No Referance'
      });
    }



    this.examService.getExamFees(applySubjects).subscribe(response => {
      this.expectedExamFees = response;
      this.getCreditBalance();
      var examApplication: ExaminationApplication = {

        studentRegID: this.user.userId,
        cityCode: this.examAppForm.controls["cityCode"].value,
        classCode: '10000',
        totalExamFees: this.expectedExamFees,
        paidBalance: this.creditBalance.creditAmount,
        applicationEntrySubjectsExaminationDto: applyingSubjects,
        studentsPaymentsDetailsDto: studentPayments

      };


      this.examService.ApplyForExam(examApplication).subscribe(result => {
        if (result) {
          this.toastr.info("Please Arrange Your Payment");
          this.displayModal = false;
          this.router.navigateByUrl('ExamPayments');
        }
        else {
          this.toastr.error("Exam Application Error, Please Contact AAT Sri Lanka");
        }
      })
    })
  }


  getBlackListed() {
    this.accountService.getBlackListedStatus().subscribe(response => {
      this.BlackListedStatus = response
      this.getClascodeExist();

    });
  }

  getExamAppliysubjects() {
    this.examService.getApplyingSubjects().subscribe(response => {
      this.stageOneSubjects = response.filter(x => x.stageCode == "LE1");
      this.stageTwoSubjects = response.filter(x => x.stageCode == "LE2");
      this.stageThreeSubjects = response.filter(x => x.stageCode == "LE3");
      this.stageSKISubjects = response.filter(x => x.stageCode == "SKI");
    })
  }


  getSystemControl() {
    this.accountService.getSystemControlFiles().subscribe(response => {
      this.sysConFile = response
    });
  }

  GetStudentsDetails() {
    this.accountService.getStudentsDetailResults().subscribe(userdetails => {
      this.studRegCompDetails = userdetails;
      this.studentResult = this.studRegCompDetails.studentResult;
    }
    );
  }


  getExamAppliedCity() {
    this.accountService.getExamAppliedCity().subscribe(response => {
      this.examAppliedCityList = response
    });
  }

}
