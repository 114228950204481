import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserItemCartDetails } from '../_models/useritemcartdetails';
import { SalesitemsService } from '../_services/salesitems.service';
import { ToWords } from 'to-words';
import { DOCUMENT } from '@angular/common';
import { PurchaseRequest } from '../_models/purchaseRequest';
import { SalesInvoiceService } from '../_services/sales-invoice.service';

@Component({
  selector: 'app-shopcart-final',
  templateUrl: './shopcart-final.component.html',
  styleUrls: ['./shopcart-final.component.css']
})
export class ShopcartFinalComponent implements OnInit {

  itemsinCart: UserItemCartDetails[] = [];
  purchaseRequest: PurchaseRequest;
  totalSales: number = 0;
  totalCount : number = 0;
  totalDiscount : number = 0;
  salesinWord : string ="No Number" ;
  netSales:number = 0;
  itemString:string="";

  constructor(private itemService: SalesitemsService, private toastr: ToastrService,@Inject(DOCUMENT) private document: Document, private invService:SalesInvoiceService) { }

  ngOnInit(): void {
    this.loadItemsToCart();
  }


  loadItemsToCart() {
    this.itemService.getUserItemCartDetails().subscribe(itemCarts => {
      this.itemsinCart = itemCarts;
      this.createItemString();
      this.numbertoWords();
      this.totalCount = this.itemsinCart.length;
    })
  }

  calculateTotalSales(){
    this.itemsinCart.forEach(a => this.totalSales += a.salesValue);
    this.netSales = this.totalSales - this.totalDiscount;
    console.log(this.totalSales);
  }

  removeItemfromCart(itemLine: number) {
    this.totalSales = 0;
    this.itemService.removeUserItemfromCart(itemLine).subscribe(item => {
      console.log(itemLine.toString());
      this.loadItemsToCart();
      this.toastr.success("Selected Item Removed from Shopping Cart");
    }, error => {
      console.log(error);
      this.toastr.error(error.error);
    }
    );


  }

  createItemString(){
    this.itemsinCart.forEach(a => {
      if(this.itemString == "")
      {
        this.itemString = a.itemCode.toString()
      }
      else
      {
        this.itemString = this.itemString + "A"+ a.itemCode.toString();
      }
    });

    this.invService.CreateDiscount(this.itemString).subscribe(discountValue=>{
      this.totalDiscount = discountValue;
      this.calculateTotalSales();
    })   

  }


  numbertoWords() {
    const toWords = new ToWords();

    this.salesinWord = toWords.convert(this.netSales) + " Rupees Only";

  }

  confirmPayment() {
    this.invService.ConfirmPayment(this.totalDiscount).subscribe(itemCarts => {
      this.purchaseRequest = itemCarts;
     // this.document.location.href = 'https://aatsl.lk/index.php/en/shopping-cart-payment?refno=1001&fname='+this.purchaseRequest.firstName+'&lname='+this.purchaseRequest.lastName+'&phone='+this.purchaseRequest.contactNumber+'&email='+this.purchaseRequest.emailAddress+'&amount='+this.purchaseRequest.paymentAmount.toString();
     this.document.location.href = 'https://aatsl.lk/index.php/en/shopping-cart-payment?refno='+this.purchaseRequest.invoiceNumber;
     

    })
  }


  
  }
