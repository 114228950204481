<div class="container" style="margin-top:50px">

    <p-tabMenu [model]="items" [activeItem]="items[0]"></p-tabMenu>
   
    <p-card header="Applying for AAT Examination Online" subheader="" [style]="{width: '100%'} "
        styleClass="p-card-shadow">
        <ng-template pTemplate="header">
            <P class="card-header fa-2x text-center font-weight-bold  alert alert-dismissible alert-primary">
               How to Apply for the Examination              
            </P>

        </ng-template>
        <p-divider></p-divider>


        <h1>
            <strong style="color: red;">
                “ if you have already posted the examination application to AAT by post do not apply through online”
            </strong>

        </h1>

        <h2>
            <strong>
                Step One - Select Subjects
            </strong>

        </h2>

        <p>
            <li>
                Select Tab called “Exam Application”, You will be directed to Online Examination Application Option.
            </li>

        </p>
        <p>
            <li>
                Select the City where you wish to sit for the examination.
            </li>

        </p>
        <p>
            <li>
                Select the <strong>Subjects</strong> you wish to sit for
            </li>

        </p>

        <ul type="circle">
            <li>Subjects you have already completed/exempted cannot be selected again.</li>
            <li>Can Select<strong> both Levels I and Level II Subjects</strong> together.</li>
            <li>To Select <strong>Business Communication Subject (310), Level I</strong> should be completed.</li>
            <li>Should select <strong>all 4 subjects when applying for Level III</strong> at the first attempt and in
                subsequent attempts, should select <strong>failed / absent</strong> subjects otherwise you are not
                allowed to proceed.</li>
        </ul>

        <p>
            <li>
                Select the <strong>Medium</strong> you wish to sit (This is appeared top of each level except for BC
                Subject)
            </li>

        </p>

        <p>
            <li>
                After selecting the city, medium and subjects click <strong>Apply</strong> button.
            </li>

        </p>

        <p>
            <li>
                Then a summary box will appear which contains the details you selected earlier including <strong> The
                    Payable Amount </strong>
                (If you are eligible for <strong>‘Credit Note’</strong> the <strong>payable amount will be shown after
                    reducing the Credit
                    Note Amount.</strong>). Recheck whether they are correct and click the Yes button to proceed with
                the payment. After clicking <strong>‘Yes’</strong> you cannot change the details you have selected.
            </li>

        </p>

        <p>
            <li>
                Then you will be directed to the <strong>‘Exam Application Payment’</strong> page.
            </li>

        </p>

        <p-divider></p-divider>

        <h2>
            <strong>
                Step Two - Making the Payment for Examination
            </strong>

        </h2>

        <h3>
            In the <strong>‘Exam Application Payment’ </strong> page ,
        </h3>

        <p>
            <li>
                You can do the payment through either <strong>Online</strong> or <strong>Offline Bank Payment</strong> .
                When you select the relevant tab
                you will be directed to the relevant payment section
            </li>
        </p>

        <p>
            <li>
                <strong>Online – Payment</strong>
            </li>

        </p>

        <ul type="circle">
            <li>You can do the payments using a Credit card (Visa/Master)</li>
        </ul>

        <p>
            <li>
                <strong>Offline Bank Payment</strong>
            </li>

        </p>

        <ul type="circle">
            <dl>
                <dt>
                    <li>When you visit the, Offline bank payment site click Add Payment Details box to update your
                        payment
                        details.</li>
                </dt>

                <dd>
                    <li>
                        If you paid using a cash deposit slip in the bank you must fill 22 plus your 7 digits Student
                        Registration Number in reference of bank slip( detail steps of filling the slips are shown at
                        the end )as your reference number or if you paid using the payment slip sent by AAT Exam
                        Division, along with exam application enter the number starts with JAxxxxxx.
                    </li>
                </dd>
                <dd>
                    <li>
                        Then you need to enter the paid date and paid amount accordingly
                    </li>
                </dd>
                <dd>
                    <li>
                        Finally upload a picture of the payment slip and click Yes
                    </li>
                </dd>
                <dt>
                    <li>Then a box will appear asking for confirmation. If you have enter all the relevant details
                        without missing click 'YES'</li>
                </dt>
            </dl>

        </ul>

        <p>
            <li>
                <strong>If accidentally the page is closed by you before confirming the payment or part of the amount
                    has been paid, log into your account again and go to the exam application. There the payable amount
                    will be shown and you can click The Amount Payable Then you will be directed to the ‘Exam
                    Application Payment’ page to proceed with the balance payment.</strong>
            </li>

        </p>

        <p>
            Finally, a message will be shown that you have applied for the examination successfully, Note that if there
            is any clarification and shortcomings on the exam application, the examination division will contact you.
        </p>

        <p-divider></p-divider>


        <h2>
            <strong>
                Step Three - How to pay using a cash deposit slip in the bank
            </strong>

        </h2>

        <p>
            <li>
                Examination payment can only be paid to <strong><u>any branch</u> of the Bank of Ceylon (BOC) </strong>
                to the credit of <strong>
                    A/C No: 165173, Branch: Narahenpita, Acc. Name: Association of Accounting Technicians of Sri Lanka.
                </strong>
            </li>

        </p>

        <p>
            <li>
                You can do the payment using <strong>cash deposit slips</strong> in the bank or if you have already
                received your exam
                application and you can use the payment slip of the application to make the payment to bank( If you have
                <strong>still not post</strong> the examination application)
            </li>

        </p>

        <p>
            <li>
                If you are using a <strong>cash deposit slip</strong> in the bank when filling the bank slip you need to
                fill clearly
                <strong>Students Name with Initials, NIC No, Telephone Number, Date and the Amount paid. Purpose of
                    deposit
                    should be mentioned as AAT Examination Fee.</strong>
            </li>

        </p>

        <p>
            <li>
                As the <strong>Ref No</strong> you should need to fill <strong>22 plus your 7 digits Student
                    Registration Number correctly,(e.g.:
                    22xxxxxxx)</strong> .This is a <strong style="color: red;"> <u>Mandatory Requirement,</u> </strong>
                otherwise it won’t be recognized as an examination fee.
            </li>

        </p>

        <p>
            <img src="assets/BankSlip.jpg" class="img-fluid" alt="Responsive image">

        </p>

        <h1>
            <strong style="color: red;">
                Note: You cannot pay using <u>Cash Deposit Machines (CDMs)</u>

            </strong>
        </h1>


        <p-divider></p-divider>

        <ng-template pTemplate="footer">

         

        </ng-template>
    </p-card>
</div>

