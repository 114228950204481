import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-regi-conf',
  templateUrl: './regi-conf.component.html',
  styleUrls: ['./regi-conf.component.css']
})
export class RegiConfComponent implements OnInit {


  constructor() { 

  }

  ngOnInit(): void {
  }

}
