import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LogUsers } from './_models/user';
import { AccountService } from './_services/account.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']

})
export class AppComponent implements OnInit {
  title = 'Education & Member Materials';

  user: any;
  SalesItems: any;

  constructor(private http: HttpClient, private accountService: AccountService) { 

  }

  ngOnInit() {
    this.setCurrentUser();
  }

  

  setCurrentUser() {
    const Exuser: LogUsers = JSON.parse(localStorage.getItem('user') || '{}');

    if (Exuser.userId === undefined) 
    {
      this.accountService.setCurrentUser(undefined);
    }
    else
    {
      this.accountService.setCurrentUser(Exuser);
      //this.accountService.setCurrentUser(undefined);
    }      
  
  }


}


