<div class="card border-light mb-5" style="max-width: 11rem; min-width: 11rem;" >
    <h4 class="card-header">
        <strong>
            {{item.itemName}}
        </strong>
        
    </h4>    
    <div class="card-body">
        <div class="card-img-wrapper">
            <img src="{{item.photoUrl}}" alt="{{item.itemName}}" class="card-img-top" width="100%" height="200%">
            <ul class="list-inline member-icons animate text-center">
                <li class="list-inline-item">
                    <button class="btn btn-primary" (click)="AddItemToCart()" inputmode="submit" ngbTooltip="Add to Cart" [openDelay]="300" [closeDelay]="500">
                        <i class="fa fa-cart-plus">                            
                        </i>
                    </button>                    
                </li>
                <li class="list-inline-item">
                    <button routerLink='/salesItems/{{item.itemCodeID}}'
                        class="btn btn-primary" ngbTooltip="Review the Book" [openDelay]="300" [closeDelay]="500">
                        <i class="fa fa-info">
                        </i>
                    </button>
                </li>
            </ul>
        </div>
    <div class="card-footer text-strong text-left">
        RS : {{item.itemPrice}}
    </div>
  </div>
 