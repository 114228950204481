import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { pagination } from 'src/app/_models/pagination';
import { SalesItemsInter } from 'src/app/_models/SalesItemsInt';
import { SalesitemsService } from 'src/app/_services/salesitems.service';

@Component({
  selector: 'app-sales-items-list',
  templateUrl: './sales-items-list.component.html',
  styleUrls: ['./sales-items-list.component.css']
})
export class SalesItemsListComponent implements OnInit {

   items: SalesItemsInter[] = [];
   pagination : pagination;
   pageNumber = 1;
   pageSize = 12;
   typeString:string = "ALL" ;

  
  constructor(private itemService :SalesitemsService) { }

  ngOnInit(): void {
   
    this.loadSalesItemsList();
  }

  loadSalesItemsList(){

    
    this.itemService.getSalesItemsSearch(this.typeString == null || "" ?"ALL":this.typeString,this.pageNumber,this.pageSize).subscribe(response=>{
      this.items = response.result;
      this.pagination = response.pagination;
    })
  }

  pageChanged(event: any){
    this.pageNumber = event.page;
    this.loadSalesItemsList();
  }

}
