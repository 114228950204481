import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SalesItemsInter } from '../_models/SalesItemsInt';
import { UserReview } from '../_models/userreview';
import {UserItemCart} from '../_models/useritemcart'
import {UserItemCartDetails} from '../_models/useritemcartdetails'
import { PaginatedResult } from '../_models/pagination';
import { map } from 'rxjs/operators';
import { SalesItemsDetails } from '../_models/salesitemsdetails';






@Injectable({
  providedIn: 'root'
})
export class SalesitemsService {

  baseUrl =  environment.apiUrl;
  salesItems: SalesItemsInter[] = [];
  paginatedResult:PaginatedResult<SalesItemsInter[]> =  new PaginatedResult<SalesItemsInter[]>();

  constructor(private http: HttpClient) { }


  getSalesItems(page?: number, itemsPerPage?: number){
    let params =  new HttpParams();

    if(page!=null && itemsPerPage != null){
      params = params.append('pageNumber',page.toString());
      params = params.append('pageSize',itemsPerPage.toString());
    }

    return this.http.get<SalesItemsInter[]>(this.baseUrl+'SalesItems',{observe: 'response', params}).pipe(
      map(response=>{
        this.paginatedResult.result = response.body;
        if(response.headers.get('Pagination') !== null){
          this.paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return this.paginatedResult;
      })

    );
  }

  getSalesItemsSearch(filterString:string, page?: number, itemsPerPage?: number){
    let params =  new HttpParams();

    if(page!=null && itemsPerPage != null){
      params = params.append('pageNumber',page.toString());
      params = params.append('pageSize',itemsPerPage.toString());
    }

    return this.http.get<SalesItemsInter[]>(this.baseUrl+'SalesItems/'+filterString+'/itemView',{observe: 'response', params}).pipe(
      map(response=>{
        this.paginatedResult.result = response.body;
        if(response.headers.get('Pagination') !== null){
          this.paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return this.paginatedResult;
      })

    );
  }

  getSalesItem(itemcode:number){
    return this.http.get<SalesItemsInter>(this.baseUrl+'SalesItems/'+itemcode)
  }

  updateUserReview(userreview : UserReview){
    return this.http.post<UserReview>(this.baseUrl+'SalesItems/add-review/',userreview);
  }

  addUserItemtoCart(useritemcart:UserItemCart){
    return this.http.post<UserItemCart>(this.baseUrl+'UserShoppingCart/AddToCart/',useritemcart);
  }

getUserItemCartDetails(){
  return this.http.get<UserItemCartDetails[]>(this.baseUrl+'UserShoppingCart/GetCartList');
}

removeUserItemfromCart(itemLineCode:number){
  return this.http.delete<boolean>(this.baseUrl+'UserShoppingCart/DeleteCart/'+itemLineCode);
}


geSalesItemsDetails(itemLineCode:number){
  return this.http.get<SalesItemsDetails[]>(this.baseUrl+'SalesItems/GetSalesItemDetails/itemCode?itemCode='+itemLineCode);
}


}
