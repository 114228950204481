<div class="container" style="margin-top:50px">

    <div class="itemCentre" style="align-items: center; align-content: center; text-align: center;">
        <div class="row itemCentre" style="align-items: center; align-content: center;">
            <hr>
            <br>
            <P class="card-header fa-2x text-center font-weight-bold  alert alert-dismissible alert-primary"  style="width: 100%;" >
                Available Books
            </P>
            <br>
            <hr>
        </div>
    </div>

    <div class=" row">




        <div *ngFor="let item of items" class="col-2">
            <app-salesitem-card [item]="item"></app-salesitem-card>

        </div>


    </div>

    <div class="d-flex justify-content-center" *ngIf="pagination">

        <pagination [boundaryLinks]="true" [totalItems]="pagination.TotalItems" [itemsPerPage]="pagination.ItemsPerPage"
            [(ngModel)]="pagination.CurrentPage" (pageChanged)="pageChanged($event)" previousText="&lsaquo;"
            nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">

        </pagination>
    </div>

    <div class="jumbotron">
        <div class=" row">
            <div class="col-10">
                <input class="form-control" type="text" placeholder="Search" name="userview" #name="ngModel"
                    [(ngModel)]="this.typeString">
            </div>
            <div class="col-2">
                <button class="btn btn-outline-primary" style="width: 100%;" type="submit"
                    (click)="loadSalesItemsList()">Search</button>
            </div>

        </div>
    </div>

    <br>
    <hr>

    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
</div>